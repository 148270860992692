import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uuid } from 'uuidv4';
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ImageCropper from './ImageCropper';
import { dataURLtoFile } from "../../helpers/utils";
import SVG from "react-inlinesvg";

let originalFileObj = null;

function StyledDropzone(props) {
  const allowMultipleUpload = props.allowMultipleUpload == null ? true : props.allowMultipleUpload;
  const forceSquareImageFormat = props.forceSquareImageFormat == null ? false : props.forceSquareImageFormat;
  let [showImageCrop, setShowImageCrop] = useState(false),
    [imageToCrop, setImageToCrop] = useState(null),
    [showDescription, setShowDescription] = useState(true);

  const showError = (error) => {
    if(props.onError) props.onError(error);
  };

  const runCustomChecks = (acceptedFiles, rejectedFiles) => {
    showError(null);

    if(!!rejectedFiles.find(f => f.errors.find(e => e.code == "file-too-large"))) {
      showError("File size can’t exceed " + Math.round(props.maxSize / 1000000) + " Mb.");
      return false;
    }

    if(!numberOfFilesIsValid(acceptedFiles, rejectedFiles))
      return false;

    return true;
  };

  const numberOfFilesIsValid = (acceptedFiles, rejectedFiles) => {
    if(!acceptedFiles.length) {
      showError("Please select a valid file.");
      return false;
    }

    if(allowMultipleUpload === false && acceptedFiles.length > 1) {
      showError("Please select a single file.");
      return false;
    }

    return true;
  };

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    let totalSizeMb = 0;

    if(props.user && !isNaN(props.user.stats.storageMb))
      totalSizeMb = props.user.stats.storageMb;

    for(let file of acceptedFiles)
      totalSizeMb += isNaN(file.size) ? 0 : file.size / 1024 / 1024;

    if(totalSizeMb && props.validateStorage && props.user && props.user.planFeatures.storageMb > -1 && totalSizeMb >= props.user.planFeatures.storageMb) {
      showError("You have exceeded your storage quota, upgrade to increase it.");
      setShowDescription(false);
      return;
    } else {
      setShowDescription(true);
    }

    const acceptImages = (props.fileType && !!props.fileType.match(/image\//)) || props.acceptImages,
      isValid = runCustomChecks(acceptedFiles, rejectedFiles);

    if(!isValid) {
      //showError("Please select a valid file.");
      return;
    }

    let isSquared = true;
    if(acceptImages) {
      for(let i = 0; i < acceptedFiles.length; i++)
        if(!await imageIsSquared(acceptedFiles[i])) {
          isSquared = false;
          break;
        }
    }

    if(acceptImages && forceSquareImageFormat && !isSquared) {
      let reader = new FileReader();
      reader.onload = e => {
        setImageToCrop(e.target.result);
        setShowImageCrop(true);
      };
      originalFileObj = acceptedFiles[0]; //we're not expecting multiple uploads for square images
      reader.readAsDataURL(originalFileObj);
    }

    if(!acceptImages || !forceSquareImageFormat || isSquared) {
      acceptedFiles = acceptedFiles.map(f => ({ file: f, id: uuid(), isValid: true }));
      rejectedFiles = (rejectedFiles || []).map(f => ({ file: f, id: uuid(), isValid: false }));
      props.addFilesToUpload([...acceptedFiles, ...rejectedFiles]);
      showError(rejectedFiles.length ? "Please select a valid file." : null);
    }
  }, []);

  const imageIsSquared = file => {
    return new Promise(resolve => {
      try {
        const fr = new FileReader();

        fr.onload = function() {
          const img = new Image();

          img.onload = function() {
            const isSquare = img.width === img.height;
            resolve(isSquare);
          };

          img.src = fr.result;
        };

        fr.readAsDataURL(file);
      } catch(e) {
        resolve(false);
      }
    });
  };

  const useDz = useDropzone({
    onDrop,
    accept: props.accept || props.fileType,
    multiple: allowMultipleUpload,
    maxSize: props.maxSize
  });

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDz;

  let hasError = isDragReject || props.errorMessage;

  return <>
    <div className={
      "dropzone needsclick "
      + (allowMultipleUpload ? "dropzone-multiple " : "")
      + (props.classContainer ? props.classContainer + " " : "")
      + (props.uploadProgress && props.uploadProgress.length ? "dropzone-has-progress " :
        (hasError ? "dropzone-has-error " : "")
        + (isDragActive ? "dropzone-drag-active " : "")
        + (isDragAccept ? "dropzone-drag-accept " : "")
      )}>
      <div {...getRootProps()}>
        <input {...getInputProps()} disabled={props.disabled} />

        {props.children}

        <div className="dropzone-content">
          {hasError
            ? <SVG className="dropzone-icon-error" src="/media/def-image/icons/file-upload-error.svg" />
            : <SVG className="dropzone-icon" src="/media/def-image/icons/file-upload.svg" />}
          <div className='dropzone-message'>
            <h3>
              <span className="dropzone-message-normal">Drop {allowMultipleUpload ? "files" : "a file"} here or click to upload</span>
              <span className="dropzone-message-error">{props.errorMessage || "You can’t add this file type"}</span>
            </h3>
            {props.description && showDescription && <p className="dropzone-description">{props.description}</p>}
          </div>
        </div>

        {props.uploadProgress && props.uploadProgress.length ? <div className="dropzone-progress-container">
          <h3>File uploading...</h3>
          {props.uploadProgress}
        </div> : <></>}
      </div>
    </div>
    <ImageCropper
      show={showImageCrop}
      onHide={() => { setShowImageCrop(false); }}
      onSuccess={data => {
        setShowImageCrop(false);

        if(!data || !originalFileObj) return;

        props.addFilesToUpload([{
          file: dataURLtoFile(data, originalFileObj.name),
          id: uuid(),
          isValid: true
        }]);
      }}
      image={imageToCrop}
    />
  </>;
}

export default StyledDropzone;
