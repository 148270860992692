import _ from "lodash";
import React, { createRef } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Form, Modal } from "react-bootstrap";
import { Switch } from "@material-ui/core";
import PodcastPlayer from "./generic/PodcastPlayer";
import AwsS3Dropzone from "../../generic/AwsS3Dropzone";
import { Formik, Form as FormFormik, ErrorMessage } from "formik";
import api from "../../../../redux/api";
import { getInputClassName, toastMessage, updateCurrentUser, updateProducts } from "../../../helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import schemas from "../../../schemas";
import ConfirmModal from "../../modals/ConfirmModal";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import Editor from "../../../layout/components/Editor";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";
import AIField from "../../../layout/ai/AIField";
import embed from "@brightcrowd/embed-video";
import UpgradeLink from "../../generic/UpgradeLink";
import { checkLimits } from "../../../plans";
import { user } from "../../../schemas/user-shemas";
import TagDropdown from "../../../layout/components/TagDropdown";
import { EditTagModal } from "../../contacts/Tags";

const _release_immediate = 0,
  _release_dripped = 1,
  _release_date = 2,
  _expires_forever = 0,
  _expires_product = 1,
  _expires_episode = 2;

class EpisodeEdit extends React.Component {
  formik = createRef();

  constructor(props) {
    super(props);

    this.state = {
      state: { draft: true },
      uploadError: null,
      totalEpisodes: 0,
      isNewAudioFileUploadedButNotYetSaved: false,
      contributors: [],
      lastReleaseOrder: null,
      sampleEpisodeCount: 0,
      isEdit: false,
      loading: false,
      showConfirmCancel: false,

      newEpisodeTemplate: {
        title: "",
        episodeNumber: null,
        summary: "",
        notes: "",
        audioFileName: null,
        originalFileUrl: null,
        audioUrl: "",
        durationInSeconds: null,
        audioFileSizeInBytes: null,
        delayAfterPreviousInDays: 1,
        releaseDate: new Date().toISOString(),
        isSample: 0,
        draft: 1,
        dripReleaseNumber: 1,
        dripReleasePeriod: "days",
        dripReleaseAfter: "last-released",
        expireAfter: "product-access",
        expireAfterNumber: 1,
        expireAfterPeriod: "days",
        contributors: [],
        releaseOrder: "",
        required: 0,
        transcriptionText: "",
        transcribeOnSave: 0,
        resources: [],
        releaseByTags: [],
        isAppSample: false,
        isNewFile: false
      },

      confirmDelete: null,
      confirmBackTo: false,
      showEditResourceModal: false,
      editResourceIndex: null
    };
  }

  componentDidMount() {
    const isEdit = this.props.match.params.episodeId !== "create-single";

    //this.props.dispatch(this.props.setProduct({}));
    //this.props.dispatch(this.props.setEpisode({}));    

    this.setState({
      ...this.state,
      isEdit
    });

    api.product.getProduct(this.props.match.params.id).then(res => {

      this.props.dispatch(this.props.setProduct(res));

      const currentSampleEpisodeCount = res.episodes && res.episodes.reduce(
        (sum, episode) => sum + (episode.isSample || 0),
        0
      );

      let lastReleaseOrder = res.episodes.length ? res.episodes[res.episodes.length - 1].releaseOrder : 0;

      this.setState({
        ...this.state,
        sampleEpisodeCount: currentSampleEpisodeCount,
        lastReleaseOrder: lastReleaseOrder
      });

      if(!isEdit) this.setState({
        ...this.state,
        newEpisodeTemplate: {
          ...this.state.newEpisodeTemplate,
          releaseOrder: lastReleaseOrder + 1
        }
      });

      res.episodes.forEach((episode, key) => {
        if(episode._id === this.props.match.params.episodeId) {
          let futureContributors = [];

          if(Array.isArray(episode.contributors)) {
            const isContributorsEmpty = episode.contributors.length === 0;
            if(isContributorsEmpty) {
              futureContributors = [this.props.productData.instructorName];
            } else {
              futureContributors = episode.contributors;
            }
          }

          this.setState({
            ...this.state,
            contributors: futureContributors,
            newEpisodeTemplate: {
              ...this.state.newEpisodeTemplate,
              contributors: futureContributors
            }
          });
        }
      });

      this.setState({
        ...this.state,
        totalEpisodes: (res.episodes || []).length
      });

    });
    if(this.props.match.params.episodeId !== "create-single") {
      api.episode.getEpisode({ id: this.props.match.params.episodeId }).then(res => {
        this.props.dispatch(this.props.setEpisode(res));
        this.setState({
          newEpisodeTemplate: {
            ...this.props.episodeData,
            dripReleaseNumber: this.props.episodeData.dripReleaseNumber || this.props.episodeData.dripReleaseNumber === 0 || this.props.episodeData.dripReleaseNumber === "0"
              ? this.props.episodeData.dripReleaseNumber
              : ((this.props.episodeData.dripImmediateRelease && "0") || "1"),
            dripReleaseAfter: this.props.episodeData.dripReleaseAfter || "last-released"
          }
        });
      });
    }
  }

  handleContributorsChange = (contributors, cb) => {
    this.setState({ contributors });

    if(cb) {
      cb(contributors);
    }
  };

  render() {
    const isEdit = this.state.isEdit,
      episodeId = isEdit && this.props.match.params.episodeId,
      productId = this.props.match.params.id,
      saveButtonText = isEdit ? "Update" : "Save",
      pageTitleLabel = isEdit ? "Edit Episode" : "New Episode";

    const onAudioUploadCompleted = (uploadedFiles, formik) => {
      if(uploadedFiles.length < 1) {
        onUploadError("No uploaded file found");
      } else if(uploadedFiles.length > 1) {
        onUploadError("Please upload a single file");
      } else {
        let
          fileInfo = uploadedFiles[0],
          isMP3 = "mp3" === fileInfo.file.name.split(".").pop().toLowerCase();

        this.setState({ isNewAudioFileUploadedButNotYetSaved: true });

        formik.setValues({
          ...formik.values,
          audioFileName: fileInfo.file.name,
          audioFileSizeInBytes: fileInfo.file.size,
          originalFileUrl: fileInfo.amazonS3Url,
          audioUrl: isMP3 ? fileInfo.amazonS3Url : "",
          durationInSeconds: fileInfo.durationInSeconds,
          requiresProcessing: !isMP3,
          finishedProcessing: isMP3,
          failedProcessing: false,
          processingFriendlyError: null,
          isNewFile: true,
          //releaseDate: this.props.episodeData.releaseDate,
        });

        formik.setFieldTouched("audioUrl");
      }
    };

    const removeFile = formik => {
      formik.setValues({
        ...formik.values,
        audioFileName: null,
        originalFileUrl: null,
        audioUrl: null
      });
    };
    const onUploadError = message => this.setState({ ...this.state, uploadError: message });

    const sendForm = async (episode, loading = true) => {
      this.setState({ ...this.state, loading });

      if(isEdit) {
        let res = await api.episode.editEpisode({
          episode: {
            ...episode,
          },
        });

        this.setState({ ...this.state, loading: false });

        await updateProducts(this.props.dispatch);

        await updateCurrentUser(this.props.dispatch);

        if(res) {
          this.props.history.push("/products/" + this.props.match.params.id + "/episodes", { ignorePrompt: true });
          toastMessage.success("Episode updated successfully!");
        }
      } else {
        let res = await api.episode.createEpisode({
          id: this.props.match.params.id,
          data: {
            episode: {
              ...episode
            }
          },
        });

        this.setState({ ...this.state, loading: false });

        if(res) {
          this.props.history.push("/products/" + this.props.match.params.id + "/episodes", { ignorePrompt: true });
          toastMessage.success("Episode created successfully!");
        }
      }
    };

    const backTo = formik => {
      let maySave = !_.isEqualWith(this.state.newEpisodeTemplate, formik.values);

      if(maySave)
        return this.setState({ ...this.state, confirmBackTo: true });

      goBackTo();
    };

    const goBackTo = () => {
      this.setState({ ...this.state, confirmBackTo: false });
      this.props.history.push(`/products/${this.props.match.params.id}/episodes`);
    };

    const deleteEpisodes = episodeId => {
      this.setState({ ...this.state, confirmDelete: episodeId });
    };

    const confirmDelete = () => {
      api.episode.deleteEpisode(this.state.confirmDelete).then(() => {
        toastMessage.success("Episode deleted successfully.");
        this.props.history.push(`/products/${this.props.match.params.id}/episodes`, { ignorePrompt: true });
      });

      this.setState({ ...this.state, confirmDelete: null });
    };

    const getInputClasses = (formik, fieldname) => {
      if(formik.touched[fieldname] && formik.errors[fieldname]) {
        return "is-invalid";
      }

      if(formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "is-valid";
      }

      return "";
    };

    const fmtMSS = (s) => (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;

    const getSubTitleDescription = (releaseOrder) => {
      const isFirst = releaseOrder === 1;
      const isNotFirst = releaseOrder > 1;

      if(typeof releaseOrder === "undefined") {
        if(this.state.totalEpisodes === 0) {
          return "This is the first episode";
        } else {
          const prevEpisode = this.props.productData.episodes.find((currentEpisode) => currentEpisode.releaseOrder === this.state.totalEpisodes);

          return prevEpisode ? `Last published episode: "${prevEpisode.title}"` : "";
        }
      } else if(isFirst) {
        return "This is the first episode";
      } else if(isNotFirst) {
        const prevEpisodeOrder = releaseOrder - 1;
        const prevEpisode = this.props.productData.episodes && this.props.productData.episodes.find((currentEpisode) => currentEpisode.releaseOrder === prevEpisodeOrder);

        return prevEpisode ? `Last published episode: "${prevEpisode.title}"` : "";
      }
    };

    /**
     * Executes the form's validation.
     * @param {*} values 
     * @param {*} props 
     */
    const validateForm = (function (values, props) {
      const errors = {};

      if(this.props.productData.releaseMode == _release_immediate || this.props.productData.releaseMode == _release_dripped)
        //Must have a valid releaseOrder
        if(isNaN(values.releaseOrder) || values.releaseOrder < 1) errors.releaseOrder = "Please enter a number.";

      if(this.props.productData.releaseMode == _release_date)
        //Must have a valid releaseDate (we assume it's valid if present because the field is readonly)
        if(!values.releaseDate) errors.releaseDate = "Please select the date.";

      if(this.props.productData.releaseMode == _release_dripped)
        //Must have valid dripReleaseNumber
        if(isNaN(values.dripReleaseNumber)) errors.dripReleaseNumber = "Please enter a number.";

      if(this.props.productData.livePeriod == _expires_episode)
        //Must have valid expireAfterNumber
        if(isNaN(values.expireAfterNumber) || values.expireAfterNumber < 1) errors.expireAfterNumber = "Please enter a number.";

      //Run Yup validations
      try {
        schemas.episode.episodeSchema.validateSync(values, {
          abortEarly: false
        });

        return errors;
      } catch(exception) {
        if(exception.inner) {
          return {
            ...errors,
            ...exception.inner.reduce((obj, error) => ({
              ...obj,
              [error.path]: error.message
            }), {})
          };
        }
      }
    }).bind(this);

    let fileName = url => url.substring(url.lastIndexOf("/") + 1);

    let save = async () => {
      await sendForm(this.formik.current.values);
    };

    function isAppSampleOnChange(formik, ev) {
      formik.setFieldValue("isAppSample", ev.target.checked);
    }

    let isSampleOnChange = (formik, e) => {
      const updatedValue = e.target.checked;
      const futureSampleEpisodeCount = updatedValue ? this.state.sampleEpisodeCount + 1 : this.state.sampleEpisodeCount - 1;

      this.setState({
        ...this.state,
        sampleEpisodeCount: futureSampleEpisodeCount
      });

      if(futureSampleEpisodeCount <= 3) {
        formik.setFieldValue("isSample", updatedValue ? 1 : 0);
      } else {
        toastMessage.error("You already have the maximum sample episodes.");
      }
    };

    let resourceRemoveOnClick = (formik, i) => {
      let arr = [
        ...formik.values.resources
      ];
      arr.splice(i, 1);
      formik.setFieldValue("resources", arr);
    };

    let getResourceEmbed = resource => {
      try {
        return embed(resource.url);
      } catch(error) {
        return <></>;
      }
    };

    return (
      <>
        <h1>
          <Link to={"/products/" + this.props.match.params.id + "/episodes"} className="btn btn-back">
            <SVG src={toAbsoluteUrl("/media/def-image/icons/back.svg")} />
          </Link>
          {pageTitleLabel}
        </h1>

        <div className="container-inner episodeEditPage p-0">
          <div className="d-flex flex-column flex-root upload-page">
            <Formik
              initialValues={this.state.newEpisodeTemplate}
              validate={validateForm}
              onSubmit={values => sendForm(values)}
              enableReinitialize
              validateOnBlur={false}
              validateOnChange={true}
              innerRef={this.formik}
            >
              {formik => (
                <FormFormik>
                  <div className="episode-edit d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat">

                    {isEdit && (
                      <div className="card border-0">
                        <div className="card-body text-right p-0">
                          <button type="button" className="btn btn-secondary mr-3" onClick={() => this.setState({ ...this.state, showConfirmCancel: true })} disabled={!formik.dirty}>Cancel</button>
                          <button type="submit" className={"btn btn-primary " + (this.state.loading === true ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>{saveButtonText}</button>
                        </div>
                      </div>
                    )}

                    <div className="card mb-10">
                      <div className="card-header">
                        <h3 className="card-title m-0">{isEdit ? "Episode Details" : "Episode Details"}</h3>
                        <p className="text-muted">Edit your episode. No worries, you will be able to come back here anytime and change anything you like.</p>
                      </div>

                      <div className="card-body">
                        <div className="form-group">
                          <label className="form-label">Episode status</label>
                          <Switch
                            checked={formik.values.draft != 1}
                            onChange={event => formik.setFieldValue("draft", event.target.checked ? -1 : 1)}
                            value={1}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            name="draft"
                            className="MuiSwitch-root-live blue-switch mr-1"
                          />

                          {formik.values.draft === 1 ?
                            <span className="product-settings-status-label product-settings-status-label-draft">Draft</span>
                            :
                            <span className="product-settings-status-label product-settings-status-label-live text-primary">Live</span>
                          }
                        </div>

                        <Form.Group controlId="exampleForm.ControlInput1" className="product-settings-status-container-input">
                          <label className="form-label">Title <em>*</em></label>

                          <AIField formik={formik} name="title" source="episode" data={{ ...formik.values, episodeId, productId }} prompt={formik.values.title
                            ? "Rewrite this title for an episode:\n\n" + formik.values.title
                            : "Write a title for this episode"}>
                            <input
                              type="text"
                              placeholder=""
                              className={`form-control form-control-lg form-control-solid ${getInputClasses(formik, "title")}`}
                              name="title"
                              {...formik.getFieldProps("title")}
                            />
                          </AIField>
                          
                          <span className="input-sub-description text-gray mt-4 d-block">
                            {getSubTitleDescription(formik.values.releaseOrder)}
                          </span>

                          {formik.touched.title && formik.errors.title ? (
                            <div className="invalid-feedback">
                              {formik.errors.title}
                            </div>
                          ) : null}

                        </Form.Group>

                        <Form.Group className="product-settings-status-container-input product-settings-status-container-dropzone-audio">
                          <label className="form-label">Audio <em>*</em></label>
                          {formik.values.audioUrl || formik.values.originalFileUrl
                            ? (
                              <>
                                <div className="dropzone-audio">
                                  <div className="dropzone-audio-row">
                                    <span className="dropzone-audio-duration">{fmtMSS(Math.floor(formik.values.durationInSeconds))}</span>

                                    <span className="dropzone-msg-desc">{formik.values.audioFileName || fileName(formik.values.audioUrl || formik.values.originalFileUrl)}</span>

                                    <button
                                      type="button"
                                      className="btn btn-remove"
                                      onClick={() => removeFile(formik)}
                                      title="Remove file"
                                    >
                                      <img src={toAbsoluteUrl("/media/def-image/icons/dropzone-audio-btn-remove.svg")} alt="" />
                                    </button>
                                  </div>

                                  <div className="dropzone-audio-row">
                                    <PodcastPlayer
                                      audioUrl={formik.values.audioUrl || formik.values.originalFileUrl}
                                      finishedProcessing={formik.values.finishedProcessing}
                                      failedProcessing={formik.values.failedProcessing}
                                      processingFriendlyError={formik.values.processingFriendlyError}
                                      product={this.props.productData}
                                      episode={formik.values}
                                      isNewFile={formik.values.isNewFile}
                                      isEditForm={true}
                                    />
                                  </div>
                                </div>
                              </>
                            )
                            : <div className="artworkUploadContainer mb-10">
                              <AwsS3Dropzone
                                allowMultipleUpload={false}
                                onUploadComplete={uploadedFiles => onAudioUploadCompleted(uploadedFiles, formik)}
                                onError={onUploadError}
                                accept=".aac, .flac, .aiff, .m4a, .mp3, .ogg, .opus, .wav, .3gp, .avi, .mov, .mp4, .mpeg, .mpg, .webm"
                                maxSize={process.env.REACT_APP_MAX_UPLOAD_SIZE * 1000000}
                                uploadType="raw"
                                validateStorage
                                user={this.props.user}
                                description="Select videos and MP3’s to upload"
                                errorMessage={this.state.uploadError || formik.errors.audioFileName} />
                              {formik.errors.audio && <div className="invalid-feedback-copy">{formik.errors.audio}</div>}
                            </div>}
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlTextarea1" className="big-input product-settings-status-container-input">
                          <label className="form-label">Summary</label>

                          <AIField formik={formik} name="summary" source="episode" data={{ ...formik.values, episodeId, productId }} prompt={formik.values.summary
                            ? "Rewrite this summary of the episode:\n\n" + formik.values.summary
                            : "Write a summary for this episode."}>
                            <textarea
                              placeholder=""
                              className={`form-control form-control-lg form-control-solid ${getInputClasses(formik, "summary")}`}
                              name="summary"
                              rows="4"
                              {...formik.getFieldProps("summary")}
                            />
                          </AIField>

                          {formik.touched.summary && formik.errors.summary ? (
                            <div className="invalid-feedback">
                              {formik.errors.summary}
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="card mb-10">
                      <div className="card-header">
                        <h3 className="card-title m-0">Episode Availability</h3>
                      </div>

                      <div className="card-body">
                        {(this.props.productData.releaseMode == _release_immediate || this.props.productData.releaseMode == _release_dripped) &&
                          <Form.Group className="product-settings-status-container-input w-50">
                            <label className="form-label">Episode number <em>*</em></label>

                            <Form.Control
                              as="select"
                              className={"custom-select form-control-lg form-control-solid " + getInputClasses(formik, "releaseOrder")}
                              name="releaseOrder"
                              {...formik.getFieldProps("releaseOrder")}
                            >
                              {(() => {
                                if(this.state.totalEpisodes == 0) return <option value={1} key={1}>1</option>;
                                let options = [],
                                  i = 1;
                                for(; i <= this.state.totalEpisodes; i++)
                                  options.push(<option value={i} key={i}>{i}</option>);
                                if(!isEdit) options.push(<option value={i} key={i}>{i}</option>);
                                return options;
                              })()}
                            </Form.Control>
                            {/* <input
                              type="number"
                              placeholder=""
                              className={"form-control form-control-lg form-control-solid "+getInputClasses(formik,"releaseOrder")+(this.state.lastReleaseOrder?" mb-1":"")}
                              name="releaseOrder"
                              {...formik.getFieldProps("releaseOrder")}
                            />
                            {this.state.lastReleaseOrder && <p className="gray-text-small">Last published: {this.state.lastReleaseOrder}</p>}
                            */}
                            <ErrorMessage name="releaseOrder">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                          </Form.Group>}

                        {this.props.productData.releaseMode == _release_date &&
                          <Form.Group className="product-settings-status-container-input form-group border">
                            <label className="form-label has-icon">
                              <SVG src="/media/def-image/icons/calendar-color.svg" className="icon" />
                              <span className="label-text">
                                Release date<br />
                                <small>The date and time the episode is available to listeners</small>
                              </span>
                            </label>

                            <Datetime
                              locale="en"
                              name="releaseDate"
                              onChange={val => {
                                try {
                                  formik.setFieldValue("releaseDate", val.toISOString());
                                } catch(e) { }
                              }}
                              value={moment(formik.values.releaseDate)}
                              className="input-date"
                              inputProps={{
                                readOnly: true,
                                className: "form-control form-control-lg form-control-solid " + getInputClasses(formik, "releaseDate")
                              }}
                            />
                            <ErrorMessage name="releaseDate">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                          </Form.Group>}

                        {this.props.productData.releaseMode == _release_dripped &&
                          <Form.Group className="product-settings-status-container-input form-group border">
                            <label className="form-label has-icon">
                              <SVG src="/media/def-image/icons/dripped-color.svg" className="icon" />
                              <span className="label-text">
                                Drip release<br />
                                <small>Set to 0 if you want the episode to be released immedately</small>
                              </span>
                            </label>

                            {formik.values.releaseOrder == 1 && <p className="badge blue-badge mt-4">Immediately</p>}

                            {formik.values.releaseOrder != 1 && (
                              <>
                                <div className="input-row d-flex justify-content-start">
                                  <Form.Control
                                    type="number"
                                    name="dripReleaseNumber"
                                    className={"form-control form-control-lg form-control-width-sm form-control-solid " + getInputClasses(formik, "dripReleaseNumber")}
                                    {...formik.getFieldProps("dripReleaseNumber")} />
                                  <Form.Control as="select" className="custom-select w-auto" name="dripReleasePeriod" {...formik.getFieldProps("dripReleasePeriod")}>
                                    <option value="minutes">minutes</option>
                                    <option value="hours">hours</option>
                                    <option value="days">days</option>
                                    <option value="weeks">weeks</option>
                                    <option value="months">months</option>
                                  </Form.Control>
                                  <Form.Control as="select" className="custom-select w-auto" name="dripReleaseAfter" {...formik.getFieldProps("dripReleaseAfter")}>
                                    <option value="last-released">after previous episode is released</option>
                                    <option value="last-played">after previous episode is played</option>
                                  </Form.Control>
                                </div>

                                <ErrorMessage name="dripReleaseNumber">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                              </>
                            )}
                          </Form.Group>}

                        {this.props.productData.livePeriod == _expires_episode &&
                          <Form.Group className="product-settings-status-container-input form-group border">
                            <label className="form-label has-icon">
                              <SVG src="/media/def-image/icons/show-expires.svg" className="icon" />
                              <span className="label-text">
                                Expire access<br />
                                <small>The show will still appear in the player app but won’t play any episodes</small>
                              </span>
                            </label>

                            <div className="input-row d-flex justify-content-start">
                              <Form.Control type="number"
                                name="expireAfterNumber"
                                className={"form-control form-control-lg form-control-width-sm form-control-solid " + getInputClasses(formik, "expireAfterNumber")}
                                {...formik.getFieldProps("expireAfterNumber")} />
                              <Form.Control as="select" className="custom-select w-auto" name="expireAfterPeriod" {...formik.getFieldProps("expireAfterPeriod")}>
                                <option value="days">Days</option>
                                <option value="weeks">Weeks</option>
                                <option value="months">Months</option>
                              </Form.Control>
                              <select className="custom-select w-auto" name="expireAfter" {...formik.getFieldProps("expireAfter")}>
                                <option value="product-access">After listener has access to the show</option>
                                <option value="episode-access">After listener starts the episode</option>
                                {(this.props.productData.releaseMode == _release_dripped || this.props.productData.releaseMode == _release_date) && <option value="release">After episode is released</option>}
                              </select>
                            </div>

                            <ErrorMessage name="expireAfterNumber">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                          </Form.Group>}

                        <Form.Group className="product-settings-status-container-input form-group border">
                          <label className="form-label has-icon">
                            <SVG src="/media/def-image/icons/tag-color.svg" className="icon" />
                            <span className="label-text">
                              Limit access by tag {!this.props.user.planFeatures.releaseByTags && <UpgradeLink user={user} className="ml-3" />}<br />
                              <small>Only listeners with the following tags can access this episode. <a href="https://hiro.fm/kb/how-to-restrict-access-based-on-tags-pricing-tiers/">Learn more</a></small>
                            </span>
                          </label>

                          {formik.values.releaseOrder == 1
                            ? <span className="badge blue-badge mt-3">First episode is available to all listeners</span>
                            : <TagDropdown onCreateTagRequest={() => this.setState({ ...this.state, showCreateTagModal: true })} disabled={!this.props.user.planFeatures.releaseByTags} product={this.props.productData} placeholder="Select tags" multiple value={formik.values.releaseByTags} onChange={value => formik.setFieldValue("releaseByTags", value)} />}
                        </Form.Group>

                        <EditTagModal autoReload onTagAdded={t => formik.setFieldValue("releaseByTags", [...(formik.values.releaseByTags || []), t])} onHide={() => this.setState({ ...this.state, showCreateTagModal: false })} show={this.state.showCreateTagModal} />
                      </div>
                    </div>

                    <div className="card mb-10">
                      <div className="card-header">
                        <h3 className="card-title m-0">Episode Transcription & Notes</h3>
                      </div>

                      <div className="card-body">
                        <div className="form-group product-settings-status-container-input">
                          <div className="d-md-flex align-items-center mb-2">
                            <label className="form-label mb-0 flex-1">Transcription</label>
                            {this.props.user.planFeatures.transcriptions > -1 && (
                              <span className={"badge " + (this.props.user.planFeatures.transcriptions <= this.props.user.stats.transcriptionsCount ? "red-badge" : "")}>
                                {this.props.user.stats.transcriptionsCount || "0"} / {this.props.user.planFeatures.transcriptions} Remaining
                              </span>
                            )}
                          </div>
                          {this.state.transcriptionRequested
                            ? (
                              <p>The transcription will be available soon. We’ll let you know when it’s done.</p>
                            )
                            : (
                              <>
                                {this.props.episodeData.transcriptionStatus == "failed" && <p className="text-muted">Automatic transcription failed, enter your own or click the <strong>AI</strong> button to try again.</p>}
                                {isEdit || !this.props.episodeData.transcriptionStatus || ["complete", "custom", "failed"].includes(this.props.episodeData.transcriptionStatus)
                                  ? (
                                    <AIField loading={this.state.transcriptionLoading} onClick={async () => {
                                      if(!checkLimits.canRequestTranscription(this.props.user))
                                        return;

                                      if(!isEdit || formik.touched.audioUrl) {
                                        formik.setFieldValue("transcribeOnSave", 1);
                                        return toastMessage.success("The transcription will begin after you save the episode.");
                                      }

                                      this.setState({
                                        ...this.state,
                                        transcriptionLoading: true
                                      });

                                      await api.ai.requestTranscription({
                                        episodeId: this.props.episodeData._id,
                                        audioUrl: formik.values.audioUrl
                                      });

                                      await updateCurrentUser(this.props.dispatch);

                                      this.setState({
                                        ...this.state,
                                        transcriptionLoading: false,
                                        transcriptionRequested: true
                                      });
                                    }}>
                                      <textarea rows="15" {...formik.getFieldProps("transcriptionText")} className={"form-control form-control-lg form-control-solid " + getInputClasses(formik, "transcriptionText")}></textarea>
                                    </AIField>
                                  )
                                  : <p className="text-muted">Transcription in progress. We’ll let you know when it’s done.</p>}
                              </>
                            )}
                        </div>

                        <Form.Group className="product-settings-status-container-editor-section product-settings-status-container-input">
                          <label className="form-label">Notes</label>

                          <AIField editor formik={formik} name="notes" source="episode" data={{ ...formik.values, episodeId, productId }} prompt={formik.values.notes
                            ? "Rewrite this text:\n\n" + formik.values.notes
                            : "Write the Notes & CTA section of this episode"}>
                            <Editor name="notes" formik={formik} />
                          </AIField>
                        </Form.Group>

                        <div className="form-group">
                          <label className="form-label">
                            Resources
                            {!this.props.user.planFeatures.episodeResources && <UpgradeLink className="ml-3" user={this.props.user} />}
                          </label>
                          <div className="text-muted mb-8">
                            Share resources by pasting a link to the image (JPG or PNG only), video or document you want to display in your show notes.
                          </div>

                          {Array.isArray(formik.values.resources) && formik.values.resources.length > 0 && (
                            <div className="d-md-flex episode-resources mb-8">
                              {formik.values.resources.map((resource, i) => (
                                <div className={"episode-resource " + resource.type} key={i}>
                                  <div className="resource-label">
                                    <label>{resource.label}</label>

                                    <button type="button" className="btn btn-edit btn-transparent" onClick={ev => this.setState({ ...this.state, editResourceIndex: i, showEditResourceModal: true, mainFormik: formik })}>
                                      <SVG src="/media/def-image/icons/pencil.svg" />
                                    </button>

                                    <button type="button" className="btn btn-remove btn-transparent" onClick={ev => resourceRemoveOnClick(formik, i)}>
                                      <SVG src="/media/def-image/icons/delete.svg" />
                                    </button>
                                  </div>

                                  {resource.type == "image" && (
                                    <div className="content-placeholder" style={{ backgroundImage: "url(" + resource.url + ")" }} />
                                  )}

                                  {resource.type == "video" && (
                                    <div className="content-placeholder" dangerouslySetInnerHTML={{ __html: getResourceEmbed(resource) }} />
                                  )}

                                  {resource.type == "pdf" && (
                                    <div className="content-placeholder">
                                      <SVG src="/media/def-image/icons/file-xl.svg" className="pdf-icon" />
                                      <label>No preview</label>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}

                          <div className="d-flex gap episode-resources-btns">
                            <button disabled={!this.props.user.planFeatures.episodeResources} type="button" className="btn btn-primary-light" onClick={() => this.setState({ ...this.state, editResourceIndex: null, editResourceDefaultType: "image", showEditResourceModal: true, mainFormik: formik })}>
                              <SVG src="/media/def-image/icons/add-image.svg" className="icon fill-primary" />
                              Add Image
                            </button>

                            <button disabled={!this.props.user.planFeatures.episodeResources} type="button" className="btn btn-primary-light" onClick={() => this.setState({ ...this.state, editResourceIndex: null, editResourceDefaultType: "video", showEditResourceModal: true, mainFormik: formik })}>
                              <SVG src="/media/def-image/icons/add-video.svg" className="icon fill-primary" />
                              Add Video
                            </button>

                            <button disabled={!this.props.user.planFeatures.episodeResources} type="button" className="btn btn-primary-light" onClick={() => this.setState({ ...this.state, editResourceIndex: null, editResourceDefaultType: "pdf", showEditResourceModal: true, mainFormik: formik })}>
                              <SVG src="/media/def-image/icons/add-file.svg" className="icon fill-primary" />
                              Add document
                            </button>
                          </div>
                        </div>

                        {/* <Form.Group className="product-settings-status-container-input mb-0">
                          <label className="form-label">Contributors</label>
                          <TagsInput
                            value={this.state.contributors}
                            onChange={(e) => this.handleContributorsChange(e, (contributors) => formik.setFieldValue("contributors", contributors))}
                            inputProps={{ placeholder: "" }}
                            addOnBlur
                          />
                        </Form.Group> */}
                      </div>
                    </div>

                    <div className="card mb-10">
                      <div className="card-header">
                        <h3 className="card-title m-0">Samples and Automated Events</h3>
                      </div>

                      <div className="card-body">
                        <Form.Group controlId="exampleForm.ControlInput1" className="product-settings-status-container-input form-group border">
                          <label className="form-label">
                            Sample episode
                            {/* <span className="blue-badge ml-3">{this.state.sampleEpisodeCount} of 3 sample episodes set</span> */}
                          </label>

                          <p className="sub-title">
                            Let sales page visitors play a snippet of the episode or allow contacts to play the entire episode in the podcast player app.
                          </p>

                          <label className="checkbox checkbox-lg checkbox-single mb-4">
                            <input type="checkbox" checked={!!formik.values.isSample} onChange={ev => isSampleOnChange(formik, ev)} />
                            <span />
                            <em className="checkbox-label">Yes, include it in a sample on the sales page</em>
                          </label>

                          <label className="checkbox checkbox-lg checkbox-single">
                            <input type="checkbox" disabled={!this.props.user.planFeatures.appSamples} checked={!!formik.values.isAppSample} onChange={ev => isAppSampleOnChange(formik, ev)} />
                            <span />
                            <em className="checkbox-label">
                              Yes, include this episode in a sample on the Hiro player app
                              {!this.props.user.planFeatures.appSamples && <UpgradeLink user={user} className="ml-3" />}
                            </em>
                          </label>
                        </Form.Group>

                        <Form.Group controlId="exampleForm.ControlInput1" className="product-settings-status-container-input form-group border">
                          <label className="form-label has-icon">
                            <SVG src="/media/def-image/automations/abandon-trigger.svg" className="icon" />
                            <span className="label-text">
                              Listener stops <br />
                              <small>Check this box if you want to create an automated event that triggers if this episode isn’t played. You check this box and then create the automation next.</small>
                            </span>
                          </label>

                          <label className="checkbox checkbox-lg checkbox-single">
                            <input type="checkbox" checked={!!formik.values.required} onChange={ev => formik.setFieldValue("required", ev.target.checked ? 1 : 0)} />
                            <span />
                            <em className="checkbox-label">Yes, this is required</em>
                          </label>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="card border-0">
                      <div className={"card-body d-flex p-0 " + (isEdit ? "justify-content-between" : "justify-content-end")}>
                        {isEdit && <a href="#" onClick={() => deleteEpisodes(this.props.match.params.episodeId)} className="btn btn-danger">Delete Episode</a>}
                        <div>
                          <button type="button" className="btn btn-secondary mr-3" onClick={() => this.setState({ ...this.state, showConfirmCancel: true })} disabled={!formik.dirty}>Cancel</button>
                          <button type="submit" className={"btn btn-primary " + (this.state.loading === true ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>{saveButtonText}</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ConfirmModal
                    show={this.state.showConfirmCancel}
                    message="Do you want to discard your changes?"
                    onConfirm={() => (this.setState({ ...this.state, showConfirmCancel: false }), formik.resetForm())}
                    onCancel={() => this.setState({ ...this.state, showConfirmCancel: false })} />
                </FormFormik>
              )}
            </Formik>
          </div>
        </div>

        <Modal centered show={this.state.showEditResourceModal} onHide={() => this.setState({ ...this.state, showEditResourceModal: false })} size="md" className="new-modals edit-episode-resource-modal">
          <Formik
            initialValues={this.state.editResourceIndex === null
              ? {
                type: this.state.editResourceDefaultType,
                url: "",
                label: ""
              }
              : this.state.mainFormik.values.resources[this.state.editResourceIndex]}
            validate={(values, props) => {
              let errors = {};

              if(!values.label?.trim())
                errors.label = "Enter the resource name.";

              if(!values.url?.trim()) {
                errors.url = "Enter the URL.";
              } else {
                let url;

                try {
                  url = new URL(values.url);
                } catch(error) { }

                if(!url) {
                  errors.url = "Enter a valid URL.";
                } else if(url.protocol != "https:") {
                  errors.url = "The URL should begin with https://";
                } else if(values.type == "video") {
                  let video;

                  try {
                    video = embed(values.url);
                  } catch(error) { }

                  if(!video)
                    errors.url = "Enter a YouTube or Vimeo URL.";
                }
              }

              return errors;
            }}
            onSubmit={values => {
              let arr = [
                ...this.state.mainFormik.values.resources
              ];
              if(this.state.editResourceIndex === null)
                arr.push(values);
              else
                arr.splice(this.state.editResourceIndex, 1, values);
              this.state.mainFormik.setFieldValue("resources", arr);
              this.setState({ ...this.state, showEditResourceModal: false });
            }}
            enableReinitialize>
            {formik => (
              <FormFormik>
                <Modal.Header>
                  <h1>
                    {this.state.editResourceIndex === null
                      ? "New resource"
                      : "Edit resource"}
                  </h1>
                </Modal.Header>

                <Modal.Body>
                  <div className="form-group">
                    <label className="form-label">File Type</label>
                    <div>
                      <span className="badge">
                        {formik.values.type == "image" ? "Image" : ""}
                        {formik.values.type == "video" ? "Video" : ""}
                        {formik.values.type == "pdf" ? "Document" : ""}
                      </span>
                    </div>
                    {/* <div className="d-flex flex-wrap gap-20px">
                      <a href="#" className={"resource-type-option " + (formik.values.type == "image" ? "active" : "")} onClick={ev => (ev.preventDefault(), formik.setFieldValue("type", "image"))}>
                        <SVG src="/media/def-image/icons/image-lg.svg" />
                        <label>Image</label>
                      </a>

                      <a href="#" className={"resource-type-option " + (formik.values.type == "video" ? "active" : "")} onClick={ev => (ev.preventDefault(), formik.setFieldValue("type", "video"))}>
                        <SVG src="/media/def-image/icons/video-lg.svg" />
                        <label>Video</label>
                      </a>

                      <a href="#" className={"resource-type-option " + (formik.values.type == "pdf" ? "active" : "")} onClick={ev => (ev.preventDefault(), formik.setFieldValue("type", "pdf"))}>
                        <SVG src="/media/def-image/icons/file-lg.svg" />
                        <label>File</label>
                      </a>
                    </div> */}
                  </div>

                  <div className="form-group">
                    <label className="form-label">Link Name</label>
                    <input type="text" className={getInputClassName(formik, "label")} {...formik.getFieldProps("label")} />
                    {formik.touched.label && formik.errors.label && <div className="field-error">{formik.errors.label}</div>}
                  </div>

                  <div className="form-group">
                    <label className="form-label">Link URL</label>
                    <input type="text" className={getInputClassName(formik, "url")} {...formik.getFieldProps("url")} />
                    {formik.touched.url && formik.errors.url && <div className="field-error">{formik.errors.url}</div>}
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <button type="button" className="btn btn-secondary mr-2" onClick={() => this.setState({ ...this.state, showEditResourceModal: false })}>Cancel</button>
                  <button type="submit" className="btn btn-primary" disabled={!(formik.isValid && formik.dirty)}>
                    {this.state.editResourceIndex === null
                      ? "Add"
                      : "Ok"}
                  </button>
                </Modal.Footer>
              </FormFormik>
            )}
          </Formik>
        </Modal >

        <ConfirmModal
          show={this.state.confirmDelete}
          message="Do you want to delete this episode?"
          onConfirm={confirmDelete}
          onCancel={() => this.setState({ ...this.state, confirmDelete: false })} />

        <UnsavedFormGuard formikRef={this.formik} onSaveAsync={save} loading={this.state.loading == "modal"} />
      </>
    );
  }
}

export default injectIntl(connect(
  state => ({
    productData: (state.product || {}).product || {},
    episodeData: (state.episode || {}).episode || {},
    user: (state.auth || {}).user || {},
  }),

  dispatch => ({
    ...actions.product,
    ...actions.episode,
    dispatch,
  }),
)(EpisodeEdit));
