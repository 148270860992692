// Core
import React, { useState } from 'react';
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";

// Components
import { AsideCustomProductLabel } from '../../../../../../../_metronic/layout/components/aside/AsideCustomProductLabel';

// Other
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { colors } from './colors';
import { icons } from './icons';
import UpgradeLink from '../../../../../generic/UpgradeLink';
import AIButton from '../../../../../../layout/ai/AIButton';

export const CoverUploader = (props) => {
    const {
        color,
        icon,
        url,
        dropzone,
        formHandler,
        user,
        product,
        formData
    } = props;

    const [currentColor, setCurrentColor] = useState(color || 'color-9');
    const [currentIcon, setCurrentIcon] = useState(icon || 'icon-1');
    const [isEditIconHovered, setEditIconHovered] = useState(false);
    const [isRemoveIconHovered, setRemoveIconHovered] = useState(false);

    let [show, setShow] = useState(false);

    const setEditHover = () => {
        setEditIconHovered(true);
    };

    const disableEditHover = () => {
        setEditIconHovered(false);
    };

    const setRemoveHover = () => {
        setRemoveIconHovered(true);
    };

    const disableRemoveHover = () => {
        setRemoveIconHovered(false);
    };

    const iconHoveredClass = (isEditIconHovered || isRemoveIconHovered) ? 'hovered' : '';
    const coverClasses = `coverUploaderComponent-cover ${iconHoveredClass}`;

    const iconJSX = (currentIcon && !url) && (
        <SVG
            src={toAbsoluteUrl(`/media/def-image/create-product-icons/${currentIcon}.svg`)}
        />
    );

    const imageJSX = url && (
        <img
            src={url}
        />
    );

    const removeHandler = () => {
        formHandler('artwork', {
            url: '',
            color: currentColor,
            icon: currentIcon,
        });
        disableEditHover();
        disableRemoveHover();
    };

    const removeJSX = url && (
        <>
            <button
                type='button'
                className='coverUploaderComponent-cover-remove-btn'
                onMouseEnter={setRemoveHover}
                onMouseLeave={disableRemoveHover}
                onClick={removeHandler}
            >
                <img
                    src={toAbsoluteUrl("/media/def-image/icons/delete.svg")}
                />
            </button>
        </>
    );

    const coverJSX = ((currentColor && currentIcon) || url) && (
        <div className={coverClasses}>
            <div className={`coverUploaderComponent-cover-container ${currentColor}`}>
                {iconJSX}
                {imageJSX}
            </div>
            {removeJSX}
            <Dropdown.Toggle onClick={ev => setShow(!show)} className='coverUploaderComponent-cover-edit-btn' onMouseEnter={setEditHover} onMouseLeave={disableEditHover}>
                <img
                    src={toAbsoluteUrl(!show ? "/media/def-image/icons/pencil.svg" : "/media/def-image/close.svg")}
                />
            </Dropdown.Toggle>
        </div>
    );

    const changeColor = (colorId) => {
        setCurrentColor(colorId);
        formHandler('artwork', {
            color: colorId,
            icon: currentIcon,
        });
    };

    const changeIcon = (iconId) => {
        setCurrentIcon(iconId);
        formHandler('artwork', {
            color: currentColor,
            icon: iconId,
        });
    };

    function setImageFromUrl(url) {
        formHandler("artwork", { url });
    }

    const colorsJSX = colors && colors.map((colorElement) => {
        const selectedJSX = colorElement.id === currentColor ? (
            <img
                alt=''
                src={toAbsoluteUrl('/media/def-image/create-product-icons/selected.svg')}
            />
        ) : null;

        return (
            <div
                key={colorElement.id}
                className='colors-control-item'
                style={{ backgroundColor: colorElement.color }}
                onClick={() => changeColor(colorElement.id)}
            >
                {selectedJSX}
            </div>
        );
    });

    const iconsJSX = icons && icons.map((iconElement) => {
        const defaultIconClass = 'icons-control-item';
        const iconClasses = iconElement.id === currentIcon ? `${defaultIconClass} active` : defaultIconClass;

        return (
            <div key={iconElement.id} className={iconClasses} onClick={() => changeIcon(iconElement.id)}>
                <SVG
                    src={toAbsoluteUrl(`/media/def-image/create-product-icons/${iconElement.resource}.svg`)}
                />
            </div>
        );
    });

    const editJSX = (
        <Dropdown.Menu>
            <div className='coverUploaderComponent-edit'>
                <div className='coverUploaderComponent-edit-column'>
                    <div className='coverUploaderComponent-edit-column-header'>
                        <span className='coverUploaderComponent-edit-column-header-text fill'>Create cover</span>
                        <AIButton image data={{ ...formData, productId: product?._id }} onResponse={url => setImageFromUrl(url)} />
                    </div>
                    <div className='coverUploaderComponent-edit-column-cover-control'>
                        <div className='colors-control'>
                            {colorsJSX}
                        </div>
                        <div className='icons-control'>
                            {iconsJSX}
                        </div>
                    </div>
                </div>
                <div className='coverUploaderComponent-edit-column'>
                    <div className='coverUploaderComponent-edit-column-header'>
                        <span className='coverUploaderComponent-edit-column-header-text'>Upload own cover</span>
                        {!user.planFeatures.customCoverArt ? <UpgradeLink user={user} /> : ""}
                    </div>
                    <div className={!user.planFeatures.customCoverArt ? "dropzone-disabled" : ""}>
                        {dropzone}
                    </div>
                </div>
            </div>
        </Dropdown.Menu>
    );

    return (
        <div className='coverUploaderComponent'>
            <Dropdown show={show}>
                {coverJSX}
                {editJSX}
            </Dropdown>
        </div>
    );
};
