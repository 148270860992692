import React from 'react';
import { useTranslation } from 'react-i18next';
import { displayPrice, numberFormat } from '../../../../helpers';

export function Price({ price, label, discountPrice = null }) {
  const { t } = useTranslation();

  price = displayPrice(price, t);

  if (!price) return <></>;

  return (
    <div className='price'>
      {price.free ? (
        <strong>Free</strong>
      ) : (
        <>
          {label && (
            <>
              <label className='fs-7'>{t('Total payment')}</label>
              <br />
            </>
          )}

          {discountPrice !== null ? (
            <>
              <s className='fw-normal'>
                {price.currency}
                {price.value}
              </s>
              {discountPrice > 0 ? (
                <strong className='pl-3 text-primary'>
                  {price.currency}
                  {numberFormat(discountPrice)}
                </strong>
              ) : (
                <strong className='pl-3 text-primary'>Free</strong>
              )}
            </>
          ) : (
            <strong>
              {price.currency}
              {price.value}
            </strong>
          )}

          {price.periodLong && (
            <>
              <br />
              <small className='text-muted'>{price.periodLong}</small>
            </>
          )}
        </>
      )}
    </div>
  );
}
