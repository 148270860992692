import React, { useEffect } from "react";
import SVG from "react-inlinesvg";

function GoogleLogin({ label, onSuccess, onFailure, scope, state, loading = false, className = "", icon = "google", promptConsent, clearUrl = true, refreshToken }) {
	useEffect(() => {
		let qs = new URLSearchParams(window.location.hash.substring(1)),
			token = qs.get("google_at"),
			rToken = qs.get("google_rt");

		if(clearUrl)
			window.location.hash = window.location.hash
				.replace(/&?google_at=[^&]+/, "")
				.replace(/&?google_rt=[^&]+/, "");

		if(!token)
			return;

		if(token == "failed") {
			if(onFailure)
				onFailure();
			return;
		}

		if(onSuccess)
			onSuccess(refreshToken
				? {
					accessToken: token,
					refreshToken: rToken
				}
				: token);
	}, []);

	let onClick = ev => {
		window.location.href = "https://accounts.google.com/o/oauth2/v2/auth?scope=" + encodeURIComponent(scope) +
			"&include_granted_scopes=true&response_type=code&redirect_uri=" + encodeURIComponent(process.env.REACT_APP_SERVER_URL + "api/google-oauth") +
			"&client_id=" + encodeURIComponent(process.env.REACT_APP_GOOGLE_CLIENT_ID) + "&state=" + encodeURIComponent(state) + "&access_type=offline" +
			"&prompt=" + (promptConsent ? "select_account%20consent" : "select_account");
	};

	return (
		<button type="button" className={"btn btn-secondary btn-google-login " + className + " " + (loading ? "loading spinner" : "")} onClick={onClick}>
			<SVG src={"/media/def-image/" + icon + ".svg"} />
			{label || "Sign in with Google"}
		</button>
	);
}

export default GoogleLogin;