import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";

export function SelectedOption({ option, image = true, forceSelectedSubtitle, subtitle, canRemove, removeOnClick, className, preview }) {
    return (
        <div className={"selected-option " + (preview ? "selected-option-preview " : "") + (option.className || "") + " " + (className || "")}>
            {image && (option.image
                ? (option.image.match(/\.svg/)
                    ? <SVG src={option.image} />
                    : <img src={option.image} />)
                : <div className="no-img" />)}
            <label>
                {option.label}
                {(forceSelectedSubtitle || option.subtitle || subtitle) && (
                    <>
                        <br />
                        <span className="subtitle">{forceSelectedSubtitle || option.subtitle || subtitle}</span>
                    </>
                )}
            </label>
            {canRemove && !option.hideRemoveButton && <span className="remove" onClick={ev => removeOnClick(ev, option)}>&times;</span>}
        </div>
    );
}

function CustomSelect({ showReset, options, groups, loading, image = true, dropdownFooter, excludeSelected, disabled, lg, multiple, forceSelectedSubtitle, transparent, autoSelect, subtitle, className, noSelectionText, emptyDropdownText, emptyText, onChange, value, placeholder }) {
    let [selected, setSelected] = useState([]),
        [group, setGroup] = useState();

    function triggerOnChange(list) {
        let nextValue = list.map(p => p.id);

        if(!onChange || _.isEqual(value, nextValue))
            return;

        onChange(nextValue);
    }

    function resetOnClick(ev) {
        ev.stopPropagation();
        setSelected([]);
        triggerOnChange([]);
    }

    function optionOnClick(ev, option) {
        ev.preventDefault();

        setSelected(selected => {
            if(selected.find(o => o.id == option.id))
                return selected;

            let list;

            if(multiple) {
                list = _.cloneDeep(selected);
                list.push(option);
            } else {
                list = [option];
            }

            triggerOnChange(list);

            return list;
        });
    }

    function removeOnClick(ev, option) {
        ev.preventDefault();
        ev.stopPropagation();

        setSelected(selected => {
            let list = _.cloneDeep(selected);
            list = list.filter(o => option.id != o.id);

            triggerOnChange(list);

            return list;
        });
    }

    useEffect(() => {
        if(!options || !options.length)
            return;

        if((!value || !value.length) && autoSelect) {
            triggerOnChange([options[0]]);
            return;
        }

        let arr;
        if(!value) {
            arr = [];
        } else if(!Array.isArray(value)) {
            arr = [value];
        } else {
            arr = value;
        }

        let selected = [];
        for(let elem of arr) {
            let option = options.find(p => p.id == elem);
            if(option)
                selected.push(option);
        }

        setGroup(Array.isArray(groups) && groups.length
            ? (groups.find(g => g.default) || groups[0]).name
            : null);

        setSelected(selected);
    }, [value, options, groups]);

    let filteredOptions = options.filter(option => (!group || !option.groups || option.groups.includes(group)) && (!excludeSelected || !selected.find(o => o.id == option.id)));

    if(!options || !options.length)
        return <label className="d-block mt-3 mb-8 text-muted text-center">{emptyText || "No options available."}</label>;

    return (
        <Dropdown className={"select-dropdown " + (lg ? "select-dropdown-lg " : "") + (multiple ? "multiple " : "single ") + (transparent ? "transparent " : "") + (loading ? "loading spinner spinner-sm " : "") + className}>
            <Dropdown.Toggle disabled={disabled} className="custom-select" as="div">
                {selected.map((option, i) => (
                    <SelectedOption option={option} key={i} image={image} forceSelectedSubtitle={forceSelectedSubtitle} subtitle={subtitle} canRemove={multiple} removeOnClick={removeOnClick} />
                ))}
                {noSelectionText && !selected.length && (
                    <div className="selected-option no-selected-option">
                        {image && <div className="no-img" />}
                        <label>
                            {noSelectionText}
                            {subtitle && (
                                <>
                                    <br />
                                    <span className="subtitle">{subtitle}</span>
                                </>
                            )}
                        </label>
                    </div>
                )}
                {placeholder && !selected.length && (
                    <div className="placeholder">{placeholder}</div>
                )}
                {showReset && selected.length > 0 && <span className="reset" onClick={resetOnClick}>&times;</span>}
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount>
                {Array.isArray(groups) && groups.length > 0 && (
                    <div className="dropdown-groups">
                        {groups.map(g => (
                            <button key={g.name} type="button" className={"dropdown-group " + (group == g.name ? "active" : "")} onClick={() => setGroup(g.name)}>{g.label}</button>
                        ))}
                    </div>
                )}

                {filteredOptions.map((option, i) => (
                    <Dropdown.Item key={i} onClick={ev => optionOnClick(ev, option)}>
                        {image && (option.image
                            ? (option.image.match(/\.svg/)
                                ? <SVG src={option.image} />
                                : <img src={option.image} />)
                            : <div className="no-img" />)}
                        <label>
                            {option.label}
                            {option.subtitle && (
                                <>
                                    <br />
                                    <span className="subtitle">{option.subtitle}</span>
                                </>
                            )}
                        </label>
                    </Dropdown.Item>
                ))}

                {filteredOptions.length == 0 && (
                    <div className="text-muted p-9 text-center">
                        {group
                            ? groups.find(g => g.name == group).emptyDropdownText || emptyDropdownText || "There are no more options to select."
                            : emptyDropdownText || "There are no more options to select."}
                    </div>
                )}

                {options.length > 0 && dropdownFooter && (
                    <div className="dropdown-footer">
                        {dropdownFooter}
                    </div>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default CustomSelect;