import { useEffect } from 'react';

/**
 * Hook that sets the body background as transparent and restores it when unmounting
 * @param {string} color - Background color (default: 'transparent')
 * @param {boolean} restoreOnUnmount - Whether to restore the original color on unmount (default: true)
 * @returns {void}
 */
export function useTransparentBackground(
  color = 'transparent',
  restoreOnUnmount = true
) {
  useEffect(() => {
    // Save the original background
    const originalBackground = document.body.style.backgroundColor;

    // Apply transparent background
    document.body.style.backgroundColor = color;

    // Restore on unmount
    return () => {
      if (restoreOnUnmount) {
        document.body.style.backgroundColor = originalBackground;
      }
    };
  }, [color, restoreOnUnmount]);
}
