import React, { useState } from 'react';
import EmbedOptions from './EmbedOptions';
import EmbedCode from './EmbedCode';

function EmbedFeature({ productId, productLive, publicUri }) {
  const [selectedOption, setSelectedOption] = useState('player');

  return (
    <div className='card mb-10 p-0'>
      <div className='card-header'>
        <h3 className='card-title fw-bold m-0'>Embed</h3>
      </div>
      <div className='card-body pt-9 pb-9'>
        <h4 className='mb-2'>Embed code</h4>
        <p className='text-muted mb-6'>
          Select the feature you want to embed on your site.
        </p>

        <EmbedOptions
          selectedOption={selectedOption}
          onOptionSelect={setSelectedOption}
        />
      </div>
      <EmbedCode
        productId={productId}
        embedType={selectedOption}
        productLive={productLive}
        publicUri={publicUri}
      />
    </div>
  );
}

export default EmbedFeature;
