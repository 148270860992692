import _ from "lodash";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../../../redux/actions";
import 'react-phone-number-input/style.css';
import { toastMessage } from "../../../../../helpers";
import ContactEditForm from "../../../../contacts/ContactEditForm";
import { Modal } from "react-bootstrap";

function ModalEditCustomer({ show, onHide, productId, studentId, user }) {
  let onSuccess = (hasWarning) => {
    if(!hasWarning)
      toastMessage.success(studentId
        ? "Listener updated!"
        : "Listener created!");
    onHide();
  };

  return (
    <>
      <Modal onHide={onHide}
        show={show}
        size="md"
        centered
        className="new-modals new-listeners-modal">
        <Modal.Header>
          <h1>{studentId ? "Edit listener" : "Create listener"}</h1>
        </Modal.Header>
        <ContactEditForm isModalBody user={user} type="listeners" updateDuplicated productIds={[productId]} id={studentId} onCancel={() => onHide()} onSuccess={onSuccess} />
      </Modal>
    </>
  );
}

export default injectIntl(connect(
  state => ({
    products: state.product.products,
    listener: state.listener.listener,
    user: state.auth.user
  }),

  dispatch => ({
    ...actions.listener,
    dispatch,
  }),
)(ModalEditCustomer));
