import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/sv';
import 'moment/locale/ru';
import { Scrollbars } from 'react-custom-scrollbars';
import { formatLength } from '../../../helpers';
import { PlayerEvents } from '../../generic/Player';
import { useTranslation } from 'react-i18next';
import { momentLocales } from '../../../../locales';

/**
 * Component EpisodeList
 * @param {string} [props.className]
 * @param {boolean} [props.desktop]
 * @param {boolean} [props.mobile]
 * @param {Object} [props.product]
 * @returns {Object}
 */
export function EpisodeList({ product, className, desktop, mobile }) {
  let [displayed, setDisplayed] = useState(4),
    [shadowOpacity, setShadowOpacity] = useState(0),
    [playingSample, setPlayingSample] = useState(null),
    playerCallback = (state, data) => {
      if (state == 'playing') setPlayingSample(data.episode._id);
      else setPlayingSample(null);
    },
    lastUpdate = null,
    { t, i18n } = useTranslation();

  for (let i = 0; i < product.episodes.length; i++) {
    let date = moment(product.episodes[i].updatedAt);
    if (!lastUpdate || lastUpdate.valueOf() < date.valueOf()) lastUpdate = date;
  }

  let isSinglePrice = product.prices.length == 0;

  let list = [],
    to = desktop
      ? product.episodes.length
      : Math.min(product.episodes.length, displayed);
  for (let i = 0; i < to; i++) {
    let episode = product.episodes[i],
      tags = [];

    if (!isSinglePrice) {
      if (i == 0)
        //all listeners have access to the first episode
        for (let price of product.prices) tags.push(price.name);
      else if (episode.releaseByTags.length > 0)
        //show prices with the same tags
        for (let tag of episode.releaseByTags)
          for (let price of product.prices)
            if (price.tags.includes(tag) && !tags.includes(price.name))
              tags.push(price.name);
    }

    list.push(
      <div key={i} className='d-flex episode-item align-items-center'>
        <div
          className={'icon ' + (product.episodes[i].isSample ? 'play' : 'lock')}
          onClick={() => {
            if (
              !product.episodes[i].isSample ||
              !product.episodes[i].publicAudio.audioUrl
            )
              return;
            if (playingSample == product.episodes[i]._id)
              return PlayerEvents.dispatchPause();
            PlayerEvents.dispatchPlayEpisode(product, product.episodes[i], {
              stopAt: 180,
              callback: playerCallback,
            });
          }}
        >
          <SVG
            src={toAbsoluteUrl(
              product.episodes[i].isSample
                ? playingSample == product.episodes[i]._id
                  ? '/media/svg/icons/Media/Pause.svg'
                  : '/media/svg/icons/Media/Play.svg'
                : '/media/def-image/icons/create/lock.svg'
            )}
            className='svg-icon'
          />
        </div>
        <div className='title flex-grow-1'>
          {product.episodes[i].title}

          {tags.length > 0 && (
            <div className='tags mt-2'>
              {tags.map((t) => (
                <span className='badge gray-badge me-2' key={t}>
                  {t}
                </span>
              ))}
            </div>
          )}
        </div>
        <div className='length'>
          {formatLength(product.episodes[i].publicAudio.durationInSeconds)}
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        'episode-list ' +
        (desktop && 'desktop ') +
        (mobile && 'mobile ') +
        (className || '')
      }
    >
      <div className='summary d-flex align-items-center'>
        <div className='flex-grow-1 title'>
          <h1>{t('Episodes')}</h1>
          <p className='text-gray-600'>
            {product.episodes.length} {t('episodes')} (
            {formatLength(product.totalDuration)})
          </p>
        </div>
        {lastUpdate && (
          <div className='last-update text-right'>
            <span className='text-muted'>{t('Last update:')}</span>
            <br />{' '}
            {moment(lastUpdate)
              .locale(momentLocales[i18n.language || 'en'])
              .format('D MMM Y')}
          </div>
        )}
      </div>
      <div className='list scrollbar'>
        <div style={{ opacity: shadowOpacity }} className='scrollbar-shadow' />
        {list.length ? (
          <>
            <Scrollbars
              autoHeight
              hideTracksWhenNotNeeded
              autoHeightMax={585}
              className='scrollbar-view'
              renderTrackVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{ ...style }}
                  className='scrollbar-track'
                />
              )}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  {...props}
                  style={{ ...style }}
                  className='scrollbar-thumb'
                />
              )}
              onUpdate={(v) => {
                let { scrollTop } = v;
                setShadowOpacity(scrollTop > 0 ? 1 : 0);
              }}
            >
              {list}
            </Scrollbars>
          </>
        ) : (
          <p className='empty'>
            {t('This audio feed doesn’t have any eposides yet.')}
          </p>
        )}
      </div>
      {product.episodes.length <= displayed ? (
        ''
      ) : (
        <button
          type='button'
          onClick={() => setDisplayed(displayed + 4)}
          className='btn-see-more'
        >
          {t('See more')}{' '}
          <SVG
            src={toAbsoluteUrl('/media/svg/icons/Navigation/Angle-down.svg')}
            className='svg-icon'
          />
        </button>
      )}
    </div>
  );
}
