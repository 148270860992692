import React, { useEffect, useState, Fragment, useRef } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import actions from "../../../redux/actions";
import SVG from "react-inlinesvg";
import api from "../../../redux/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { getSmsBlockLength, toastMessage } from "../../helpers";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { Switch } from "@material-ui/core";
import _ from "lodash";
import ProductDropdown from "../../layout/components/ProductDropdown";
import Preview from "../../layout/components/Preview";
import Datetime from "react-datetime";
import { checkLimits } from "../../plans";
import UnsavedFormGuard from "../../layout/components/UnsavedFormGuard";
import { PhoneNumberModalEvents } from "../modals/PhoneNumberModal";
import { NeedsUpgradeEvents } from "../modals/NeedsUpgrade";
import TagDropdown from "../../layout/components/TagDropdown";
import UpgradeLink from "../generic/UpgradeLink";
import { EditTagModal } from "../contacts/Tags";

let lastUserId = null;

let FormValidation = Yup.object().shape({
  code: Yup.string().required("Required field."),
  //products: Yup.array().required("Select at least one product."),
  steps: Yup.array().of(
    Yup.object().shape({
      text: Yup.string()
        //.test("step-text-charset", "It looks like you used emojis or symbols that aren’t supported while this feature is in beta.", v => isValidSmsText(v || ""))
        .test("step-text-length", "Your message is too long.", function(v) {
          return (v || "").length <= getSmsBlockLength(v) - (this.parent.type == "access-sms" ? 30 : 0);
        })
    })
  )
});

let DragHandle = SortableHandle(() => (
  <div className="sortable-handle">
    <SVG src="/media/def-image/handle.svg" />
  </div>
));

let SortableCard = SortableElement(({ children }) => (
  <>{children}</>
));

let SortableCardsContainer = SortableContainer(({ children }) => {
  return (
    <div className="sortable-cards">
      {children.map((child, key) => (
        <SortableCard key={child.props.id} index={child.props.order}>
          {child}
        </SortableCard>
      ))}
    </div>
  );
});

function getInputClasses(formik, name) {
  if(!_.get(formik.touched, name))
    return "";
  if(_.get(formik.errors, name))
    return "is-invalid";
  return "is-valid";
}

function getInputMessage(formik, name, className = "") {
  if(_.get(formik.touched, name) && _.get(formik.errors, name))
    return (
      <div className={"invalid-feedback-copy " + className}>{_.get(formik.errors, name)}</div>
    );
  return <></>;
}

let previewTimer;

let emptyValues = {
  code: "",
  text: "",
  status: "published",
  startDate: moment().startOf("day").toDate(),
  accessType: "add-products",
  products: [],
  steps: [
    {
      id: "step-1",
      type: "collect-email",
      order: 0,
      enabled: 1,
      text: "Reply with your email address and your Audio Product will be sent straight to your inbox."
    },
    {
      id: "step-2",
      type: "collect-name",
      order: 1,
      enabled: 1,
      text: "Please reply with your name so we can personalize your emails."
    },
    {
      id: "step-3",
      type: "access-sms",
      order: 2,
      text: "Tap this link to access (show name)"
    }
  ]
},
  initialValues = emptyValues;

function CollectEmailStep({ formik, step, index }) {
  let name = "steps[" + index + "].",
    text = formik.values.steps[index].text,
    [maxLength, setMaxLength] = useState(160);

  useEffect(() => {
    setMaxLength(getSmsBlockLength(_.get(formik.values, name + "text")));
  }, [formik.values]);

  return (
    <div className="card p-9">
      <DragHandle />
      <div className="d-flex align-items-center mb-3">
        <Switch checked={!!step.enabled} onChange={ev => formik.setFieldValue(name + "enabled", ev.target.checked ? 1 : 0)} className="mr-2" />
        <h2 className="fill m-0">Collect Email</h2>
      </div>
      <p className="text-muted mb-5">If this is a new listener, reply with a message asking for an email address.</p>
      <div className="form-group">
        <label className="form-label">Text message</label>
        <textarea rows="2" className={"form-control " + getInputClasses(formik, name + "text")} {...formik.getFieldProps(name + "text")} />
        <div className="d-flex mt-2 align-items-center">
          <div className="fill pr-5">{getInputMessage(formik, name + "text", "mt-0")}</div>
          <div className={text.length > maxLength ? "text-danger" : ""}>{maxLength - text.length}</div>
        </div>
      </div>
    </div>
  );
}

function CollectNameStep({ formik, step, index }) {
  let name = "steps[" + index + "].",
    text = formik.values.steps[index].text,
    [maxLength, setMaxLength] = useState(160);

  useEffect(() => {
    setMaxLength(getSmsBlockLength(_.get(formik.values, name + "text")));
  }, [formik.values]);

  return (
    <div className="card p-9">
      <DragHandle />
      <div className="d-flex align-items-center mb-3">
        <Switch checked={!!step.enabled} onChange={ev => formik.setFieldValue(name + "enabled", ev.target.checked ? 1 : 0)} className="mr-2" />
        <h2 className="fill m-0">Collect Name</h2>
      </div>
      <p className="text-muted mb-5">If this is a new listener, reply with a message asking for a name.</p>
      <div className="form-group">
        <label className="form-label">Text message</label>
        <textarea rows="2" className={"form-control " + getInputClasses(formik, name + "text")} {...formik.getFieldProps(name + "text")} />
        <div className="d-flex mt-2 align-items-center">
          <div className="fill pr-5">{getInputMessage(formik, name + "text", "mt-0")}</div>
          <div className={text.length > maxLength ? "text-danger" : ""}>{maxLength - text.length}</div>
        </div>
      </div>
    </div>
  );
}

function AccessSMSStep({ formik, step, index }) {
  let name = "steps[" + index + "].",
    text = formik.values.steps[index].text,
    [maxLength, setMaxLength] = useState(160);

  useEffect(() => {
    setMaxLength(getSmsBlockLength(_.get(formik.values, name + "text")) - 30);
  }, [formik.values]);

  return (
    <div className="card p-9">
      <DragHandle />
      <h2 className="fill">
        {formik.values.accessType == "no-product" ? "Thank You Message" : "Access Message"}
      </h2>
      <div className="form-group">
        <label className="form-label">Text message</label>
        <textarea rows="2" className={"form-control " + getInputClasses(formik, name + "text")} {...formik.getFieldProps(name + "text")} />
        <div className="d-flex mt-2 align-items-center">
          <div className="fill pr-5">{getInputMessage(formik, name + "text", "mt-0")}</div>
          <div className={text.length > maxLength ? "text-danger" : ""}>{maxLength - text.length}</div>
        </div>
      </div>
    </div>
  );
}

function SMSCodeEdit({ user, products, match, dispatch, fulfillUser }) {
  let [loading, setLoading] = useState(match.params.id),
    [saving, setSaving] = useState(false),
    [downgraded, setDowngraded] = useState(false),
    [previewBody, setPreviewBody] = useState(),
    [showCreateTagModal, setShowCreateTagModal] = useState(false),
    history = useHistory(),
    currentIdRef = useRef(),
    formik = useFormik({
      initialValues,
      validationSchema: FormValidation,
      enableReinitialize: true,
      onSubmit: data => submitForm(data)
    });

  let submitForm = async (data, loading = true) => {
    setSaving(loading);

    let res = match.params.id
      ? await api.sms.update(match.params.id, data)
      : await api.sms.create(data);

    setSaving(false);

    if(res && res.success) {
      toastMessage.success("Keyword " + (match.params.id ? "updated!" : "created!"));

      res = await api.auth.getUserByToken(true);
      if(res)
        dispatch(fulfillUser(res.data));

      history.push("/sms", { ignorePrompt: true });
    } else {
      toastMessage.error(res.error || "Unable to save the keyword. Please, try again later.");
    }
  };

  let save = async () => {
    await submitForm(formik.values, "modal");
  };

  let loadItem = async () => {
    if(match.params.id) {
      let res = await api.sms.get(match.params.id);

      if(!res || !res.success) {
        toastMessage.error(res.error || "Unable to retreive the keyword details.");
        history.push("/sms", { ignorePrompt: true });
        return;
      }

      formik.resetForm({ values: res.data });
    }

    setLoading(false);
  };

  let getEffectiveStatus = item => {
    if(downgraded) return "unpublished";
    if(item.status == "scheduled" && item.startDate && moment(item.startDate).toDate() <= new Date)
      return "published";
    return item.status;
  };

  let onSortEnd = ({ oldIndex, newIndex }) => {
    let newArray = [];

    for(let step of _.cloneDeep(formik.values.steps)) {
      if(step.order == oldIndex)
        step.order = newIndex;
      else if(step.order >= newIndex)
        step.order += 1;
      newArray.push(step);
    }

    newArray
      .sort((a, b) => a.order - b.order)
      .forEach((o, i) => o.order = i);

    formik.setFieldValue("steps", newArray);
  };

  let valuesChanged = () => {
    clearTimeout(previewTimer);
    previewTimer = setTimeout(updatePreview, 800);
  };

  let updatePreview = () => {
    let messages = [];

    let addMessage = (type, text) => {
      messages.push(
        <>
          <div className={"message-" + type + (text ? "" : " empty")}>
            {text && (
              <>
                <em>{type == "in" ? "Customer" : ((user && user.firstName) || "You")}</em>
                {text.split(/\n/g).map((line, i) => <Fragment key={i}>{line}<br /></Fragment>)}
              </>
            )}
          </div>
        </>
      );
    };

    let getProductName = selectedIds =>
      selectedIds && selectedIds.length && products && products.length
        ? products.find(o => o._id == selectedIds[0]).name
        : (
          products && products.length
            ? products[0].name
            : "Show Name"
        );

    addMessage("in", formik.values.code.toUpperCase());

    let first = true;

    for(let step of [...formik.values.steps].sort((a, b) => a.order - b.order)) {
      switch(step.type) {
        case "collect-email":
          if(!step.enabled || !step.text)
            continue;
          addMessage("out", step.text);
          addMessage("in", "test@test.com");
          first = false;
          break;
        case "collect-name":
          if(!step.enabled || !step.text)
            continue;
          addMessage("out", step.text);
          addMessage("in", "John");
          first = false;
          break;
        case "access-sms":
          addMessage("out", (step.text.replace("(show name)", getProductName(formik.values.products)) || (first ? "Hi! " : "") +
            "Tap this link to access " + getProductName(formik.values.products)) +
            (formik.values.accessType != "no-products"
              ? " - " +
              (formik.values.accessType == "custom" && !!formik.values.accessUrl
                ? formik.values.accessUrl
                : "https://go.hiro.fm/abc")
              : ""));
          first = false;
          break;
      }
    }

    setPreviewBody(messages.map((message, index) => <Fragment key={index}>{message}</Fragment>));
  };

  useEffect(() => {
    if(match.params.id != currentIdRef.current)
      loadItem();
    currentIdRef.current = match.params.id;

    if(user && user._id != lastUserId && !match.params.id)
      checkLimits.canAddKeyword(user);
    lastUserId = user ? user._id : null;
  }, [match.params]);

  useEffect(() => {
    valuesChanged();
  }, [formik.values]);

  useEffect(() => {
    if(!user)
      return;

    if(!user.planFeatures.smsAccess)
      NeedsUpgradeEvents.dispatchShowForSms();
    else if(!user.phoneNumbers.length)
      PhoneNumberModalEvents.dispatchShowSmsOrWhatsapp();
  }, [user]);

  return (
    <>
      <EditTagModal autoReload onTagAdded={t => formik.setFieldValue("tags", [...(formik.values.tags || []), t])} onHide={() => setShowCreateTagModal(false)} show={showCreateTagModal} />

      <h1>
        <Link to="/sms" className="btn btn-back">
          <SVG src={toAbsoluteUrl("/media/def-image/icons/back.svg")} />
        </Link>
        {match.params.id ? "Edit" : "New"} Keyword
      </h1>

      {loading
        ? (
          <div className="spinner spinner-full"></div>
        )
        : (
          <div className="form-with-preview">
            <form onSubmit={formik.handleSubmit}>
              <div className="contents">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title m-0">Keyword Details</h3>
                  </div>
                  <div className="card-body">
                    <div className="row mb-9">
                      <div className="col-sm-6 mb-9 mb-sm-0">
                        <div className="form-group">
                          <label className="form-label">Status <span className={"status-dot status-" + getEffectiveStatus(formik.values)}></span></label>
                          <select className="custom-select" {...formik.getFieldProps("status")}>
                            <option value="published">Published</option>
                            <option value="scheduled">Scheduled</option>
                            <option value="unpublished">Unpublished</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">Start Date</label>
                          <Datetime
                            locale="en"
                            className="w-auto"
                            onChange={val => formik.setFieldValue("startDate", val.toISOString())}
                            value={moment(formik.values.startDate)}
                            inputProps={{ disabled: formik.values.status != "scheduled", readOnly: true, className: "form-control input-date " + getInputClasses(formik, "startDate") }} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label">Opt-in Word</label>
                      <input type="text" className={"form-control " + getInputClasses(formik, "code")} {...formik.getFieldProps("code")} />
                      {getInputMessage(formik, "code")}
                    </div>

                    <div className="form-group">
                      <label className="form-label">Choose what to send them</label>

                      <div className="row">
                        <div className="col-md text-nowrap">
                          <label className="form-check">
                            <input className="form-check-input" type="radio" name="accessType" value="add-products" checked={formik.values.accessType == "add-products" || !formik.values.accessType} onChange={ev => formik.setFieldValue("accessType", ev.target.value)} />
                            Show access
                          </label>
                        </div>

                        <div className="col-md text-nowrap">
                          <label className="form-check">
                            <input className="form-check-input" type="radio" name="accessType" value="sales-page" checked={formik.values.accessType == "sales-page"} onChange={ev => formik.setFieldValue("accessType", ev.target.value)} />
                            Show sales page
                          </label>
                        </div>

                        <div className="col-md text-nowrap">
                          <label className="form-check">
                            <input className="form-check-input" type="radio" name="accessType" value="custom" checked={formik.values.accessType == "custom"} onChange={ev => formik.setFieldValue("accessType", ev.target.value)} />
                            Custom URL
                          </label>
                        </div>

                        <div className="col-md text-nowrap">
                          <label className="form-check">
                            <input className="form-check-input" type="radio" name="accessType" value="no-products" checked={formik.values.accessType == "no-products"} onChange={ev => formik.setFieldValue("accessType", ev.target.value)} />
                            Create contact only
                          </label>
                        </div>
                      </div>
                    </div>

                    {(formik.values.accessType == "add-products" || !formik.values.accessType) && (
                      <div className="form-group">
                        <label className="form-label">Select Show</label>
                        <ProductDropdown multiple value={formik.values.products} onChange={selected => formik.setFieldValue("products", selected)} />
                        {getInputMessage(formik, "products")}

                        {Array.isArray(formik.values.products) && formik.values.products.length > 0 && formik.values.products.some(p => !products?.find(q => q._id == p)?.isLive) && (
                          <div className="field-error">
                            {formik.values.products.length == 1
                              ? "The show is draft or not ready to be released. Listeners won‘t get the access SMS unless the show is live."
                              : "Some selected shows are draft or not ready to be released. Listeners won‘t get the access SMS unless the shows are live."}
                          </div>
                        )}
                      </div>
                    )}

                    {formik.values.accessType == "sales-page" && (
                      <div className="form-group">
                        <label>Select Show</label>
                        <ProductDropdown value={formik.values.products} onChange={selected => formik.setFieldValue("products", selected)} />
                        {getInputMessage(formik, "products")}
                      </div>
                    )}

                    {formik.values.accessType == "custom" && (
                      <div className="form-group">
                        <label>URL</label>
                        <input type="text" className={"form-control " + getInputClasses(formik, "accessUrl")} {...formik.getFieldProps("accessUrl")} />
                        {getInputMessage(formik, "accessUrl")}
                      </div>
                    )}

                    <div className="form-group">
                      <label className="form-label">Tag contact</label>

                      <TagDropdown onCreateTagRequest={() => setShowCreateTagModal(true)} multiple value={formik.values.tags} onChange={value => formik.setFieldValue("tags", value)} />
                    </div>
                  </div>
                </div>

                <SortableCardsContainer useDragHandle lockAxis="y" onSortEnd={onSortEnd}>
                  {formik.values.steps.map((step, index) => {
                    let C;
                    switch(step.type) {
                      case "collect-email":
                        C = CollectEmailStep;
                        break;
                      case "collect-name":
                        C = CollectNameStep;
                        break;
                      case "access-sms":
                        C = AccessSMSStep;
                        break;
                    }
                    return <C formik={formik} step={step} id={step.id} index={index} order={step.order} key={step.id} smsCodeId={match.params.id} />;
                  })}
                </SortableCardsContainer>

                <div className="card border-0">
                  <div className="card-body p-0 text-right">
                    <button className={"btn btn-primary " + (saving === true ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save Keyword</button>
                  </div>
                </div>
              </div>
            </form>

            <Preview title="Message Preview" className="chat" scroll>
              <div className="list">
                {previewBody}
              </div>
            </Preview>
          </div>
        )}

      <UnsavedFormGuard formik={formik} onSaveAsync={save} loading={saving == "modal"} />
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(withRouter(SMSCodeEdit))
);
