import React from "react";
import SVG from "react-inlinesvg";
import UpgradeLink from "../../../../generic/UpgradeLink";

export default function StepPromote({ formik, user }) {
    let enabled = !!user.planFeatures.promoteShows;
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title m-0 fill">
                    Promote in Hiro app
                    {!enabled && <UpgradeLink className="ml-3" user={user} />}
                </h3>

                <p className="text-muted">Recommend Marketing Secrets in the Hiro app with my other shows. <a href="#">Learn more</a></p>
            </div>

            <div className="card-body listener-access-email">
                <div className="form-group">
                    <div className="d-flex episode-access-options">
                        <label className={"option " + (formik.values.promote ? "active " : "") + (enabled ? "" : "disabled")}>
                            <input type="radio" name="promote" value={true} onChange={ev => enabled && formik.setFieldValue("promote", ev.target.checked)} />
                            <SVG src="/media/def-image/icons/recommend.svg" />
                            Recommend show
                        </label>

                        <label className={"option " + (!formik.values.promote ? "active " : "") + (enabled ? "" : "disabled")}>
                            <input type="radio" name="promote" value={false} onChange={ev => enabled && formik.setFieldValue("promote", !ev.target.checked)} />
                            <SVG src="/media/def-image/icons/dont-recommend.svg" />
                            Do not recommend show
                        </label>
                    </div>
                </div>

                {formik.values.promote && (
                    <div className="form-group">
                        <label className="checkbox checkbox-lg mb-3">
                            <input type="checkbox" disabled={!enabled} onChange={ev => formik.setFieldValue("promoteRestrictByCategory", ev.target.checked)} checked={formik.values.promoteRestrictByCategory} />
                            <span />
                            Restrict by category
                        </label>

                        <p className="m-0">Only display {formik.values.name || "this show"} with other shows in the same category.</p>
                    </div>
                )}
            </div>
        </div>
    );
}