import { useFetch } from "../../app/helpers";

export default {
  list: (page, search, rowsPerPage) => useFetch.get("/contacts?page=" + (page || "") + "&search=" + encodeURIComponent(search || "") + "&rowsPerPage=" + (rowsPerPage || ""), null, true, true),
  listWithNumber: (page, search, rowsPerPage) => useFetch.get("/contacts?page=" + (page || "") + "&search=" + encodeURIComponent(search || "") + "&withNumber=1&rowsPerPage=" + (rowsPerPage || ""), null, true, true),
  create: (data, { updateDuplicated = false, type = null }) => useFetch.post("/contacts", { data, updateDuplicated, type }, true, true),
  update: (id, data) => useFetch.put("/contacts/" + id, { data }, true, true),
  delete: id => useFetch.delete("/contacts/" + id, null, true, true),
  get: id => useFetch.get("/contacts/" + id, null, true, true),
  createTag: data => useFetch.post("/tags", { data }, true, true),
  updateTag: (id, data) => useFetch.put("/tags/" + id, { data }, true, true),
  deleteTag: id => useFetch.delete("/tags/" + id, null, true, true)
};
