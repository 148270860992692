import React, { useEffect, useRef, useState } from "react";
import { isValidUrl, toAbsoluteUrl } from "../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, useHistory, useLocation, useRouteMatch, withRouter } from "react-router-dom";
import actions from "../../../redux/actions";
import SVG from "react-inlinesvg";
import api from "../../../redux/api";
import moment from "moment";
import { ProductLogo } from "../../../_metronic/layout/components/productLogo";
import ConfirmModal from "../modals/ConfirmModal";
import OptInsModal from "./OptInsModal";
import { getSearchRegExp, toastMessage } from "../../helpers";
import Scrollbars from "react-custom-scrollbars";
import { Dropdown } from "react-bootstrap";
import CustomSelect from "../../layout/components/CustomSelect";
import EmojiToolbox from "../../layout/components/toolbox/EmojiToolbox";
import actionTypes from "../../../redux/action-types";
import { NeedsUpgradeEvents } from "../modals/NeedsUpgrade";
import { PhoneNumberModalEvents } from "../modals/PhoneNumberModal";
import { SegmentedMessage } from "sms-segments-calculator";
import { AddCreditsModalEvents } from "../modals/AddCreditsModal";
import useTimeout from "../../helpers/useTimeout";
import ContactEditForm from "../contacts/ContactEditForm";
import PhoneNumberDropdown from "../../layout/dropdowns/PhoneNumberDropdown";

let debounceTimer,
  searchDebounceTimer,
  scrollToBottom;

const name = (contact, { noEmptyName, showPhone, showEmail, showFrom } = {}) => {
  let res = [];

  if(contact.firstName && contact.lastName && contact.firstName != "Anonymous") {
    res.push(<strong key={0}>{contact.firstName + " " + contact.lastName}</strong>);
  } else if(contact.name && contact.name != "Anonymous") {
    res.push(<strong key={0}>{contact.name}</strong>);
  } else if(noEmptyName) {
    if(contact.phone) {
      showPhone = false;
      res.push(<strong key={0}>{contact.phone}</strong>);
    } else if(contact.from) {
      showFrom = false;
      res.push(<strong key={0}>{contact.from}</strong>);
    } else if(contact.email) {
      showEmail = false;
      res.push(<strong key={0}>{contact.email}</strong>);
    }
  }

  if(showPhone && contact.phone)
    res.push(<span key={1}>{contact.phone}</span>);

  if(showFrom && contact.from)
    res.push(<span key={2}>{contact.from}</span>);

  if(showEmail && contact.email)
    res.push(<span key={3}>{contact.email}</span>);

  if(!res.length && noEmptyName)
    res.push(<strong key={4} className="text-muted">Unknown</strong>);

  return res;
};

function Chat({ user, phoneNumber, contactId, onUpdate, onShowDetails, setUpdateCallback }) {
  const [loading, setLoading] = useState(false),
    [messages, setMessages] = useState([]),
    [contact, setContact] = useState(null),
    [showEmojiPicker, setShowEmojiPicker] = useState(false),
    [sending, setSending] = useState(false),
    [searching, setSearching] = useState(false),
    [searchResults, setSearchResults] = useState(null),
    textareaRef = useRef(),
    contactRef = useRef(),
    scrollRef = useRef(),
    history = useHistory(),
    isMac = window.navigator.platform.match(/mac/i),
    [showDeleteConfirm, setShowDeleteConfirm] = useState(false),
    [cost, setCost] = useState(0),
    [sendEnabled, setSendEnabled] = useState(false),
    [attachment, setAttachment] = useState(),
    [showAttachmentDropdown, setShowAttachmentDropdown] = useState(),
    [attachmentIsValid, setAttachmentIsValid] = useState(true),
    [attachmentValidationLoading, setAttachmentValidationLoading] = useState(false),
    attachmentInputRef = useRef();

  let isWhatsapp = phoneNumber?.kind == "whatsapp";

  let loadData = async (update = false) => {
    if(!contactId || contactId == "new")
      return;

    if(!update) {
      setLoading(true);
      scrollToBottom = true;
    }

    let last = messages.length ? messages.slice(-1)[0] : null;
    if(!last)
      update = false;

    let res = update
      ? await api.inbox.getConversationUpdates(phoneNumber.kind, contactId, last._id)
      : await api.inbox.getConversation(phoneNumber.kind, contactId);

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    if(update)
      setMessages(v => [...v, ...res.messages]);
    else
      setMessages(res.messages);

    setContact(res.contact);
  };

  setUpdateCallback(async () => loadData(true));

  let insert = text => {
    textareaRef.current.value = textareaRef.current.value.substring(0, textareaRef.current.selectionStart)
      + text
      + textareaRef.current.value.substring(textareaRef.current.selectionEnd);
    textareaOnChange();
  };

  let textareaOnInput = ev => {
    if(textareaRef.current)
      textareaRef.current.style.height = Math.max(48, textareaRef.current.scrollHeight) + "px";
  };

  let textareaOnChange = ev => {
    if(textareaRef.current) {
      setCost(!isWhatsapp && !user.twilioConnected && !user.telnyxConnected && textareaRef.current.value && textareaRef.current.value.trim()
        ? new SegmentedMessage(textareaRef.current.value).segmentsCount
        : null);
      setSendEnabled((textareaRef.current.value && textareaRef.current.value.trim()) || attachment);
    }
  };

  let textareaOnKeyDown = ev => {
    if(ev.which == 13 && (ev.ctrlKey || ev.metaKey)) {
      ev.preventDefault();
      send();
    }
  };

  let contactOnKeyDown = ev => {
    if(ev.which == 27) {
      setSearching(false);
      setSearchResults(null);
    }

    if(ev.which == 13) {
      ev.preventDefault();

      if(searchResults && searchResults.length)
        history.push("/inbox/" + searchResults[0]._id);
    }
  };

  let contactOnInput = ev => {
    clearTimeout(searchDebounceTimer);

    //TODO abort previous search

    let text = ev.target.value.trim();

    if(!text) {
      setSearching(false);
      setSearchResults(null);
      return;
    }

    searchDebounceTimer = setTimeout(async () => {
      setSearching(true);

      let res = await api.contacts.listWithNumber(1, text);

      setSearching(false);

      if(!res || !res.success)
        return toastMessage.error((res && res.error) || "Unable to connect to the server.");

      setSearchResults(res.data);
    }, 500);
  };

  let send = async () => {
    if(!user || sending || !contactId || contactId == "new")
      return;

    // if(!user.planFeatures.inbox)
    //   return NeedsUpgradeEvents.dispatchShowForSms("Upgrade to send messages");

    if(!user.phoneNumbers.length)
      return PhoneNumberModalEvents.dispatchShow();

    if(user.phoneNumberOwner == "hiro" && cost > user.credits)
      return AddCreditsModalEvents.dispatchShow("Add credits to send messages");

    let text = textareaRef.current.value.trim();
    if(!text && !attachment)
      return textareaRef.current.focus();

    setSending(true);

    let res = await api.inbox.post(phoneNumber.kind, contactId, { text, attachment });

    if(!res || !res.success) {
      setSending(false);
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");
    }

    onUpdate();
    await loadData(true, true);

    textareaRef.current.value = "";
    textareaRef.current.focus();
    setCost(null);
    setSendEnabled(false);
    setAttachment(null);

    setSending(false);
  };

  let onScroll = ev => {
    let view = scrollRef.current.view,
      maxScroll = Math.max(0, view.scrollHeight - view.parentElement.scrollHeight);
    scrollToBottom = view.scrollTop - maxScroll >= 15;
  };

  let deleteOnClick = ev => {
    ev.preventDefault();
    setShowDeleteConfirm(true);
  };

  let deleteOnConfirm = async () => {
    setLoading(true);
    await api.inbox.delete(contactId);
    await onUpdate();
    history.push("/inbox");
  };

  function detailsOnClick(ev) {
    ev.preventDefault();
    onShowDetails();
  }

  function attachmentInputOnKeyDown(ev) {
    if(ev.which == 13) {
      ev.preventDefault();
      attachmentOkOnClick();
    }
  }

  async function attachmentOkOnClick() {
    setAttachmentValidationLoading(true);
    setSendEnabled(textareaRef.current.value && textareaRef.current.value.trim());

    let url = attachmentInputRef.current.value;

    let res = await api.generic.validateAttachment(url);

    setAttachmentValidationLoading(false);

    if(!res)
      return toastMessage.error("Unable to connect to the server.");

    if(!res.success)
      return toastMessage.error(res.error || "Unable to validate the image.");

    if(res.size > 600)
      return toastMessage.error("The image is too large. Please use an image smaller than 600KB.");

    setAttachment(url);
    setShowAttachmentDropdown(false);
    setSendEnabled(true);
  }

  function removeAttachmentOnClick() {
    setSendEnabled(textareaRef.current.value && textareaRef.current.value.trim());
    setAttachment(null);
  }

  function validateAttachment() {
    setAttachmentIsValid(!attachmentInputRef.current.value || isValidUrl(attachmentInputRef.current.value));
  }

  useEffect(() => {
    if(contactId && contactId != "new")
      loadData();
    setAttachment(null);
  }, [contactId]);

  useTimeout(() => loadData(true), 30000, true);

  useEffect(() => {
    if(contactRef.current)
      contactRef.current.focus();

    else if(textareaRef.current)
      textareaRef.current.focus();

    textareaOnInput();
  }, [textareaRef.current, contactRef.current]);

  useEffect(() => {
    if(!scrollRef.current || !scrollToBottom)
      return;

    let view = scrollRef.current.view;

    view.scrollTo({ top: view.scrollHeight, behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    const h = ev => {
      setTimeout(() => {
        setSearching(false);
        setSearchResults(null);

        //console.log(ev);
      }, 300);
    };
    document.addEventListener("mouseup", h, { capture: true });
    return () => document.removeEventListener("mouseup", h, { capture: true });
  }, []);

  useEffect(() => {
    if(showAttachmentDropdown)
      attachmentInputRef.current.focus();
  }, [showAttachmentDropdown]);

  return loading
    ? <div className="loading loading-block spinner" />
    : (
      <>
        <div className="chat-header">
          {contactId && contactId != "new" && contact
            ? (
              <>
                <a href="#" className="fill details" onClick={detailsOnClick}>
                  {name(contact, { showPhone: true, noEmptyName: true })}
                </a>
                <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                  <Dropdown.Toggle>
                    <SVG src="/media/def-image/icons/menu-2.svg" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                    <Link to={"/contacts/" + contact._id + "/edit"} className="dropdown-item">
                      <div className="icon">
                        <SVG src="/media/def-image/icons/listeners.svg" />
                      </div>
                      Open in Contacts
                    </Link>
                    <Link to={"/inbox"} className="dropdown-item hidden-lg">
                      <div className="icon">
                        <SVG src="/media/def-image/back.svg" />
                      </div>
                      Close
                    </Link>
                    <a href="#" onClick={deleteOnClick} className="dropdown-item">
                      <div className="icon">
                        <SVG src="/media/def-image/icons/delete.svg" />
                      </div>
                      Delete
                    </a>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )
            : (
              <form className="search-contact">
                <label>To:</label>
                <div className={"search-field-container " + (searching ? "loading spinner spinner-sm" : "")}>
                  <input type="text" className="form-control" ref={contactRef} onInput={contactOnInput} onKeyDown={contactOnKeyDown} placeholder="Search contact..." />
                  {!searching && searchResults && (
                    <div className="results">
                      {searchResults.length
                        ? searchResults.map((item, i) => (
                          <Link to={"/inbox/" + item._id} key={i}>
                            {name(item, { showPhone: true, showEmail: true })}
                          </Link>
                        ))
                        : <span className="text-muted">No results.</span>}
                    </div>
                  )}
                </div>
              </form>
            )}
        </div>

        <Scrollbars
          ref={scrollRef}
          onScroll={onScroll}
          hideTracksWhenNotNeeded
          className="scrollbar-view"
          renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-track" />}
          renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-thumb" />}>
          <div className="conversation">
            {messages.map((message, i) => (
              <div key={i} className={"message message-" + message.from}>
                <div>
                  {!!message.attachment && (
                    <div className="attachment" style={{ backgroundImage: "url(" + message.attachment + ")" }} />
                  )}
                  <div className="meta">
                    <label>
                      {message.from == "user"
                        ? (contact && (contact.firstName || contact.name)) || "User"
                        : "You"}
                    </label>
                    <span className="date">{moment(message.date).locale("en").format("MMM D, h:mm a")}</span>
                  </div>
                  <div className="text">{message.text}</div>
                </div>
              </div>
            ))}
          </div>
        </Scrollbars>

        <form className={"chat-footer " + (isWhatsapp ? "for-whatsapp " : "for-sms ") + (!contactId || contactId == "new" ? "disabled" : "")}>
          {isWhatsapp && (
            <div className="alert">
              <SVG src="/media/def-image/icons/info-base.svg" />
              You can only send messages to contacts who have written to you in the last 24 hours.
            </div>
          )}

          <div className="textarea-col">
            {!!attachment && (
              <div className="attachment-preview">
                <button type="button" className="btn btn-remove" onClick={removeAttachmentOnClick}>
                  <SVG src="/media/def-image/icons/delete.svg" />
                </button>
                <div className="spinner spinner-sm" />
                <div className="image" style={{ backgroundImage: "url(" + attachment + ")" }} />
              </div>
            )}

            <textarea className="form-control message-field" placeholder="Type a message" ref={textareaRef} onInput={textareaOnInput} onChange={textareaOnChange} onKeyDown={textareaOnKeyDown} />
            <div>
              <button type="button" className="btn toolbox-btn" onClick={() => setShowEmojiPicker(v => !v)}>
                <SVG src="/media/def-image/icons/emoji.svg" />
              </button>
              {!isWhatsapp && (
                <button type="button" className="btn toolbox-btn" onClick={() => setShowAttachmentDropdown(true)}>
                  <SVG src="/media/def-image/icons/paperclip.svg" />
                </button>
              )}
              {showAttachmentDropdown && (
                <div className="attachment-dropdown">
                  <button type="button" className="btn close-btn" onClick={() => setShowAttachmentDropdown(false)}>
                    <SVG src="/media/def-image/close.svg" />
                  </button>
                  <label className="form-label">
                    Image or Giphy<br />
                    <span className="help-text">Paste the URL below.</span>
                  </label>
                  <div class="d-flex">
                    <input type="text" className={"form-control " + (attachmentIsValid ? "" : "is-invalid")} defaultValue={attachment} ref={attachmentInputRef} onInput={validateAttachment} onKeyDown={attachmentInputOnKeyDown} />
                    <button type="button" className={"btn btn-primary ml-3 " + (attachmentValidationLoading ? "loading spinner" : "")} disabled={!attachmentIsValid} onClick={attachmentOkOnClick}>Set</button>
                  </div>
                </div>
              )}
              <EmojiToolbox show={showEmojiPicker} onHide={() => setShowEmojiPicker(false)} onData={insert} />
              {!!cost && <small className={"cost " + (cost > user.credits ? "text-danger " : "")}>{cost} credits</small>}
            </div>
          </div>
          <div className="btn-col">
            <button disabled={!sendEnabled} type="button" className={"btn btn-primary " + (sending ? "loading spinner spinner-sm" : "")} onClick={() => send()}>Send</button>
            {/* <small className="text-muted">{isMac ? "⌘" : "Ctrl"}+intro to send</small> */}
          </div>
        </form>

        <ConfirmModal
          show={showDeleteConfirm}
          message="Are you sure you want to close this conversation?"
          onConfirm={deleteOnConfirm}
          onCancel={e => setShowDeleteConfirm(false)} />
      </>);
}

function Inbox({ user, products, dispatch, fulfillUser, phoneNumber }) {
  let [loading, setLoading] = useState(),
    contactIdMatch = useRouteMatch("/inbox/:contactId"),
    [list, setList] = useState([]),
    [filter, setFilter] = useState({ page: 1, x: 1 }),
    [hasMore, setHasMore] = useState(false),
    [showContactForm, setShowContactForm] = useState(false),
    updateChatCallbackRef = useRef(),
    history = useHistory();

  let updateUser = async () => {
    let res = await api.auth.getUserByToken(true);
    if(res)
      dispatch(fulfillUser(res.data));
  };

  let loadList = async (update = false) => {
    if(!phoneNumber)
      return;

    if(!update)
      setLoading(filter.page ? "more" : true);

    let res = update
      ? await api.inbox.updateList(phoneNumber.kind, filter.page, filter.search)
      : await api.inbox.list(phoneNumber.kind, filter.page, filter.search);

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    if(filter.page == 1 || update)
      setList(res.data);
    else
      setList(d => [...d, ...res.data]);

    setHasMore(res.pages > filter.page);
  };

  let update = () => {
    updateUser();
    loadList(true);
  };

  let handleSearch = async ev => {
    let search = ev.target.value;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() =>
      setFilter({ page: 1, search }), 800);
  };

  let loadMoreOnClick = () =>
    setFilter(f => ({ ...f, page: f.page + 1 }));

  function closeContactForm(update = false) {
    setShowContactForm(false);
    if(update) {
      loadList();
      if(updateChatCallbackRef.current)
        updateChatCallbackRef.current();
    }
  }

  useTimeout(() => loadList(true), 30000, true);

  useEffect(() => {
    loadList();
  }, [filter]);

  useEffect(() => {
    history.push("/inbox/new");
    loadList();
  }, [phoneNumber]);

  useEffect(() => {
    if(!user)
      return;

    if(!user.phoneNumbers.length)
      PhoneNumberModalEvents.dispatchShowSmsOrWhatsapp();
  }, [user]);

  return (
    <>
      <h1>
        Inbox
        <PhoneNumberDropdown className="mx-10" />
        <Link as="button" to="/inbox/new" className="btn btn-plus inline" />
      </h1>

      <div className={"row inbox-page " + (contactIdMatch ? "has-chat " : "") + (showContactForm ? "contact-form-visible " : "")}>
        <div className="col-5 col-xl-4 list-col">
          <div className="card">
            <div className="card-body">
              <input type="text" className="form-control search" placeholder="Search name or number" onInput={handleSearch} />

              {loading === true
                ? <div className="loading loading-block spinner" />
                : <Scrollbars
                  hideTracksWhenNotNeeded
                  className="scrollbar-view disable-mobile"
                  renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-track" />}
                  renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-thumb" />}>
                  <div className="conversations">
                    {list.map((item, i) => (
                      <Link key={i} to={"/inbox/" + item._id} className={"conversation " + (item.seen ? "" : "new ") + (contactIdMatch && item._id == contactIdMatch.params.contactId ? "active " : "")}>
                        <div className="title">
                          {name(item, { noEmptyName: true })}
                          <span className="date">{moment(item.updatedAt || item.createdAt).locale("en").fromNow()}</span>
                        </div>
                        <span className="last-message">{item.lastMessage}</span>
                      </Link>
                    ))}

                    {list.length ? <></> : <div className="text-muted text-center p-6">No conversations found.</div>}

                    {hasMore && <button className={"btn btn-sm btn-secondary btn-load-more " + (loading == "more" ? "loading spinner spinner-dark spinner-sm " : "")} onClick={loadMoreOnClick}>Load more...</button>}
                  </div>
                </Scrollbars>}
            </div>
          </div>
        </div>
        <div className="col-7 col-xl-8 chat-col">
          <div className="card">
            <div className="card-body">
              {contactIdMatch
                ? <Chat phoneNumber={phoneNumber} user={user} setUpdateCallback={fn => (updateChatCallbackRef.current = fn)} key={contactIdMatch ? contactIdMatch.params.contactId : 0} contactId={contactIdMatch && contactIdMatch.params.contactId != "new" && contactIdMatch.params.contactId} onUpdate={update} onShowDetails={() => setShowContactForm(true)} />
                : (
                  <div className="empty-chat-panel">
                    <p>Select a conversation or <Link className="btn btn-primary btn-sm btn-inline ml-2" to="/inbox/new">Start a new chat</Link></p>
                  </div>
                )}
            </div>

            {showContactForm && (
              <div className="contact-form side-form">
                <ContactEditForm user={user} onCancel={closeContactForm} onSuccess={() => closeContactForm(true)} showMeta id={contactIdMatch && contactIdMatch.params.contactId != "new" && contactIdMatch.params.contactId} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
      phoneNumber: state.phoneNumber.current
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      ...actions.phoneNumber,
      dispatch
    })
  )(withRouter(Inbox))
);
