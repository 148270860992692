import React from "react";
import { checkLimits } from "../../plans";
import SVG from "react-inlinesvg";

export default function UpgradeLink({ flat, className, user, message, label }) {
    return (
        <a href="#" className={"upgrade-link " + (flat ? "" : "badge ") + (className || "")} onClick={ev => {
            ev.preventDefault();
            checkLimits.upgradeOrActivate(user, null, message);
        }}>
            <SVG src="/media/def-image/icons/crown.svg" />
            {label || "Upgrade to unlock"}
        </a>
    );
}