export default {
    translation: {
        "Coupon Code (optional)": "Kod kuponu (opcjonalny)",
        "/ wk": "/ tyg",
        "/ mo": "/ mies",
        "/ yr": "/ rok",
        "Click for Access": "Kliknij, aby uzyskać dostęp",
        "Buy Now": "Kup teraz",
        "Select plan": "Wybierz plan",
        "{{episodes}} episodes - free": "{{episodes}} odcinki - za darmo",
        "{{episodes}} episodes - {{displayPrice}}": "{{episodes}} odcinki - {{displayPrice}}",
        "All": "Wszystko",
        "Audio Feeds": "Kanały audio",
        "Category": "Kategoria",
        "There are no shows in this category.": "Nie ma programów w tej kategorii.",
        "The creator doesn’t have any public audio feeds yet.": "Twórca nie ma jeszcze żadnych publicznych kanałów audio.",
        "About me": "O mnie",
        "My audio feeds": "Moje kanały audio",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "Zgadzam się z regulaminem & polityką prywatności Hiro.",
        "Powered by Hiro.fm": "Napędzane przez Hiro.fm",
        "Start your own private audio product": "Rozpocznij swój prywatny produkt audio",
        "episodes": "odcinki",
        "Free": "Darmowe",
        "/ week": "/ tydzień",
        "/ month": "/ miesiąc",
        "/ year": "/ rok",
        "one-time payment": "płatność jednorazowa",
        "per week": "na tydzień",
        "per month": "na miesiąc",
        "per year": "na rok",
        "Products": "Produkty",
        "Episodes": "Odcinki",
        "Listeners": "Słuchacze",
        "Downloads": "Pobrania",
        "My activity": "Moja aktywność",
        "Created by:": "Stworzone przez:",
        "Last update:": "Ostatnia aktualizacja:",
        "This audio feed doesn’t have any eposides yet.": "Ten kanał audio nie ma jeszcze żadnych odcinków.",
        "See more": "Zobacz więcej",
        "Please verify the credit card details.": "Proszę zweryfikować dane karty kredytowej.",
        "Payment": "Płatność",
        "Your details": "Twoje dane",
        "First Name": "Imię",
        "Last Name": "Nazwisko",
        "Email": "Email",
        "Phone (optional)": "Telefon (opcjonalnie)",
        "Invalid phone number.": "Nieprawidłowy numer telefonu.",
        "Payment details": "Szczegóły płatności",
        "Total payment": "Całkowita kwota płatności",
        "Get Access": "Uzyskaj dostęp",
        "Pay": "Zapłać",
        "Category:": "Kategoria:",
        "Get Free Access": "Uzyskaj darmowy dostęp",
        "Show currently unavailable": "Pokaż aktualnie niedostępne",
        "Buy now for {{priceValue}}": "Kup teraz za {{priceValue}}",
        "Length": "Długość",
        "reviews": "recenzje",
        "About": "O",
        "Testimonials": "Opinie",
        "My other private audio feeds": "Moje inne prywatne kanały audio",
        "Thank you!": "Dziękuję!",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "Pomyślnie zakupiłeś {{productName}} od {{author}}. Wysłaliśmy Ci email na {{installEmail}} z instrukcjami dostępu.",
        "Point your phone’s camera at the code below to access the private podcast.": "Skieruj aparat swojego telefonu na poniższy kod, aby uzyskać dostęp do prywatnego podcastu.",
        "Purchased item": "Zakupiony przedmiot",
        "Access Private Feed Now": "Uzyskaj teraz dostęp do prywatnego kanału",
        "Ohh no, something went wrong with your payment": "Ups, coś poszło nie tak z twoją płatnością",
        "Check your credit card details and try one more time.": "Sprawdź dane swojej karty kredytowej i spróbuj jeszcze raz.",
        "Try one more time": "Spróbuj jeszcze raz",
        "Cancel": "Anuluj",
        "Enter your first name.": "Wprowadź swoje imię.",
        "Enter your last name.": "Wprowadź swoje nazwisko.",
        "Enter your email address.": "Wprowadź swój adres email.",
        "Invalid email address.": "Nieprawidłowy adres email.",
        "Product not found.": "Produkt nie został znaleziony.",
        "Payment failed.": "Płatność nie powiodła się.",
        "Fill all the fields.": "Wypełnij wszystkie pola.",
        "The email address is invalid.": "Adres email jest nieprawidłowy.",
        "Invalud product ID.": "Błędny ID produktu.",
        "Access denied to product.": "Odmowa dostępu do produktu.",
        "Show not available.": "Program niedostępny.",
        "Terms of service": "Warunki usługi",
        "Privacy policy": "Polityka prywatności"
    }
};