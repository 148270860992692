import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, connect } from "react-redux";
import actions from "../../../../redux/actions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import ConfirmModal from "../../modals/ConfirmModal";
import api from "../../../../redux/api";
import { useDispatch } from 'react-redux';
import ProfileNavigation from "../../../layout/navigation/ProfileNavigation";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { getInputClassName, toastMessage } from "../../../helpers";
import CopyButton from "../../../layout/components/CopyButton";
import { NeedsUpgradeEvents } from "../../modals/NeedsUpgrade";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";

let shouldUpdateUser = true;

function Integrations() {
  const user = useSelector((state) => state.auth.user, shallowEqual),
    [stripeDisconnectConfirm, setStripeDisconnectConfirm] = useState(false),
    getParams = new URLSearchParams(window.location.search),
    [success, setSuccess] = useState(false),
    [error, setError] = useState(false),
    [message, setMessage] = useState(null),
    dispatch = useDispatch();

  function stripeConnectExisting(e) {
    window.location.href = "https://connect.stripe.com/oauth/authorize?"
      + "redirect_uri=" + encodeURIComponent(process.env.REACT_APP_SERVER_URL + "api/stripe/connect-account")
      + "&client_id=" + process.env.REACT_APP_STRIPE_ID
      + "&response_type=code"
      + "&scope=read_write"
      + "&state=" + user._id;
  }

  function stripeConnectNew(e) {
    window.location.href = "https://connect.stripe.com/express/oauth/authorize?"
      + "redirect_uri=" + encodeURIComponent(process.env.REACT_APP_SERVER_URL + "api/stripe/connect-account")
      + "&client_id=" + process.env.REACT_APP_STRIPE_ID
      + "&state=" + user._id;
  }

  async function updateUser() {
    const res = await api.auth.getUserByToken(true);
    dispatch(actions.auth.fulfillUser(res.data));
  }

  function stripeDisconnect(e) {
    setStripeDisconnectConfirm(false);

    api.user.disconnectStripe()
      .then(res => {
        window.location.search = "?success=2";
      });
  };

  useEffect(() => {
    let success = getParams.get("success"),
      error = getParams.get("error");

    if(success) {
      setSuccess(true);
      setMessage({
        1: "Account successfully linked.",
        2: "Your Stripe account was disconnected."
      }[success]);
      if(shouldUpdateUser) updateUser();
      shouldUpdateUser = false;
    } else if(error) {
      setError(true);
      setMessage(error);
    }
  }, []);

  return (
    <>
      <ProfileNavigation active="integrations" />

      <OpenAi user={user} updateUser={updateUser} />

      {success || error
        ? (
          <div className={"alert mb-10 " + (success ? "alert-success" : "alert-warning") + " d-flex align-items-center flex-column flex-sm-row m-0"}>
            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/" + (success ? "Done-circle" : "Warning-2") + ".svg")} className="svg-icon" />
            <div>
              <h4 className="mb-0">{message}</h4>
            </div>
          </div>
        )
        : <></>}

      <div className="card card-profile-header mb-10">
        <div className="card-header">
          <div>
            <h3 className="card-title fw-bold m-0">Accept payments</h3>
            <p className="text-muted">Connect your Stripe account to recieve payments from your sales page.</p>
          </div>
        </div>
        <div className="card-body pt-9 pb-10">
          <div className="d-flex align-items-center">
            <div className="pr-5">
              <img src={toAbsoluteUrl("/media/def-image/stripe.png")} />
            </div>
            <div className="flex-grow-1">
              <strong className="fs-5">Stripe</strong>
              {user.stripeConnected ? "" : <>
                <br />
                <span className="fs-7">Need a Stripe account? <a href="#" onClick={stripeConnectNew}>Sign Up!</a></span>
              </>}
            </div>
            <div>
              {user.stripeConnected
                ? <button className="btn btn-secondary" onClick={e => setStripeDisconnectConfirm(true)}>Disconnect</button>
                : <button className="btn btn-secondary" onClick={stripeConnectExisting}>Connect</button>}
            </div>
          </div>
        </div>
      </div>

      <h2>Zapier, Pabbly, HighLevel and Hiro‘s API</h2>

      <div className="row connect-apps">
        <div className="col-md-6 col-lg-3 mb-10">
          <div className="card">
            <div className="image blue">
              <SVG src={toAbsoluteUrl("/media/def-image/customer-access.svg")} />
            </div>
            <h3>Give customers access</h3>
            <p>Automatically add listeners and give access to your show when they purchase or sign up on other platforms.</p>
            <a href="https://hiro.fm/kb/how-to-give-listeners-access-to-your-show/" target="_blank">Show me how</a>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 mb-10">
          <div className="card">
            <div className="image blue">
              <SVG src={toAbsoluteUrl("/media/def-image/send-text.svg")} />
            </div>
            <h3>Send listener email or text</h3>
            <p>Send emails, texts, add them to a list or other platform as soon as they get access to a show.</p>
            <a href="https://hiro.fm/kb/how-to-trigger-an-email-and-other-stuff/" target="_blank">Show me how</a>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 mb-10">
          <div className="card">
            <div className="image cyan">
              <SVG src={toAbsoluteUrl("/media/def-image/add-episodes.svg")} />
            </div>
            <h3>Add episodes</h3>
            <p>Automatically add episodes to your show when you upload videos or audios to a Google Drive or Dropbox.</p>
            <a href="https://hiro.fm/kb/how-to-automatically-add-episodes/" target="_blank">Show me how</a>
          </div>
        </div>

        <div className="col-md-6 col-lg-3">
          <div className="card">
            <div className="image cyan">
              <SVG src={toAbsoluteUrl("/media/def-image/remove-access.svg")} />
            </div>
            <h3>Remove listener access</h3>
            <p>Automatically remove access to your show when subscriptions payment fails from another platform.</p>
            <a href="https://hiro.fm/kb/how-to-automatically-manage-show-access/" target="_blank">Show me how</a>
          </div>
        </div>
      </div>

      <ConfirmModal
        show={stripeDisconnectConfirm}
        message="Are you sure you want to disconnect your account?"
        onConfirm={stripeDisconnect}
        onCancel={e => setStripeDisconnectConfirm(false)} />

    </>
  );
}

function OpenAi({ user, updateUser }) {
  const [saving, setSaving] = useState(),
    [check1checked, setCheck1checked] = useState(),
    [check2checked, setCheck2checked] = useState(),
    formik = useFormik({
      onSubmit: value => save(value),
      validationSchema: Yup.object().shape({
        openAiKey: Yup.string().notRequired()
      }),
      initialValues: {
        openAiKey: user.openAiKey
          ? user.openAiKey.substr(0, 3) + "..." + user.openAiKey.substr(user.openAiKey.length - 4)
          : ""
      },
      enableReinitialize: true
    });

  const { handleSubmit, errors, touched, getFieldProps, isValid, dirty, setValues, setTouched, resetForm, values } = formik;

  async function save(values, loading) {
    setSaving(loading || true);

    let res = await api.user.editCurrentUser({
      action: "openai-integration",
      user: values
    }, true);

    if(!res || !res.success) {
      setSaving(false);
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");
    }

    toastMessage.success("Details saved.");

    await updateUser();

    setSaving(false);
  }

  useEffect(() => {
    if(user.openAiKey) {
      setCheck1checked(true);
      setCheck2checked(true);
    }
  }, [user]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="card card-profile-header mb-10">
          <div className="card-header d-lg-flex">
            <div className="mb-0">
              <h3 className="card-title fw-bold mb-2">
                Unlock AI Bot
                <a href="https://hiro.fm/kb/integrations-how-to-connect-chatgpt-with-openai-key/" target="_blank" className="get-help-link">Get Help</a>
              </h3>
              <p className="text-muted m-0">Connect your OpenAI account to use AI features. Get your OpenAI key here: <a href="https://platform.openai.com/api-keys" target="_blank" className="fw-normal underline">OpenAI API keys page</a>.</p>
            </div>
          </div>
          <div className="card-body pt-9 pb-10">
            <div className="form-group">
              <label className="form-label mb-7">OpenAI Key:</label>

              <label className="checkbox checkbox-lg mb-7">
                <input type="checkbox" onChange={ev => setCheck1checked(ev.target.checked)} checked={check1checked} />
                <span />
                I confirm that I have an active paid OpenAI account.
              </label>

              <label className="checkbox checkbox-lg mb-7">
                <input type="checkbox" onChange={ev => setCheck2checked(ev.target.checked)} checked={check2checked} />
                <span />
                <div>
                  I confirm that my OpenAI account has a credit balance. You can <a href="https://platform.openai.com/settings/organization/billing/overview" target="_blank" className="fw-normal underline">verify your balance here</a>.
                </div>
              </label>

              <div className="d-flex gap flex-column flex-md-row">
                <input type="text" {...getFieldProps("openAiKey")} className={getInputClassName(formik, "openAiKey")} onInput={() => (setCheck1checked(false), setCheck2checked(false))} />

                <button className={"btn btn-primary " + (saving === true ? "loading spinner " : "")} type="submit" disabled={!check1checked || !check2checked || !isValid || !dirty}>Save</button>
              </div>
              {touched.openAiKey && errors.openAiKey && <div className="field-error">{errors.openAiKey}</div>}
            </div>
          </div>
        </div>
      </form>

      <UnsavedFormGuard formik={formik} onSaveAsync={async () => save(values, "modal")} loading={saving == "modal"} />
    </>
  );
}

export default connect(null, actions.auth)(Integrations);