import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import utils from "../utils";
import TagDropdown from "../../../layout/components/TagDropdown";
import { EditTagModal } from "../../contacts/Tags";

function TagContact({ automationId, submit, name, formik, saving, trigger, triggerIndex, actionIndex, remove, setPreviewBody, setPreviewSettings }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name),
    [showCreateTagModal, setShowCreateTagModal] = useState(false);

  useEffect(() => {
    set("changed", true);
  }, []);

  return (
    <>
      <div className="form-group">
        <label className="form-label">Add Tags to Contact</label>
        <TagDropdown onCreateTagRequest={() => setShowCreateTagModal(true)} multiple value={get("tags")} onChange={value => set("tags", value)} />

        {error("tags") && touched("tags") && <div className="field-error">{error("tags")}</div>}
      </div>

      <EditTagModal autoReload onTagAdded={t => set("tags", [...(get("tags") || []), t])} onHide={() => setShowCreateTagModal(false)} show={showCreateTagModal} />
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(TagContact)
);