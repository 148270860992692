import React from "react";

export default function OptOutSuccess() {
  return (
    <div className="min-vh-100 p-15 text-center d-flex align-items-center justify-content-center">
      <div>
        <h2 className="mb-6">Success</h2>
        <p>You You will no longer receive email notifications.</p>
      </div>
    </div>
  );
}