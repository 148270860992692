import React, { useEffect, useRef, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import actions from "../../../redux/actions";
import SVG from "react-inlinesvg";
import api from "../../../redux/api";
import moment from "moment";
import { ProductLogo } from "../../../_metronic/layout/components/productLogo";
import ConfirmModal from "../modals/ConfirmModal";
import { Dropdown } from "react-bootstrap";
import { getSearchRegExp, toastMessage } from "../../helpers";
import { checkLimits } from "../../plans";
import Lottie from "react-lottie";
import animationData from "../../layout/lottie/empty-automations.json";

function Automations({ user, products, dispatch, fulfillUser }) {
  let [loading, setLoading] = useState(true),
    [actionLoading, setActionLoading] = useState(false),
    [renameId, setRenameId] = useState(null),
    [list, setList] = useState(null),
    [selected, setSelected] = useState([]),
    [showConfirmDelete, setShowConfirmDelete] = useState(false),
    history = useHistory(),
    renameRef = useRef(),
    [search, setSearch] = useState(null);

  let loadList = async () => {
    setLoading(true);
    let res = await api.automations.list();
    if(!res || !res.success)
      return;
    setList(res.data);
    setLoading(false);
  };

  let countOnClick = (ev, item) => {
    ev.preventDefault();
  };

  let selectedOnChange = ev => {
    let items = selected.filter(i => i != ev.target.value);
    if(ev.target.checked)
      items.push(ev.target.value);
    setSelected(items);
  };

  let updateUser = async () => {
    let res = await api.auth.getUserByToken(true);
    if(res)
      dispatch(fulfillUser(res.data));
  };

  let deleteClick = ev => {
    setShowConfirmDelete(true);
  };

  let confirmDelete = async () => {
    setActionLoading("delete");
    setShowConfirmDelete(false);

    for(let item of selected)
      await api.automations.delete(item);

    setActionLoading(null);
    loadList();
    updateUser();
  };

  let handleSearch = async ev => {
    setSearch(ev.target.value);
  };

  let filter = (list, search) => {
    if(!search || !search.trim())
      return list;

    let r = getSearchRegExp(search);

    return list.filter(item => r.test(item.name) || !!item.products.find(p => r.test(p.name)));
  };

  let editItem = (ev, item) => {
    ev.preventDefault();
    history.push("/automations/" + item._id + "/edit");
  };

  let renameItem = (ev, item) => {
    ev.preventDefault();
    setRenameId(item._id);
    setTimeout(() => {
      if(renameRef.current) {
        renameRef.current.focus();
        renameRef.current.select();
      }
    }, 100);
  };

  let duplicateItem = async (ev, item) => {
    ev.preventDefault();
    setActionLoading("menu_" + item._id);

    let res = await api.automations.duplicate(item._id);

    setActionLoading(null);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Automation duplicated!");
    loadList();
    updateUser();
  };

  let togglePauseItem = async (ev, item) => {
    ev.preventDefault();
    setActionLoading("menu_" + item._id);

    let res = await api.automations.toggleActive(item._id, item.active ? 0 : 1);

    setActionLoading(null);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Automation updated!");
    loadList();
  };

  let deleteItem = async (ev, item) => {
    ev.preventDefault();
    setActionLoading("menu_" + item._id);

    let res = await api.automations.delete(item._id);

    setActionLoading(null);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Automation deleted!");
    loadList();
    updateUser();
  };

  let renameOnKeyDown = ev => {
    if(ev.which == 13) {
      ev.preventDefault();
      saveRename();
    }

    if(ev.which == 27) {
      ev.preventDefault();
      setRenameId(null);
    }
  };

  let renameOnClick = ev =>
    saveRename();

  let saveRename = async () => {
    setActionLoading("rename");

    let res = await api.automations.update(renameId, { name: renameRef.current.value });

    if(!res || !res.success)
      toastMessage.error((res && res.error) || "Unable to connect to the server.");
    else
      toastMessage.success("Automation updated!");

    setActionLoading(false);
    setRenameId(null);
    loadList();
  };

  async function createOnClick(ev) {
    ev.preventDefault();

    if(await checkLimits.canAddAutomation(user))
      history.push("/automations/edit");
  }

  useEffect(() => {
    updateUser();
    loadList();
  }, []);

  return (
    <>
      <h1>
        Automations
        <Link as="button" to="#" onClick={createOnClick} className="btn btn-plus inline" />
      </h1>

      {loading && !list
        ? (
          <div className="spinner spinner-full"></div>
        )
        : list && list.length
          ? (
            <div className={"card p-10 manage-automations-page " + (loading ? "loading spinner" : "")}>
              <div className="card-toolbar">
                <input type="text" className="form-control search" placeholder="Search Automations" onChange={handleSearch} />
                <div className="d-flex fill justify-content-end">
                  <Link to="#" onClick={createOnClick} className="btn btn-primary font-weight-bolder font-size-sm mr-3">+ New Automation</Link>
                  <button type="button" className={"btn btn-danger font-weight-bolder font-size-sm " + (actionLoading == "delete" ? "loading spinner" : "")} disabled={!selected.length} onClick={deleteClick}>Delete</button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center">
                  <thead>
                    <tr>
                      <th width="10"></th>
                      <th>Name</th>
                      <th>Show</th>
                      <th>Started</th>
                      <th>In Queue</th>
                      <th>Completed</th>
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filter(list, search).map((item, i) => (
                      <tr key={i}>
                        <td>
                          <label className="checkbox checkbox-lg checkbox-single">
                            <input type="checkbox" onChange={selectedOnChange} value={item._id} checked={selected.includes(item._id)} />
                            <span />
                          </label>
                        </td>
                        <td>
                          {renameId == item._id
                            ? (
                              <div className="d-flex">
                                <input type="text" className="form-control form-control-sm fill" autoFocus onKeyDown={renameOnKeyDown} defaultValue={item.name} ref={renameRef} />
                                <button type="button" className={"btn btn-primary btn-sm ml-2 " + (actionLoading == "rename" ? "loading spinner spinner-sm" : "")} onClick={renameOnClick}>Save</button>
                              </div>
                            )
                            : (
                              <>
                                <span className={"status-dot left " + (item.active ? "status-published" : "status-unpublished")} />
                                <Link to={"automations/" + item._id + "/edit"} className="text-inherit">
                                  {item.name}
                                </Link>
                              </>
                            )}
                        </td>
                        <td>
                          <div className="products-logos">
                            {item.products.map((product, i) => (
                              <ProductLogo product={product} key={i} />
                            ))}
                          </div>
                        </td>
                        <td>
                          {item.stats.started}
                          {/*item.stats.started
                            ? <a href="#" onClick={ev => countOnClick(ev, item)}>{item.stats.started}</a>
                            : "0"*/}
                        </td>
                        <td>
                          {item.stats.inQueue}
                          {/*item.stats.inQueue
                            ? <a href="#" onClick={ev => countOnClick(ev, item)}>{item.stats.inQueue}</a>
                          : "0"*/}
                        </td>
                        <td>
                          {item.stats.completed}
                          {/*item.stats.completed
                            ? <a href="#" onClick={ev => countOnClick(ev, item)}>{item.stats.completed}</a>
                          : "0"*/}
                        </td>
                        <td>
                          <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                            <Dropdown.Toggle className={actionLoading == "menu_" + item._id ? " loading spinner spinner-dark" : ""}>
                              <SVG src={toAbsoluteUrl("/media/def-image/icons/dots-circle.svg")} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                              <Dropdown.Item href="#" onClick={ev => editItem(ev, item)}>
                                <div className="icon">
                                  <SVG src="/media/def-image/icons/pencil.svg" />
                                </div>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item href="#" onClick={ev => renameItem(ev, item)}>
                                <div className="icon">
                                  <SVG src="/media/def-image/icons/rename.svg" />
                                </div>
                                Rename
                              </Dropdown.Item>
                              {!item.usesWhatsapp && (
                                <Dropdown.Item href="#" onClick={ev => duplicateItem(ev, item)}>
                                  <div className="icon">
                                    <SVG src="/media/def-image/icons/duplicate.svg" />
                                  </div>
                                  Duplicate
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item href="#" onClick={ev => togglePauseItem(ev, item)}>
                                {item.active
                                  ? (
                                    <>
                                      <div className="icon">
                                        <SVG src="/media/def-image/icons/pause-2.svg" />
                                      </div>
                                      Pause
                                    </>
                                  )
                                  : (
                                    <>
                                      <div className="icon">
                                        <SVG src="/media/def-image/icons/play-2.svg" />
                                      </div>
                                      Resume
                                    </>
                                  )}
                              </Dropdown.Item>
                              <Dropdown.Item href="#" onClick={ev => deleteItem(ev, item)}>
                                <div className="icon">
                                  <SVG src="/media/def-image/icons/delete.svg" />
                                </div>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )
          : (
            <div className="manage-automations-page text-center">
              <div className="empty-lottie">
                <Lottie options={{
                  animationData,
                  loop: false,
                  autoplay: true
                }} height={240} width={300} />
              </div>
              <div className="empty-content">
                <p className="mb-10">Automatically send messages, notifications, and more based on what<br />your listeners do, all on autopilot.</p>
                <div className="mb-20">
                  <Link to="#" onClick={createOnClick} className="btn btn-primary">Create your first automation</Link>
                </div>
              </div>
            </div>
          )
      }

      <ConfirmModal
        show={showConfirmDelete}
        message="Are you sure you want to delete the selected automation?"
        onConfirm={confirmDelete}
        onCancel={e => setShowConfirmDelete(false)} />
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(withRouter(Automations))
);
