import React from 'react';
import { ProductLogo } from '../../../../../_metronic/layout/components/productLogo';
import SVG from 'react-inlinesvg';
import { Dropdown } from 'react-bootstrap';
import { displayPrice, getAuthorUrl } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { Price } from './Price';

/**
 * Component PurchaseSummary.
 * @param {Object} props.product - Product information
 * @param {Object} props.author - Author information
 * @param {Object} [props.className] - Additional CSS class
 * @param {Object} [props.selectedPrice] - Currently selected price
 * @param {Function} [props.setSelectedPrice] - Function to set selected price
 * @param {Object} [props.purchasedPrice] - Price that was purchased (if any)
 * @param {Number} [props.discountPrice] - Discounted price (if any)
 */
export function PurchaseSummary({
  product,
  author,
  className,
  selectedPrice,
  setSelectedPrice,
  purchasedPrice,
  discountPrice,
}) {
  const authorUrl = getAuthorUrl(author);
  const { t } = useTranslation();

  return (
    <div className={'purchase-summary ' + (className || '')}>
      <div className='d-flex align-items-center'>
        <ProductLogo product={product} />
        <div className='flex-grow-1'>
          <h2>{product.name}</h2>
          <p>
            {t('Created by:')}{' '}
            <a href={authorUrl} className='author-link'>
              {author.firstName
                ? author.firstName +
                  (author.lastName ? ' ' + author.lastName : '')
                : author.email}
            </a>
          </p>
        </div>

        {purchasedPrice && (
          <Price price={purchasedPrice} discountPrice={discountPrice} />
        )}

        {!purchasedPrice && selectedPrice && product.prices.length == 1 && (
          <Price price={selectedPrice} discountPrice={discountPrice} />
        )}

        {!purchasedPrice && product.prices.length > 1 && (
          <Dropdown className='dropdown price-select-dropdown fixed-dropdown dropdown-inline'>
            <Dropdown.Toggle className='form-control'>
              {selectedPrice
                ? displayPrice(selectedPrice, t)?.string
                : t('Select plan')}
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} renderOnMount>
              {product.prices.map((p) => {
                let episodes = [product.episodes[0]._id.toString()];

                //how many episodes have the same tags
                for (let episode of product.episodes)
                  for (let price of product.prices)
                    for (let tag of price.tags)
                      if (
                        episode.releaseByTags.includes(tag) &&
                        !episodes.includes(episode._id.toString())
                      )
                        episodes.push(episode._id.toString());

                return (
                  <Dropdown.Item
                    key={p._id}
                    type='button'
                    className={
                      'dropdown-item ' +
                      (selectedPrice?._id == p._id ? 'active' : '')
                    }
                    onClick={() => setSelectedPrice(p)}
                  >
                    <div className='icon'>
                      {p.type == 'free' && (
                        <SVG src='/media/def-image/icons/gift.svg' />
                      )}
                      {p.type == 'one-time' && (
                        <SVG src='/media/def-image/icons/one-time.svg' />
                      )}
                      {p.type == 'subscription' && (
                        <SVG src='/media/def-image/icons/recurring.svg' />
                      )}
                    </div>
                    <div className='flex-1'>
                      <strong>{p.name}</strong>
                      <br />
                      <small className='text-muted'>
                        {t(
                          p.type == 'free'
                            ? '{{episodes}} episodes - Free'
                            : '{{episodes}} episodes - {{displayPrice}}',
                          {
                            episodes: episodes.length,
                            displayPrice: displayPrice(p, t)?.abbrString || '',
                          }
                        )}
                      </small>
                    </div>
                    {selectedPrice?._id == p._id && (
                      <SVG
                        src='/media/def-image/icons/check.svg'
                        className='checkmark ml-3'
                      />
                    )}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </div>
  );
}
