import React from "react";
import { Form } from "react-bootstrap";
import { ErrorMessage } from 'formik';
import { Button, Tooltip } from "@material-ui/core";
import { Field } from "formik";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { NavLink } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../../../redux/actions";
import { NeedsUpgradeEvents } from "../../../../modals/NeedsUpgrade";
import UpgradeLink from "../../../../generic/UpgradeLink";
import SVG from "react-inlinesvg";

function StepAvailability({ formik, currentUser, showButtons = false, loading = false }) {
  const handleChange = (propertyToUpdate, event) => {
    formik.setFieldValue(propertyToUpdate, parseInt(event.target.value));
    event.persist();
  };

  const releaseMode0DescriptionJSX = formik.values.releaseMode === 0 && (
    <p className="content-description with-border-bottom">
      All of your episodes will be available to the listener immediately. This is great for
      audiobooks or binge listening.
    </p>
  );

  const releaseMode1DescriptionJSX = formik.values.releaseMode === 1 && (
    <p className="content-description with-border-bottom">
      Release episodes or a batch of episodes over time. This is great for challenges or other evergreen content.
    </p>
  );

  const releaseMode2DescriptionJSX = formik.values.releaseMode === 2 && (
    <p className="content-description with-border-bottom">
      Set a specific date and time when episodes will be available.
      This is great for subscriptions and traditional podcasting.
    </p>
  );

  return (
    <>
      <div className="form-group">
        <label className="mb-5 form-label">
          Release type
          <Tooltip title="Immediate release is good for books or courses. Dripped is good for challenges. Date is good for live trainings." placement="top">
            <Button><i className="fa fa-info-circle" aria-hidden="true" /></Button>
          </Tooltip>

          {!currentUser.planFeatures.release && <UpgradeLink user={currentUser} />}
        </label>

        <div className="d-flex episode-access-options">
          <label className={"option " + (formik.values.releaseMode == "0" ? "active" : "")}>
            <Field type="radio" name="releaseMode" value={0} onChange={handleChange.bind(this, "releaseMode")} />
            <SVG src="/media/def-image/icons/all-at-once.svg" />
            All at once
          </label>
          <label className={"option " + (formik.values.releaseMode == "1" ? "active " : "") + (currentUser.planFeatures.release ? "" : "disabled")}>
            <Field type="radio" name="releaseMode" value={1} onChange={handleChange.bind(this, "releaseMode")} disabled={!currentUser.planFeatures.release} />
            <SVG src="/media/def-image/icons/dripped-color.svg" />
            Time period
          </label>
          <label className={"option " + (formik.values.releaseMode == "2" ? "active " : "") + (currentUser.planFeatures.release ? "" : "disabled")}>
            <Field type="radio" name="releaseMode" value={2} onChange={handleChange.bind(this, "releaseMode")} disabled={!currentUser.planFeatures.release} />
            <SVG src="/media/def-image/icons/calendar-color.svg" />
            Specific calendar date
          </label>
        </div>

        <div className="pt-8">
          {releaseMode0DescriptionJSX}
          {releaseMode1DescriptionJSX}
          {releaseMode2DescriptionJSX}
        </div>

        <ErrorMessage name="releaseMode">{msg => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>
      </div>

      <div className="form-group">
        <label className="mb-5 form-label">
          Show access duration
          <Tooltip title="Access to the feed can be forever or automatically stopped after a time has lapsed since the listener first installs the private feed." placement="top">
            <Button><i className="fa fa-info-circle" aria-hidden="true" /></Button>
          </Tooltip>

          {!currentUser.planFeatures.expire && <UpgradeLink user={currentUser} />}
        </label>

        <div className="d-flex episode-access-options">
          <label className={"option " + (formik.values.livePeriod == "0" ? "active" : "")}>
            <Field type="radio" name="livePeriod" value={0} onChange={handleChange.bind(this, "livePeriod")} />
            <SVG src="/media/def-image/icons/never-expires.svg" />
            Forever
          </label>
          <label className={"option " + (formik.values.livePeriod == "1" ? "active " : "") + (currentUser.planFeatures.expire ? "" : "disabled")}>
            <Field type="radio" name="livePeriod" value={1} onChange={handleChange.bind(this, "livePeriod")} disabled={!currentUser.planFeatures.expire} />
            <SVG src="/media/def-image/icons/show-expires.svg" />
            Audio feed expires
          </label>
          <label className={"option " + (formik.values.livePeriod == "2" ? "active " : "") + (currentUser.planFeatures.expire ? "" : "disabled")}>
            <Field type="radio" name="livePeriod" value={2} onChange={handleChange.bind(this, "livePeriod")} disabled={!currentUser.planFeatures.expire} />
            <SVG src="/media/def-image/icons/episode-expires.svg" />
            Episodes expire
          </label>
        </div>

        <ErrorMessage name="livePeriod">{msg => <div className="invalid-feedback d-block">{msg}</div>}</ErrorMessage>

        {formik.values.livePeriod == "1" && (
          <div className="pt-8">
            <label className="form-label">Access to your audio feed is automatically turned off:</label>
            <div className="d-flex product-expires-settings align-items-center mb-9">
              <Form.Control as="select" name="productExpires" {...formik.getFieldProps("productExpires")} className="custom-select w-auto mr-3">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
                <option>16</option>
                <option>17</option>
                <option>18</option>
                <option>19</option>
                <option>20</option>
                <option>21</option>
                <option>22</option>
                <option>23</option>
                <option>24</option>
                <option>25</option>
                <option>26</option>
                <option>27</option>
                <option>28</option>
                <option>29</option>
                <option>30</option>
              </Form.Control>
              <Form.Control as="select" name="productExpiresMode" {...formik.getFieldProps("productExpiresMode")} className="custom-select w-auto mr-3">
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
                <option value="months">Months</option>
              </Form.Control>
              <span className="flex-1">after listener installs {formik.values.name || "the audio feed"}</span>
            </div>

            <label className="form-label">Display time remaining in the Hiro player mobile app:</label>
            <div class="btn-group">
              <button type="button" class={"btn " + (formik.values.displayCountdown ? "btn-active" : "")} onClick={() => formik.setFieldValue("displayCountdown", true)}>Display countdown</button>
              <button type="button" class={"btn " + (!formik.values.displayCountdown ? "btn-active" : "")} onClick={() => formik.setFieldValue("displayCountdown", false)}>Hide countdown</button>
            </div>
          </div>
        )}

        {(formik.values.livePeriod == "1" || formik.values.livePeriod == "2") && (
          <div className="form-group pt-9 mb-0">
            <label className="checkbox checkbox-lg mb-3">
              <input type="checkbox" onChange={ev => formik.setFieldValue("accessForeverIfCompleted", ev.target.checked)} value={true} checked={formik.values.accessForeverIfCompleted} />
              <span />
              <strong className="fw-500">Grant lifetime access if completed</strong>
            </label>
            <div>Grant unlimited access to the show when the listener completes the required episodes.</div>
          </div>
        )}
      </div>
    </>
  );
}

export default injectIntl(connect(
  store => ({
    currentUser: store.auth.user,
  }),

  actions.product,
)(StepAvailability));