import React, { useCallback, useEffect, useRef } from "react";

export default function useAutosizeEmbed() {
    let intervalRef = useRef();

    let updateSize = useCallback(() => {
        postHeight();
    }, []);

    function postHeight() {
        window.parent?.postMessage({
            hiroFrameHeight: document.querySelector("#root").scrollHeight
        }, "*");
    }

    useEffect(() => {
        function handleEvent() {
            postHeight();
        };

        document.querySelector("#root").style.minHeight = "auto !important";

        window.addEventListener("load", handleEvent);
        window.addEventListener("resize", handleEvent);
        intervalRef.current = setInterval(() => postHeight(), 30000);
        postHeight();

        return () => {
            window.removeEventListener("load", handleEvent);
            window.removeEventListener("resize", handleEvent);
            clearInterval(intervalRef.current);
        };
    }, []);

    return updateSize;
}