import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import api from '../../../../redux/api';
import CompletePaymentFlow from './payment-signup-form/CompletePaymentFlow';
import { useTransparentBackground } from '../../../helpers/use-transparent-background';

function EmbeddSignUpPage(props) {
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  useTransparentBackground();

  const productId =
    props.productId ||
    props.match?.params?.id ||
    params.id ||
    searchParams.get('productId');

  const uri = props.match?.params?.uri || params.uri;

  const [product, setProduct] = useState(null);
  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stripeObject, setStripeObject] = useState(null);
  const [error, setError] = useState(null);

  async function init() {
    setLoading(true);
    try {
      const filter = productId ? { id: productId } : uri ? { uri: uri } : null;

      if (!filter) {
        setError('ID or URI not provided');
        setLoading(false);
        return;
      }

      const res = await api.salesPage.getProduct(filter);

      if (!res.success || !res.data) {
        setError('Product not found');
        setLoading(false);
        return;
      }

      setProduct(res.data.product);
      setAuthor(res.data.author);

      if (
        res.data.author.stripeConnected &&
        res.data.author.stripeConnected.stripeUserId
      ) {
        try {
          const stripeKey =
            typeof process !== 'undefined' && process.env
              ? process.env.REACT_APP_STRIPE_KEY
              : window.STRIPE_KEY || '';

          const stripeRes = await loadStripe(stripeKey, {
            stripeAccount: res.data.author.stripeConnected.stripeUserId,
          });
          setStripeObject(stripeRes);
        } catch (stripeError) {
          console.error('Error loading Stripe:', stripeError);
        }
      }

      setLoading(false);
    } catch (error) {
      console.error('Error loading data:', error);
      setError('Error loading data');
      setLoading(false);
    }
  }

  useEffect(() => {
    init();
  }, [productId, uri]);

  if (error) {
    return <div className='payment-embed-error'>{error}</div>;
  }

  if (loading) {
    return (
      <div className='embedded-player loading'>
        <svg className='splash-spinner' viewBox='0 0 50 50'>
          <circle
            className='path'
            cx='25'
            cy='25'
            r='20'
            fill='none'
            strokeWidth='5'
          ></circle>
        </svg>
      </div>
    );
  }

  return (
    <div className='card overflow-hidden m-0'>
      {product && author && (
        <CompletePaymentFlow
          product={product}
          author={author}
          stripeObject={stripeObject}
          showForm={true} // Always show the form
          onHideForm={() => {}} // Do nothing when hiding in embedded mode
          isModal={false}
          redirectOnSuccess={false}
        />
      )}
    </div>
  );
}

export default injectIntl(
  connect(
    (store) => ({
      currentUser: store.auth.user,
    }),
    (dispatch) => ({
      dispatch,
    })
  )(EmbeddSignUpPage)
);
