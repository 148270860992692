export default {
    translation: {
        "Coupon Code (optional)": "Código do cupão (opcional)",
        "/ wk": "/ sem",
        "/ mo": "/ mês",
        "/ yr": "/ ano",
        "Click for Access": "Clique para acessar",
        "Buy Now": "Comprar agora",
        "Select plan": "Selecionar plano",
        "{{episodes}} episodes - free": "{{episodes}} episódios - grátis",
        "{{episodes}} episodes - {{displayPrice}}": "{{episodes}} episódios - {{displayPrice}}",
        "All": "Todos",
        "Audio Feeds": "Feeds de áudio",
        "Category": "Categoria",
        "There are no shows in this category.": "Não há programas nesta categoria.",
        "The creator doesn’t have any public audio feeds yet.": "O criador ainda não tem feeds de áudio públicos.",
        "About me": "Sobre mim",
        "My audio feeds": "Meus feeds de áudio",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "Concordo com os termos de serviço e política de privacidade do Hiro.",
        "Powered by Hiro.fm": "Desenvolvido por Hiro.fm",
        "Start your own private audio product": "Inicie seu próprio produto de áudio privado",
        "episodes": "episódios",
        "Free": "Grátis",
        "/ week": "/ semana",
        "/ month": "/ mês",
        "/ year": "/ ano",
        "one-time payment": "pagamento único",
        "per week": "por semana",
        "per month": "por mês",
        "per year": "por ano",
        "Products": "Produtos",
        "Episodes": "Episódios",
        "Listeners": "Ouvintes",
        "Downloads": "Downloads",
        "My activity": "Minha atividade",
        "Created by:": "Criado por:",
        "Last update:": "Última atualização:",
        "This audio feed doesn’t have any eposides yet.": "Este feed de áudio ainda não tem episódios.",
        "See more": "Ver mais",
        "Please verify the credit card details.": "Por favor, verifique os detalhes do cartão de crédito.",
        "Payment": "Pagamento",
        "Your details": "Seus detalhes",
        "First Name": "Nome",
        "Last Name": "Sobrenome",
        "Email": "E-mail",
        "Phone (optional)": "Telefone (opcional)",
        "Invalid phone number.": "Número de telefone inválido.",
        "Payment details": "Detalhes do pagamento",
        "Total payment": "Pagamento total",
        "Get Access": "Obter acesso",
        "Pay": "Pagar",
        "Category:": "Categoria:",
        "Get Free Access": "Obter acesso gratuito",
        "Show currently unavailable": "Mostrar atualmente indisponível",
        "Buy now for {{priceValue}}": "Compre agora por {{priceValue}}",
        "Length": "Duração",
        "reviews": "avaliações",
        "About": "Sobre",
        "Testimonials": "Depoimentos",
        "My other private audio feeds": "Meus outros feeds de áudio privados",
        "Thank you!": "Obrigado!",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "Você comprou com sucesso {{productName}} de {{author}}. Enviamos um e-mail para {{installEmail}} com instruções de acesso.",
        "Point your phone’s camera at the code below to access the private podcast.": "Aponte a câmera do seu telefone para o código abaixo para acessar o podcast privado.",
        "Purchased item": "Item comprado",
        "Access Private Feed Now": "Acesse o Feed Privado Agora",
        "Ohh no, something went wrong with your payment": "Ohh não, algo deu errado com o seu pagamento",
        "Check your credit card details and try one more time.": "Verifique os detalhes do seu cartão de crédito e tente mais uma vez.",
        "Try one more time": "Tentar mais uma vez",
        "Cancel": "Cancelar",
        "Enter your first name.": "Digite seu nome.",
        "Enter your last name.": "Digite seu sobrenome.",
        "Enter your email address.": "Digite seu endereço de e-mail.",
        "Invalid email address.": "Endereço de e-mail inválido.",
        "Product not found.": "Produto não encontrado.",
        "Payment failed.": "Falha no pagamento.",
        "Fill all the fields.": "Preencha todos os campos.",
        "The email address is invalid.": "O endereço de e-mail é inválido.",
        "Invalud product ID.": "ID de produto inválido.",
        "Access denied to product.": "Acesso negado ao produto.",
        "Show not available.": "Programa não disponível.",
        "Terms of service": "Termos de serviço",
        "Privacy policy": "Política de Privacidade"
    }
};