export default {
    translation: {
        "Coupon Code (optional)": "Código Promocional (opcional)",
        "/ wk": "/ sem",
        "/ mo": "/ mes",
        "/ yr": "/ año",
        "Click for Access": "Haz clic para acceder",
        "Buy Now": "Comprar",
        "Select plan": "Selecciona el plan",
        "{{episodes}} episodes - free": "{{episodes}} episodios - gratis",
        "{{episodes}} episodes - {{displayPrice}}": "{{episodes}} episodios - {{displayPrice}}",
        "All": "Todas",
        "Audio Feeds": "Feeds de audio",
        "Category": "Categoría",
        "There are no shows in this category.": "No hay programas en esta categoría.",
        "The creator doesn’t have any public audio feeds yet.": "El creador aún no tiene feeds de audio públicos.",
        "About me": "Sobre mí",
        "My audio feeds": "Mis feeds de audio",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "Acepto los <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">términos de servicio</a> y la <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">política de privacidad</a> de Hiro.",
        "Powered by Hiro.fm": "Powered by Hiro.fm",
        "Start your own private audio product": "Inicia tu propio producto de audio privado",
        "episodes": "episodios",
        "Free": "Gratis",
        "/ week": "/ semana",
        "/ month": "/ mes",
        "/ year": "/ año",
        "one-time payment": "pago único",
        "per week": "por semana",
        "per month": "por mes",
        "per year": "por año",
        "Products": "Productos",
        "Episodes": "Episodios",
        "Listeners": "Oyentes",
        "Downloads": "Descargas",
        "My activity": "Mi actividad",
        "Created by:": "Creado por:",
        "Last update:": "Última actualización:",
        "This audio feed doesn’t have any eposides yet.": "Este feed de audio aún no tiene episodios.",
        "See more": "Ver más",
        "Please verify the credit card details.": "Por favor verifica los detalles de la tarjeta de crédito.",
        "Payment": "Pago",
        "Your details": "Tus detalles",
        "First Name": "Nombre",
        "Last Name": "Apellido",
        "Email": "Correo electrónico",
        "Phone (optional)": "Teléfono (opcional)",
        "Invalid phone number.": "Número de teléfono no válido.",
        "Payment details": "Detalles del pago",
        "Total payment": "Pago total",
        "Get Access": "Obtener acceso",
        "Pay": "Pagar",
        "Category:": "Categoría:",
        "Get Free Access": "Obtener acceso gratuito",
        "Show currently unavailable": "Show temporalmente no disponible",
        "Buy now for {{priceValue}}": "Comprar ahora por {{priceValue}}",
        "Length": "Duración",
        "reviews": "reseñas",
        "About": "Acerca de",
        "Testimonials": "Testimonios",
        "My other private audio feeds": "Mis otros feeds de audio privados",
        "Thank you!": "¡Gracias!",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "Has comprado exitosamente {{productName}} de {{author}}. Te enviamos un correo a {{installEmail}} con instrucciones de acceso.",
        "Point your phone’s camera at the code below to access the private podcast.": "Apunta la cámara de tu teléfono al código de abajo para acceder al podcast privado.",
        "Purchased item": "Artículo comprado",
        "Access Private Feed Now": "Acceder al feed priv",
        "Ohh no, something went wrong with your payment": "",
        "Check your credit card details and try one more time.": "",
        "Try one more time": "Intenta una vez más",
        "Cancel": "Cancelar",
        "Enter your first name.": "Ingresa tu nombre.",
        "Enter your last name.": "Ingresa tu apellido.",
        "Enter your email address.": "Ingresa tu email.",
        "Invalid email address.": "Email inválido.",
        "Product not found.": "Show no encontrado.",
        "Payment failed.": "Falló el pago.",
        "Fill all the fields.": "Completa todos los campos.",
        "The email address is invalid.": "El email es inválido.",
        "Invalud product ID.": "Show inválido.",
        "Access denied to product.": "Acceso denegado al show.",
        "Show not available.": "Show no disponible.",
        "Terms of service": "Términos",
        "Privacy policy": "Privacidad"
    }
};