import React, { useEffect,useState,useRef } from "react";
import api from "../../../../redux/api";
import _ from "lodash";
import { ProductLogo } from "../../../../_metronic/layout/components/productLogo";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Scrollbars } from "react-custom-scrollbars";
import { formatLength } from "../../../helpers";

/**
 * Component EmbeddedPlayer
 * @param {Object} props 
 * @returns {Object}
 */
export default function EmbeddedPlayer(props) {
  let [product,setProduct]=useState(null),
    [episodes,setEpisodes]=useState([]),
    [episode,setEpisode]=useState(null),
    [episodeIndex,setEpisodeIndex]=useState(0),
    [playing,setPlaying]=useState(false),
    [playbackRate,setPlaybackRate]=useState(1),
    [currentTime,setCurrentTime]=useState(0),
    [progress,setProgress]=useState(0),
    [loading,setLoading]=useState(true),
    [knobDrag,setKnobDrag]=useState(false),
    player=useRef(null),
    timeBar=useRef(null);

  useEffect(()=>{
    setLoading(true);
    api.salesPage.getPlayer(props.match.params.id)
        .then(res=>{
            if(res.success&&res.data) {
              setProduct(res.data.product);

              if(res.data.product.episodes.length) {
                if(!props.match.params.episodes) {
                  setEpisodes(res.data.product.episodes);
                  setEpisode(res.data.product.episodes[0]);
                } else {
                  let items=[],
                    ids=props.match.params.episodes.split(";");
                  
                  for(let i=0;i<res.data.product.episodes.length;i++)
                    if(ids.includes(res.data.product.episodes[i]._id))
                      items.push(res.data.product.episodes[i]);

                  if(items.length) {                  
                    setEpisodes(items);
                    setEpisode(items[0]);
                  }
                }
              }
            }
            setLoading(false);
        });
      document.querySelector("#root").scrollTo({ top:0,behavior:"smooth" });
  },[props.match.params.uri]);

  useEffect(()=>{
      if(!episode) return;
      setProgress(Math.min(100,currentTime*100/episode.publicAudio.durationInSeconds));
  },[currentTime]);  

  useEffect(()=>{
    if(!player.current) return;
    if(playing) {
        player.current.play();
    } else {
        player.current.pause();
    }
  },[playing]); 

  useEffect(()=>{
    if(!player.current) return;
    player.current.playbackRate=playbackRate;
  },[playbackRate]);

  let selectEpisode=i=>{
    if(!product||!player.current) return;
    setEpisode(episodes[i]);
    setEpisodeIndex(i);
    setPlaying(true);
    player.current.load();
    player.current.play();
    player.current.playbackRate=playbackRate;
  };

  let seekByClick=ev=>{
      if(!timeBar.current||!player.current||!episode) return;
      let relativeX=ev.clientX-timeBar.current.getBoundingClientRect().left;
      player.current.currentTime=episode.publicAudio.durationInSeconds*(relativeX/timeBar.current.clientWidth);
      setCurrentTime(player.current.currentTime);
  };

  if(loading)
    return <div className="embedded-player loading">      
      <svg className="splash-spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
      </svg>
    </div>;

  if(!product||!episode)
    return <div className="embedded-player error">
        <div>
          <SVG src={toAbsoluteUrl("/media/def-image/icons/info-base.svg")} className="svg-icon"/>
          Product unavailable.
        </div>
      </div>;
  
  return <div className={"embedded-player "+(episodes.length<=1?"single-episode":"")}>
      <div className="cover-image-container lg">
        <div>
          <ProductLogo product={product}/>
        </div>
      </div>
      <div className="player-body">
        <a href="https://hiro.fm" target="_blank" className="hiro-icon">
          <SVG src={toAbsoluteUrl("/media/logos/h-circle.svg")} className="svg-icon"/>
        </a>
        <div className="product">
          <ProductLogo product={product} className="sm"/>
          <div>
            <p className="product-name">{product.name} | {episode.episodeNumber}</p>
            <h1>{episode.title}</h1>
          </div>
        </div>
        <div className="player-controls">
          <button type="button" className="play-pause" onClick={ev=>setPlaying(!playing)}>
            <SVG src={toAbsoluteUrl("/media/def-image/icons/play.svg")} className={"svg-icon play "+(playing?"d-none":"")}/>
            <SVG src={toAbsoluteUrl("/media/def-image/icons/pause.svg")} className={"svg-icon pause "+(!playing?"d-none":"")}/>
          </button>
          <div>
            <div
              className="waveform"
              ref={timeBar}
              onMouseDown={ev=>{
                  setPlaying(false);
                  setKnobDrag(true);
              }}
              onMouseUp={ev=>{
                  setPlaying(true);
                  setKnobDrag(false);
                  seekByClick(ev);
              }}
              onMouseMove={ev=>{
                  if(!knobDrag) return;
                  seekByClick(ev);
              }}>
                <img src={episode.waveform?episode.waveform:toAbsoluteUrl("/media/def-image/waveform.jpg")} className="background"/>
                <div style={{ width:progress+"%" }} className="waveform-progress"></div>
                <label className="time">{formatLength(currentTime,true)}</label>
            </div>            
            <button type="button" className="playback-rate" onClick={ev=>{
              if(playbackRate==1) setPlaybackRate(1.5);
              else if(playbackRate==1.5) setPlaybackRate(2);
              else if(playbackRate==2) setPlaybackRate(1);
            }}>{playbackRate}X</button>
          </div>
        </div>
        <div className="list scrollbar">
          <Scrollbars
            autoHeight
            hideTracksWhenNotNeeded
            className="scrollbar-view"
            renderTrackVertical={({style,...props})=><div {...props} style={{...style}} className="scrollbar-track"/>}                
            renderThumbVertical={({style,...props})=><div {...props} style={{...style}} className="scrollbar-thumb"/>}>
              {episodes.map((episode,i)=>
                <a href="#" key={i} className="list-item" onClick={ev=>{
                  ev.preventDefault();
                  selectEpisode(i);
                }}>
                  <span className="number">{episode.episodeNumber}</span>
                  <strong className="title">{episode.title}</strong>
                  <span className="duration">{formatLength(episode.publicAudio.durationInSeconds,true)}</span>
                </a>
              )}
          </Scrollbars>
        </div>
      </div>      
      <audio
        ref={player}
        onEnded={()=>{
          if(episodeIndex<episodes.length-1) {
            selectEpisode(episodeIndex+1);
          } else {
            setPlaying(false);
          }
        }}
        onTimeUpdate={(ev)=>{
          setCurrentTime(ev.target.currentTime);
        }}>
          <source src={episode.publicAudio.audioUrl} type="audio/mpeg" />
      </audio>
    </div>;
}
