import React from 'react';
import { SalesPageContent } from './SalesPageContent';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

function PublicPage({ productId, currentUser, match }) {
  return (
    <SalesPageContent
      productId={productId}
      isEmbed={false}
      currentUser={currentUser}
      match={match}
    />
  );
}

export default injectIntl(
  connect(
    (store) => ({
      currentUser: store.auth.user,
    }),
    (dispatch) => ({
      dispatch,
    })
  )(PublicPage)
);
