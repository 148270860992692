import * as Yup from "yup";

export const product = {
  full: Yup.object().shape({
    name: Yup.string()
      .min(2, "Too short!")
      .max(255, "The title is too long")
      .required("Required field"),

    description: Yup.string()
      .notRequired(),

    instructorName: Yup.string()
      .required("Required field"),

    fromName: Yup.string()
      .required("Required field"),

    replyToEmail: Yup.string().email("Invalid email address")
      .required("Required field")
  }),

  details: Yup.object().shape({
    name: Yup.string()
      .min(2, "Too short!")
      .max(255, "The title is too long")
      .required("Please enter Title"),

    description: Yup.string()
      .notRequired(),

    instructorName: Yup.string()
      .required("Please enter Owner")
  }),

  ownership: Yup.object().shape({
    fromName: Yup.string()
      .required("Required field"),

    replyToEmail: Yup.string().email("Invalid email address")
      .required("Required field")
  }),

  all: Yup.object().shape({
    name: Yup.string()
      .min(2, "Too short!")
      .max(255, "The title is too long")
      .required("Required field"),

    description: Yup.string()
      .notRequired(),

    instructorName: Yup.string()
      .required("Required field"),

    fromName: Yup.string()
      .required("Required field"),

    replyToEmail: Yup.string().email("Invalid email address")
      .required("Required field"),

    name: Yup.string()
      .min(2, "Too short!")
      .max(255, "The title is too long")
      .required("Please enter Title"),

    description: Yup.string()
      .notRequired(),

    instructorName: Yup.string()
      .required("Please enter Owner"),

    fromName: Yup.string()
      .required("Required field"),

    replyToEmail: Yup.string().email("Invalid email address")
      .required("Required field")
  })
};

export const salesPage = {
  domain: Yup.object().shape({
    uri: Yup.string()
      .min(2)
      //.required("Enter your desired URL.")
      .matches(/^[a-z0-9-]+$/, "Only letters, numbers and dashes are allowed, and it can’t start or end with a dash.")
      .matches(/^[a-z0-9]/, "It can’t start or end with a dash.")
      .matches(/[a-z0-9]$/, "It can’t start or end with a dash.")
  }),

  thankYou: Yup.object().shape({
    thankYouUrl: Yup.string()
      .notRequired()
      .matches(/^https?:\/\//, "Please, enter a valid URL.")
  }),

  about: Yup.object().shape({
  }),

  price: Yup.object().shape({
    name: Yup.string().required("Enter the name."),
    amount: Yup.number().nullable(true).min(0).notRequired()
  }),

  coupon: Yup.object().shape({
    name: Yup.string().required("Enter the coupon code."),
    amount: Yup.number().nullable(true).moreThan(0, "Must be greater than 0.").notRequired()
  }),

  upgrade: Yup.object().shape({
    price: Yup.number().nullable(true).min(0).notRequired()
  }),

  review: Yup.object().shape({
    firstName: Yup.string().required("Enter the first name."),
    lastName: Yup.string().required("Enter the last name."),
    review: Yup.string().required("Enter the review content.")
  }),

  faq: Yup.object().shape({
    question: Yup.string().required("Enter the question."),
    response: Yup.string().required("Enter the response to the question.")
  }),

  getFreeAccess: Yup.object().shape({
    firstName: Yup.string().required("Enter your first name."),
    lastName: Yup.string().required("Enter your last name."),
    email: Yup.string().required("Enter your email address.").email("Invalid email address."),
    phone: Yup.string().notRequired()
  }),

  buy: Yup.object().shape({
    firstName: Yup.string().required("Enter your first name."),
    lastName: Yup.string().required("Enter your last name."),
    email: Yup.string().required("Enter your email address.").email("Invalid email address."),
    phone: Yup.string().notRequired()
  })

};

export const productDelete = Yup.object().shape({
  confirm: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
});

