import React, { useEffect, useState } from "react";
import { makeStyles, Paper, InputBase, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { EpisodeItem } from "./generic/EpisodeItem";
import api from "../../../../../redux/api";
import { getSearchRegExp, toastMessage, updateProducts } from "../../../../helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../../redux/actions";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import _ from "lodash";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";
import ConfirmModal from "../../../modals/ConfirmModal";
import { Dropdown } from "react-bootstrap";
import { Switch, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { UpgradeEvents } from "../../../modals/UpgradeModal";
import Lottie from "react-lottie";
import animationData from "../../../../layout/lottie/empty-episodes.json";

require("dotenv").config();

const DragHandle = SortableHandle(() => <img src={toAbsoluteUrl("/media/svg/drag-handle.svg")} alt="" onDragStart={e => e.preventDefault()} />);

const SortableItem = SortableElement(({ episode, sortIndex, productData, selectedEpisodes, toggleEpisode, loading, toggleRequired }) => (
  <EpisodeItem
    item={episode}
    key={episode._id}
    productId={productData._id}
    toggleEpisode={toggleEpisode}
    isSelectEpisode={selectedEpisodes.includes(episode._id)}
    DragHandle={productData.releaseMode == 2 ? null : DragHandle}
    sortIndex={sortIndex}
    productData={productData}
    loading={loading}
    toggleRequired={toggleRequired}
  />
));

const SortableList = SortableContainer(({ items, productData, selectedEpisodes, toggleEpisode, loading, toggleRequired }) => (
  <tbody>
    {items && items.map((episode, index) => (
      <SortableItem
        key={`item-${episode._id}`}
        index={index}
        sortIndex={index}
        selectedEpisodes={selectedEpisodes}
        toggleEpisode={toggleEpisode}
        episode={episode}
        productData={productData}
        disabled={productData.releaseMode == 2}
        loading={loading}
        toggleRequired={toggleRequired} />
    ))}
  </tbody>
));

function AdvanceTablesWidgetEpisodes({ user, dispatch, className, episodes, productId, setEpisodes, setProduct, productData, isDragging, setDragging }) {
  let [stats, setStats] = useState(null),
    [episodesShown, setEpisodesShown] = useState(episodes),
    [selectedEpisodes, setSelectedEpisodes] = useState([]),
    [embedEnabledLoading, setEmbedEnabledLoading] = useState(null),
    [showEmbedDropdown, setShowEmbedDropdown] = useState(false),
    [copyUriTooltip, setCopyUriTooltip] = useState(false),
    [embedCode, setEmbedCode] = useState(""),
    [confirmDelete, setConfirmDelete] = useState(false),
    [loading, setLoading] = useState(true);

  let embedEnabledChanged = ev => {
    if(!user.planFeatures.embedPlayer)
      return UpgradeEvents.dispatchShow({
        upgradeToAccess: true
      });

    setEmbedEnabledLoading(ev.target.checked);

    api.product.editProduct({
      product: {
        _id: productId,
        embedEnabled: ev.target.checked
      }
    }).then(res => {
      toastMessage.success("Product updated successfully.");

      api.product.getProduct(productId).then(res => {
        dispatch(setProduct(res));
        setShowEmbedDropdown(true);
        setEmbedEnabledLoading(null);
      });
    });
  };

  let copyEmbedClick = ev => {
    try {
      navigator.clipboard.writeText(embedCode);
      setCopyUriTooltip(true);
      setTimeout(() => setCopyUriTooltip(false), 2000);
    } catch(x) { }
  };

  let buildEmbedCode = () => {
    let episodes = "";
    if(selectedEpisodes.length) {
      episodes += "/";
      for(let i = 0; i < selectedEpisodes.length; i++)
        episodes += (episodes == "/" ? "" : ";") + selectedEpisodes[i];
    }
    setEmbedCode("<iframe width=\"560\" height=\"315\" src=\"" + process.env.REACT_APP_SERVER_URL + "embed/" + productData._id + episodes + "\" frameborder=\"0\"></iframe>");
  };

  useEffect(() => {
    buildEmbedCode();
  }, [selectedEpisodes]);

  useEffect(() => {
    setEpisodesShown(episodes);
  }, [episodes]);

  useEffect(() => {
    fetchEpisodes();
  }, []);

  let fetchEpisodes = async (loading = true) => {
    setSelectedEpisodes([]);
    setConfirmDelete(false);

    setLoading(loading);

    dispatch(setProduct(await api.product.getProduct(productId)));

    let episodes = await api.episode.getEpisodes({ id: productId });
    setStats(episodes.stats);
    dispatch(setEpisodes(episodes.data));

    setLoading(false);
  };

  let toggleRequired = async item => {
    setLoading("required_" + item._id);

    await api.episode.editEpisode({
      episode: {
        _id: item._id,
        required: !item.required
      }
    });

    let episodes = await api.episode.getEpisodes({ id: productId });
    dispatch(setEpisodes(episodes.data));

    setLoading(false);
  };

  let toggleEpisode = e => {
    let item = e.target.value;
    if(e.target.checked) {
      setSelectedEpisodes(v => [...v, item]);
    } else {
      setSelectedEpisodes(v => v.filter(id => id !== item));
    }
  };

  let handleSearch = e => {
    if(!e.target.value) {
      setEpisodesShown(episodes);
    } else {
      let r = getSearchRegExp(e.target.value);
      setEpisodesShown(episodes.filter(episode => r.test(episode.title) || r.test(episode.summary)));
    }
  };

  let toggleAll = e => {
    if(e.target.checked) {
      setSelectedEpisodes(episodes.map(episode => episode._id));
    } else {
      setSelectedEpisodes([]);
    }
  };

  let deleteEpisodes = () => {
    setConfirmDelete(true);
  };

  let confirmDeleteEpisodes = async () => {
    setConfirmDelete(false);
    setLoading("delete");

    await api.episode.deleteEpisodes(selectedEpisodes);

    fetchEpisodes("table");
    setLoading(false);
    toastMessage.success("Episodes deleted successfully.");
    updateProducts(dispatch);
  };

  let publishEpisodes = async () => {
    setLoading("publish");

    await api.episode.editEpisodes(selectedEpisodes, {
      draft: -1
    });

    setLoading(false);
    fetchEpisodes("table");
    toastMessage.success("Episodes published successfully.");
    updateProducts(dispatch);
  };

  let onSortEnd = ({ oldIndex, newIndex, collection, isKeySorting }) => {
    if(oldIndex == newIndex)
      return;

    setLoading("table");

    //We won't use newIndex directly because maybe the list was filtered
    let movingDown = newIndex > oldIndex,
      targetIndex = movingDown ? newIndex : newIndex - 1,
      episode = episodesShown[oldIndex]._id,
      afterEpisode = targetIndex < 0 ? null : episodesShown[targetIndex]._id;

    //Temporarily reorder the table locally so the dragged element doesn't go back to its original position
    setEpisodesShown(arrayMove(episodesShown, oldIndex, newIndex));

    api.episode.reorderEpisode({ productId: productId, episodeId: episode, params: { moveAfterId: afterEpisode } }).then(() => {
      fetchEpisodes("table");
    });
  };

  return (
    <>

      {loading === true
        ? <div className="loading-block spinner spinner-lg" />
        : (
          <>
            {!episodes.length
              ? (
                productData.pendingOwnership
                  ? (
                    <div className="container-small">
                      <div className="row">
                        <div className="col-lg-12 col-xxl-12 no-episodes-item">
                          <span className="my-10">
                            <img src={toAbsoluteUrl("/media/def-image/episodes-empty-data-state.svg")} alt="" />
                          </span>

                          <p>Episodes will be imported after confirming ownership.</p>
                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div className="container-small">
                      <div className="empty-lottie">
                        <Lottie options={{
                          animationData,
                          loop: false,
                          autoplay: true
                        }} height={240} width={300} />
                      </div>
                      <div className="empty-content">
                        <p className="mb-10 text-center">Upload your audio or video files.<br />Videos and MP4 files are automatically converted to MP3’s.</p>

                        <div className="d-flex justify-content-center">
                          <Link
                            to={`/products/${productId}/episodes/create-single`}
                            className="btn btn-secondary mr-2"
                          >Add Single Episode</Link>

                          <Link
                            to={`/products/${productId}/episodes/bulk-upload`}
                            className="btn btn-primary ml-2"
                          >Bulk Upload Episodes</Link>
                        </div>
                      </div>
                    </div>
                  )
              )
              : (
                <>

                  <div className="row stats stats-overview">
                    <div className="col-lg-3">
                      <div className="card">
                        <h3>Total Episodes</h3>
                        <label>{episodes.filter(ep => ep.draft != 1).length}</label>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="card">
                        <h3>Total Downloads</h3>
                        <label>{stats ? parseInt(stats.downloads).toLocaleString() : "-"}</label>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="card">
                        <h3>Most Popular</h3>
                        <label className="fs-inherit">{stats ? stats.mostPopular : "-"}</label>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="card">
                        <h3>Completions</h3>
                        <label>{stats ? parseInt(stats.completions).toLocaleString() : "-"}</label>
                      </div>
                    </div>
                  </div>

                  <div className={`card ${className}`}>
                    <div className="card-header border-0 pb-0">
                      <div className="card-toolbar">
                        <input type="text" className="form-control search" placeholder="Search Episodes" onChange={handleSearch} />

                        <div className="d-flex fill justify-content-end">
                          <Dropdown onClick={ev => ev.preventDefault()} show={showEmbedDropdown}>
                            <Dropdown.Toggle className="embed-btn btn-light-primary" onClick={ev => {
                              ev.preventDefault();
                              setShowEmbedDropdown(!showEmbedDropdown);
                            }}>Embed</Dropdown.Toggle>
                            <Dropdown.Menu as="div" className="embed-dropdown">
                              <div className="px-6 text-center">
                                <label className="d-block text-left mb-3">
                                  <Switch
                                    checked={embedEnabledLoading !== null ? embedEnabledLoading : productData.embedEnabled}
                                    onChange={embedEnabledChanged}
                                    className="mr-2"
                                  />
                                  Enable Public Sharing
                                </label>
                                <textarea className="form-control mb-3" readOnly disabled={!productData.embedEnabled} value={embedCode} />
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={() => setCopyUriTooltip(false)}
                                  open={copyUriTooltip}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  className="text-center"
                                  arrow
                                  placement="top"
                                  title="Code copied!">
                                  <button type="button" className="btn btn-primary-light" disabled={!productData.embedEnabled} onClick={copyEmbedClick}>Copy Embed Code</button>
                                </Tooltip>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>

                          <button
                            onClick={publishEpisodes}
                            disabled={!selectedEpisodes.length}
                            className={"btn btn-light-primary mx-5 " + (loading == "publish" ? "loading spinner" : "")}
                          >
                            Publish
                          </button>

                          <button
                            onClick={deleteEpisodes}
                            className={"btn btn-danger font-weight-bolder font-size-sm " + (loading == "delete" ? "loading spinner" : "")}
                            disabled={!selectedEpisodes.length}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>

                    {loading == "table"
                      ? <div className="loading-block spinner spinner-lg" />
                      : (
                        <div className="card-body">
                          <div className="table-responsive">
                            <table
                              className="table table-head-custom table-vertical-center"
                              id="kt_advance_table_widget_1">
                              <thead>
                                <tr className="text-left">
                                  <th className="pl-6" style={{ width: "40px" }}>
                                    <label className="checkbox checkbox-lg checkbox-single">
                                      <input
                                        type="checkbox"
                                        value="1"
                                        onChange={toggleAll}
                                      />
                                      <span />
                                    </label>
                                  </th>

                                  <th className="pr-0">TITLE</th>

                                  <th className="text-center">REQUIRED</th>

                                  <th style={{ minWidth: "200px" }}>AUDIO</th>

                                  <th style={{ minWidth: "150px" }}>TAGS</th>

                                  {productData.releaseMode == 2 && <th style={{ minWidth: "150px" }}>RELEASE DATE</th>}

                                  {(productData.livePeriod == 1 || productData.livePeriod == 2) && <th style={{ minWidth: "150px" }}>EXPIRES</th>}

                                  {/*(productData.releaseMode == 0 || productData.releaseMode == 1) && <th style={{ minWidth: "150px" }}>RELEASE ORDER</th>*/}

                                  {productData.releaseMode == 1 && <th style={{ minWidth: "150px" }}>RELEASE SCHEDULE</th>}

                                  <th style={{ minWidth: "100px" }}>STATUS</th>
                                </tr>
                              </thead>

                              <SortableList
                                items={episodesShown}
                                productData={productData}
                                selectedEpisodes={selectedEpisodes}
                                toggleEpisode={toggleEpisode}
                                onSortEnd={onSortEnd}
                                useDragHandle
                                lockAxis="y"
                                helperClass="draggedEpisode"
                                loading={loading}
                                toggleRequired={toggleRequired}
                              />
                            </table>
                          </div>
                        </div>
                      )}
                  </div>
                </>
              )}
          </>
        )}

      <ConfirmModal
        show={confirmDelete}
        message="Do you want to delete the selected episodes?"
        onConfirm={confirmDeleteEpisodes}
        onCancel={() => setConfirmDelete(false)} />
    </>
  );
}

export default injectIntl(connect(
  state => ({
    user: (state.auth || {}).user || {},
    episodes: (state.episode || {}).episodes || [],
    productData: (state.product || {}).product || {},
  }),

  dispatch => ({
    ...actions.product,
    ...actions.episode,
    dispatch,
  }),
)(AdvanceTablesWidgetEpisodes));
