import _ from "lodash";
import React, { useRef, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import actions from "../../../../redux/actions";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link } from "react-router-dom";
import moment from "moment";
import { ProgressBar } from "react-bootstrap";
import { checkLimits } from "../../../plans";
import { UpgradeEvents } from "../../modals/UpgradeModal";
import SettingsNavigation from "../../../layout/navigation/SettingsNavigation";
import { Formik, Form } from "formik";
import schemas from "../../../schemas";
import NumberField from "../../../layout/components/NumberField";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";
import api from "../../../../redux/api";
import { toastMessage } from "../../../helpers";
import { Switch } from "@material-ui/core";
import ConfirmModal from "../../modals/ConfirmModal";

function BillingSettings() {
  let auth = useSelector(state => state.auth.user, shallowEqual),
    user = auth?.owner || auth,
    portalLink = useSelector(state => state.user.portalLink, shallowEqual),
    formikRef = useRef(),
    [loading, setLoading] = useState(false),
    [showConfirmCancel, setShowConfirmCancel] = useState(false),
    [cancelLoading, setCancelLoading] = useState(false),
    [billingLoading, setBillingLoading] = useState(),
    dispatch = useDispatch();

  async function updateUser() {
    dispatch(actions.auth.fulfillUser((await api.auth.getUserByToken(true)).data));
  }

  let sendAutochargeForm = async (values, loading) => {
    setLoading(loading);

    await api.user.editCurrentUser({
      user: values,
    });

    toastMessage.success("Your settings have been updated!");

    await updateUser();

    setLoading(0);
  };

  let saveAutoCharge = async () => {
    await sendAutochargeForm(formikRef.current.values, "modal");
  };

  let num = v => isNaN(parseFloat(v))
    ? 0
    : (
      parseInt(v) !== parseFloat(v)
        ? parseFloat(v).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        : parseInt(v).toLocaleString()
    );

  async function cancelPlan() {
    setCancelLoading(true);

    const res = await api.user.downgrade();

    if(res && res.success)
      toastMessage.success("Your subscription has been updated!");
    else
      toastMessage.error((res && res.error) || "Unable to connect to the server.");

    await updateUser();

    setCancelLoading(false);
    setShowConfirmCancel(false);
  }

  async function wordpressBillingOnClick() {
    setBillingLoading(true);

    let res = await api.user.getWordpressOtpLink();

    if(!res || !res.success) {
      setBillingLoading(false);
      return toastMessage.error("Unable to sign you in, please contact support.");
    }

    window.location.href = res.url;
  }

  if(!user)
    return <></>;

  return (
    <>
      <SettingsNavigation active="billing" />

      <div className="container-inner">
        <div className="card card-profile-header mb-10">
          <div className="card-body pt-9 pb-10">
            <div className="row align-items-center">
              <div className="col pr-6">
                {user.noPlan
                  ? <p className="fw-bold mb-0">No active plan</p>
                  : <>
                    <p className="fw-bold mb-0">{user.planFeatures.title} Plan {(() => {
                      if(user.newPlan.period == "monthly") return " · Monthly";
                      if(user.newPlan.period == "annual") return " · Yearly";
                    })()}</p>
                    {user.newPlan.cancelAtPeriodEnd
                      ? <p className="text-gray mb-0">Cancels at {moment(user.newPlan.expiration).locale("en").format("MMM D, YYYY")}</p>
                      : (
                        <p className="text-gray mb-0">
                          {user.newPlan.createdAt ? <>Active since {moment(user.newPlan.createdAt).locale("en").format("MMM D, YYYY")}</> : <></>}
                          {user.newPlan.trialEnds && user.freeTrial && new Date(user.newPlan.trialEnds) >= new Date() ? " · " : ""}
                          {user.newPlan.trialEnds && user.freeTrial && new Date(user.newPlan.trialEnds) >= new Date() ? <>Trial ends {moment(user.newPlan.trialEnds).locale("en").format("MMM D, YYYY")}</> : <></>}
                        </p>
                      )}
                  </>
                }
              </div>
            </div>

            <div className="separator my-6"></div>

            <div className="d-flex align-items-center">
              {user.source == "wordpress" && (
                <button type="button" className={"btn btn-secondary mr-2 " + (billingLoading ? "loading spinner" : "")} onClick={wordpressBillingOnClick}>
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-top-panel-6.svg")} className="svg-icon svg-icon-3 mr-2" />
                  Manage Billing
                </button>
              )}

              {user.source == "wordpress-academy" && (
                <a href="https://academy.hiro.fm/my-account/" target="_blank" className="btn btn-secondary mr-2">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-top-panel-6.svg")} className="svg-icon svg-icon-3 mr-2" />
                  Manage Billing
                </a>
              )}

              {!user.source && portalLink && (
                <a href={portalLink} target="_blank" className="btn btn-secondary mr-2">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-top-panel-6.svg")} className="svg-icon svg-icon-3 mr-2" />
                  Manage Billing
                </a>
              )}

              {user && !user.source && !user.newPlan.cancelAtPeriodEnd && (
                user.noPlan
                  ? (
                    <button type="button" className="btn btn-primary" onClick={ev => checkLimits.upgradeOrActivate(user)}>
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Like.svg")} className="svg-icon svg-icon-3" />
                      Purchase Plan
                    </button>
                  )
                  : (
                    <>
                      <button type="button" className="btn btn-primary ml-2 mr-8" onClick={ev => UpgradeEvents.dispatchShow()}>
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Like.svg")} className="svg-icon svg-icon-3" />
                        Upgrade plan
                      </button>
                      <a href="#" className="link-muted-underline" onClick={() => setShowConfirmCancel(true)}>
                        Cancel Subscription
                      </a>
                    </>
                  ))}
            </div>
          </div>
        </div>

        {user && user.phoneNumberOwner == "hiro" && (
          <Formik
            initialValues={{
              autoChargeEnabled: user.autoChargeEnabled,
              autoChargeAmount: user.autoChargeAmount === null ? 20 : user.autoChargeAmount,
              autoChargeWhen: user.autoChargeWhen === null ? 10 : user.autoChargeWhen,
              autoChargeLimit: user.autoChargeLimit === null ? 100 : user.autoChargeLimit
            }}
            validationSchema={schemas.user.user.autoCharge}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={values => sendAutochargeForm(values, 1)}
            innerRef={formikRef}
          >
            {formik => (
              <Form>
                <div className="card card-profile-header mb-10">
                  <div className={"card-header " + (formik.values.autoChargeEnabled ? "" : "border-0")}>
                    <div className="d-flex align-items-center">
                      <Switch value={true} className="blue-switch" checked={!!formik.values.autoChargeEnabled} onChange={ev => formik.setFieldValue("autoChargeEnabled", ev.target.checked)} />
                      <h3 className="card-title fw-bold m-0 pl-5">Enable Auto Charge For Credits Only (Recommended)</h3>
                    </div>
                    <button type="submit" className={"btn btn-primary " + (loading == 1 ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save</button>
                  </div>
                  {formik.values.autoChargeEnabled && (
                    <div className="card-body pt-9 pb-9">
                      <div className="row mb-9">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Add <strong>${num(formik.values.autoChargeAmount)}</strong> to my account</label>
                            <NumberField formik={formik} name="autoChargeAmount" addon="$" step={10} />
                            {formik.touched["autoChargeAmount"] && formik.errors["autoChargeAmount"] && <div className="field-error">{formik.errors["autoChargeAmount"]}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-9 mb-md-0">
                          <div className="form-group">
                            <label>When my balance falls below <strong>{num(formik.values.autoChargeWhen)}</strong> credits</label>
                            <NumberField formik={formik} name="autoChargeWhen" step={10} />
                            {formik.touched["autoChargeWhen"] && formik.errors["autoChargeWhen"] && <div className="field-error">{formik.errors["autoChargeWhen"]}</div>}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>But never exceed <strong>${num(formik.values.autoChargeLimit)}</strong> per month</label>
                            <NumberField formik={formik} name="autoChargeLimit" addon="$" step={10} />
                            {formik.touched["autoChargeLimit"] && formik.errors["autoChargeLimit"] && <div className="field-error">{formik.errors["autoChargeLimit"]}</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>

      <UnsavedFormGuard formikRef={formikRef} onSaveAsync={saveAutoCharge} loading={loading == "modal"} />

      <ConfirmModal
        show={showConfirmCancel}
        message={<>Your Hiro membership will be cancelled and all your files will be deleted {user?.newPlan.expiration
          ? "on " + moment(user.newPlan.expiration).locale("en").format("LL")
          : "immediately"}.</>}
        confirmLabel="Continue"
        confirmButtonClassName="btn-danger"
        cancelLabel="Keep Current Subscription"
        loading={cancelLoading}
        onConfirm={() => cancelPlan()}
        onCancel={() => setShowConfirmCancel(false)} />
    </>
  );
}

export default connect(null, actions.auth)(BillingSettings);