import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, connect } from "react-redux";
import actions from "../../../../redux/actions";
import SVG from "react-inlinesvg";
import ConfirmModal from "../../modals/ConfirmModal";
import api from "../../../../redux/api";
import ProfileNavigation from "../../../layout/navigation/ProfileNavigation";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { getInputClassName, toastMessage } from "../../../helpers";
import CopyButton from "../../../layout/components/CopyButton";
import { NeedsUpgradeEvents } from "../../modals/NeedsUpgrade";
import { Switch } from "@material-ui/core";
import UpgradeLink from "../../generic/UpgradeLink";

function Developer() {
  return (
    <>
      <ProfileNavigation active="developer" />

      <ApiTokens />
    </>
  );
}

function ApiTokens() {
  const user = useSelector((state) => state.auth.user, shallowEqual),
    [showEditModal, setShowEditModal] = useState(),
    [loading, setLoading] = useState(true),
    [list, setList] = useState([]),
    [saving, setSaving] = useState(),
    [editItem, setEditItem] = useState(),
    [deleteItem, setDeleteItem] = useState(),
    [showConfirmDelete, setShowConfirmDelete] = useState();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Enter a name for the API key.")
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      pabblyAccess: true,
      fullAccess: user.planFeatures.api
    },
    validationSchema,
    onSubmit: save,
    enableReinitialize: true
  });

  const { handleSubmit, getFieldProps, resetForm, setValues, values, setTouched, setFieldValue, touched, errors } = formik;

  async function save(values) {
    setSaving(true);

    let res = !!editItem
      ? await api.user.updatePublicApiToken(editItem._id, values)
      : await api.user.createPublicApiToken(values);

    setSaving(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success(!!editItem
      ? "API key updated!"
      : "API key created!");

    setShowEditModal(false);
    setEditItem(null);
    resetForm();

    await loadList();
  }

  function editOnClick(item) {
    setEditItem(item);
    setValues({
      name: item.name,
      fullAccess: item.fullAccess,
      pabblyAccess: item.pabblyAccess
    }, true);
    setTouched({});
    setShowEditModal(true);
  }

  function deleteOnClick(item) {
    setDeleteItem(item);
    setShowConfirmDelete(true);
  }

  async function deleteModalOnConfirm() {
    setLoading(true);
    setShowConfirmDelete(false);

    let res = await api.user.removePublicApiToken(deleteItem._id);

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("API key deleted.");

    await loadList();
  }

  async function loadList() {
    setLoading(true);

    let res = await api.user.getPublicApiTokens();

    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    setList(res.data);
  }

  function createKeyOnClick() {
    if(user.noPLan || (!user.planFeatures.api && !user.planFeatures.zapsMonthly))
      return NeedsUpgradeEvents.dispatchShow("Upgrade to access the API");
    setShowEditModal(true);
  }

  useEffect(() => {
    loadList();
  }, []);

  return (
    <>
      <div className="card card-profile-header mb-10">
        <div className="card-header">
          <div>
            <h3 className="card-title fw-bold m-0">
              Hiro API Keys
              <span className="badge gray-badge ml-3">Advanced users</span>
            </h3>
          </div>
          <div>
            <a href="https://hirofm.docs.apiary.io" target="_blank">Documentation</a>
          </div>
        </div>
        <div className="card-body pt-9 pb-10">
          <p>Your secret API keys are listed below. Do not share your API key with others, or expose it in the browser or other client-side code.</p>

          {loading
            ? <div className="loading-block spinner" />
            : (
              !!list.length && (
                <div className="table-responsive">
                  <table className="table table-head-custom table-vertical-center">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Secret key</th>
                        <th>Access</th>
                        <th>Created at</th>
                        <th>Last used</th>
                        <th width="10"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map(item => (
                        <tr key={item._id}>
                          <td>
                            {item.name}
                          </td>
                          <td>
                            {item.token.substring(0, 6) + "..." + item.token.substr(-4)}
                            <CopyButton successTooltipText="Copied!" text={item.token} className="h-auto ml-4" />
                          </td>
                          <td>
                            {item.fullAccess
                              ? "Full"
                              : (
                                item.pabblyAccess
                                  ? "Pabbly"
                                  : "None"
                              )}
                          </td>
                          <td>
                            {moment(item.createdAt).locale("en").format("MMM D, YYYY")}
                          </td>
                          <td>
                            {item.lastUsedAt
                              ? moment(item.lastUsedAt).locale("en").format("MMM D, YYYY")
                              : "Never"}
                          </td>
                          <td className="pr-0 text-right text-nowrap">
                            <button type="button" className="btn btn-transparent mr-4" title="Edit" onClick={() => editOnClick(item)}>
                              <SVG src="/media/def-image/icons/pencil.svg" />
                            </button>
                            <button type="button" className="btn btn-transparent" title="Remove" onClick={() => deleteOnClick(item)}>
                              <SVG src="/media/def-image/icons/delete.svg" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}

          <button type="button" className="btn btn-primary" onClick={createKeyOnClick}>Generate new key</button>
        </div>
      </div>

      <Modal centered size="md" show={showEditModal} onHide={() => setShowEditModal(false)} className="new-modals edit-api-token-modal">
        <form onSubmit={handleSubmit}>
          <Modal.Header>
            <h1 className="m-0">
              {!!editItem
                ? "Edit API key"
                : "Generate API key"}
            </h1>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-4">
              <label className="form-label">API Key Name:</label>
              <input type="text" {...getFieldProps("name")} className={getInputClassName(formik, "name", "autofocus")} />
              {touched.name && errors.name && <div className="field-error">{errors.name}</div>}
            </div>

            <label className="form-group mb-3 d-flex align-items-center">
              <Switch value={true} className="blue-switch" checked={!!values.pabblyAccess} onChange={ev => setFieldValue("pabblyAccess", ev.target.checked)} />
              <span className="d-inline-block pl-6 mb-0">
                API key for <a href="https://connect.pabbly.com" target="_blank">Pabbly</a>
              </span>
            </label>

            <label className="form-group d-flex align-items-center">
              <Switch disabled={!user.planFeatures.api} value={true} className="blue-switch" checked={!!values.fullAccess} onChange={ev => setFieldValue("fullAccess", ev.target.checked)} />
              <span className="d-inline-block pl-6 mb-0">Regular API access</span>

              {!user.planFeatures.api && (
                <UpgradeLink user={user} className="ml-4" />
              )}
            </label>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-secondary mr-2" onClick={() => setShowEditModal(false)}>Cancel</button>
              <button type="submit" className={"btn btn-primary " + (saving ? "loading spinner" : "")} disabled={!(formik.isValid && formik.dirty)}>Save</button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      <ConfirmModal
        show={showConfirmDelete}
        message="Are you sure you want to delete the API key?"
        onConfirm={deleteModalOnConfirm}
        onCancel={() => setShowConfirmDelete(false)} />
    </>
  );
}

export default connect(null, actions.auth)(Developer);
