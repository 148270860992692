export default {
    translation: {
        "Coupon Code (optional)": "Код за купон (по избор)",
        "/ wk": "/ седмица",
        "/ mo": "/ месец",
        "/ yr": "/ година",
        "Click for Access": "Кликнете за достъп",
        "Buy Now": "Купи сега",
        "Select plan": "Изберете план",
        "{{episodes}} episodes - free": "{{episodes}} епизода - безплатно",
        "{{episodes}} episodes - {{displayPrice}}": "{{episodes}} епизода - {{displayPrice}}",
        "All": "Всички",
        "Audio Feeds": "Аудио потоци",
        "Category": "Категория",
        "There are no shows in this category.": "Няма предавания в тази категория.",
        "The creator doesn’t have any public audio feeds yet.": "Създателят все още няма публични аудио потоци.",
        "About me": "За мен",
        "My audio feeds": "Моите аудио потоци",
        "I agree to Hiro’s <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">terms of service</a> & <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">privacy policy</a>.": "Съгласен съм с <a href=\"https://hiro.fm/terms\" target=\"_blank\" className=\"themed\">условията за ползване</a> и <a href=\"https://hiro.fm/privacy\" target=\"_blank\" className=\"themed\">политиката за поверителност</a> на Hiro.",
        "Powered by Hiro.fm": "Задвижвано от Hiro.fm",
        "Start your own private audio product": "Започнете свой частен аудио продукт",
        "episodes": "епизоди",
        "Free": "Безплатно",
        "/ week": "/ седмица",
        "/ month": "/ месец",
        "/ year": "/ година",
        "one-time payment": "еднократно плащане",
        "per week": "на седмица",
        "per month": "на месец",
        "per year": "на година",
        "Products": "Продукти",
        "Episodes": "Епизоди",
        "Listeners": "Слушатели",
        "Downloads": "Изтегляния",
        "My activity": "Моята активност",
        "Created by:": "Създадено от:",
        "Last update:": "Последна актуализация:",
        "This audio feed doesn’t have any eposides yet.": "Този аудио поток все още няма епизоди.",
        "See more": "Виж повече",
        "Please verify the credit card details.": "Моля, проверете данните на кредитната карта.",
        "Payment": "Плащане",
        "Your details": "Вашите данни",
        "First Name": "Име",
        "Last Name": "Фамилия",
        "Email": "Имейл",
        "Phone (optional)": "Телефон (по избор)",
        "Invalid phone number.": "Невалиден телефонен номер.",
        "Payment details": "Данни за плащане",
        "Total payment": "Обща сума за плащане",
        "Get Access": "Вземете достъп",
        "Pay": "Плати",
        "Category:": "Категория:",
        "Get Free Access": "Вземете безплатен достъп",
        "Show currently unavailable": "Предаването в момента не е налично",
        "Buy now for {{priceValue}}": "Купете сега за {{priceValue}}",
        "Length": "Продължителност",
        "reviews": "отзиви",
        "About": "За продукта",
        "Testimonials": "Отзиви",
        "My other private audio feeds": "Моите други частни аудио потоци",
        "Thank you!": "Благодаря!",
        "You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.": "Успешно закупихте {{productName}} от {{author}}. Изпратихме ви имейл на {{installEmail}} с инструкции за достъп.",
        "Point your phone’s camera at the code below to access the private podcast.": "Насочете камерата на телефона си към кода по-долу, за да получите достъп до частния подкаст.",
        "Purchased item": "Закупен артикул",
        "Access Private Feed Now": "Достъп до частен поток сега",
        "Ohh no, something went wrong with your payment": "О, не, нещо се обърка с вашето плащане",
        "Check your credit card details and try one more time.": "Проверете данните на кредитната карта и опитайте отново.",
        "Try one more time": "Опитайте отново",
        "Cancel": "Отказ",
        "Enter your first name.": "Въведете вашето име.",
        "Enter your last name.": "Въведете вашата фамилия.",
        "Enter your email address.": "Въведете вашия имейл адрес.",
        "Invalid email address.": "Невалиден имейл адрес.",
        "Product not found.": "Продуктът не е намерен.",
        "Payment failed.": "Плащането не бе успешно.",
        "Fill all the fields.": "Попълнете всички полета.",
        "The email address is invalid.": "Имейл адресът е невалиден.",
        "Invalud product ID.": "Невалиден ID на продукта.",
        "Access denied to product.": "Достъпът до продукта е отказан.",
        "Show not available.": "Предаването не е налично.",
        "Terms of service": "Условия за ползване",
        "Privacy policy": "Политика за поверителност"
    }
};