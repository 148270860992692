import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { ErrorMessage } from "formik";
import AwsS3Dropzone from "../../../generic/AwsS3Dropzone";
import { Button, Tooltip } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../../redux/actions";
import StepAvailability from "./generic/StepAvailability";
import StepEmail from "./generic/StepEmail";
import { Element } from "react-scroll";
import { CoverUploader } from "./generic/coverUploader";
import ColorPicker from "../../../generic/ColorPicker";
import UpgradeLink from "../../../generic/UpgradeLink";
import { ProductLogo } from "../../../../../_metronic/layout/components/productLogo";
import SVG from "react-inlinesvg";
import Preview from "../../../../layout/components/Preview";
import AIField from "../../../../layout/ai/AIField";
import ConfirmModal from "../../../modals/ConfirmModal";
import StepNotifications from "./generic/StepNotifications";
import StepApp from "./generic/StepApp";
import StepSampleEpisodes from "./generic/StepSampleEpisodes";
import StepPromote from "./generic/StepPromote";

class Steps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showReview: false,
      completed: {},
      error: {},
      uploadError: null
    };
  }

  scrollTo(cardIndex) {
    if(cardIndex == 0)
      return document.querySelector("#root").scrollTo(0, 0);
    //document.querySelector("#card-element-3").scrollIntoView();
  }

  editClick(ev) {
    ev.preventDefault();
    this.setState({ ...this.state, showReview: false });
  }

  render() {
    const onUploadError = message => this.setState({ ...this.state, uploadError: message });

    const onArtworkUploaded = uploadedFiles => {
      if((uploadedFiles || []).length < 1) {
        onUploadError("No uploaded file found.");
      } else if((uploadedFiles || []) > 1) {
        onUploadError("Please upload a single file");
      } else {
        this.props.formik.setFieldValue("artwork", {
          url: uploadedFiles[0].amazonS3Url,
          fileSize: uploadedFiles[0].file.size
        });
      }
    };

    const clearPersisted = function() {
      window.localStorage.removeItem("product-form");
    };

    let releaseOverviewMessage;

    if(this.props.formik.values.releaseMode === 0) {
      releaseOverviewMessage = "All episode released immediately";
    } else if(this.props.formik.values.releaseMode === 1) {
      releaseOverviewMessage = "Dripped";
    } else if(this.props.formik.values.releaseMode === 2) {
      releaseOverviewMessage = "Date Release";
    }

    let expirationOverviewMessage;

    if(this.props.formik.values.livePeriod === 0) {
      expirationOverviewMessage = "Episodes are available forever";
    } else if(this.props.formik.values.livePeriod === 1) {
      expirationOverviewMessage = "Product Expires after " + this.props.formik.values.productExpires + " " + this.props.formik.values.productExpiresMode;
    } else if(this.props.formik.values.livePeriod === 2) {
      expirationOverviewMessage = "Episode expires";
    }

    let cancelOnClick = () => {
      this.setState({ ...this.state, showConfirmCancel: true });
    };

    let saveOnClick = () => {
      this.props.formik.setFieldValue("draft", 0);
      this.props.formik.submitForm();
    };

    let saveDraftOnClick = () => {
      this.props.formik.setFieldValue("draft", 1);
      this.props.formik.submitForm();
    };

    let cancelOnConfirm = () => {
      this.setState({ ...this.state, showConfirmCancel: false });
      this.props.formik.resetForm();
      clearPersisted();
      this.props.history.push("/products", { ignorePrompt: true });
    };

    // console.log(this.props.formik.values);

    return (
      <>
        <div className={"create-podcast-content" + (this.state.showReview ? " review-page-content-section" : "")} id="create-podcast-content-area">
          <div className="items">
            <Preview title="Show Preview" className="product">
              <div className="head" style={{
                backgroundColor: this.props.formik.values.color ? this.props.formik.values.color.code : null,
                color: this.props.formik.values.color && this.props.formik.values.color.dark ? "white" : null
              }}>
                <div className={"title " + (this.props.formik.values.name ? "" : "empty")}>{this.props.formik.values.name}</div>
                <div className={"subtitle " + (this.props.formik.values.instructorName ? "" : "empty")}>{this.props.formik.values.instructorName ? "with " + this.props.formik.values.instructorName : ""}</div>
                {this.props.currentUser.pictureUrl && <img src={this.props.currentUser.pictureUrl} className="user-picture" />}
              </div>
              <div className="artwork">
                <ProductLogo product={this.props.formik.values} />
              </div>
              <div className="text">
                <strong>Summary</strong>
                <div className={"summary " + (this.props.formik.values.description ? "" : "empty")}>{this.props.formik.values.description}</div>
              </div>
              <div className="text">
                <strong>Episodes</strong>
                <div className="episode">
                  <span />
                  <SVG src={toAbsoluteUrl("/media/def-image/icons/app-play.svg")} />
                </div>
                <div className="episode">
                  <span />
                  <SVG src={toAbsoluteUrl("/media/def-image/icons/app-lock.svg")} />
                </div>
              </div>
            </Preview>

            <div className="card">
              <div className="card-header">
                <h3 className="card-title m-0">Show Details</h3>
              </div>
              <div className="card-body">
                <Form.Group controlId="exampleForm.ControlInput1" className="content-input">
                  <label className="form-label">Title <em>*</em></label>

                  <AIField formik={this.props.formik} name="name" source="product" data={this.props.formik.values} prompt={this.props.formik.values.name
                    ? "Rewrite this title:\n\n" + this.props.formik.values.name
                    : "Write a title for this podcast"}>
                    <Form.Control
                      type="text"
                      name="name"
                      {...this.props.formik.getFieldProps("name")}
                      className={{ "form-control": true, "is-invalid": this.props.formik.errors.name }}
                    />
                  </AIField>

                  <ErrorMessage name="name">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlInput1" className="content-input podcast-author">
                  <label className="form-label">Creator <em>*</em></label>

                  <Form.Control
                    type="text"
                    name="instructorName"
                    {...this.props.formik.getFieldProps("instructorName")}
                    className={{ "form-control": true, "is-invalid": this.props.formik.errors.instructorName }}
                  />

                  <ErrorMessage name="instructorName">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                </Form.Group>

                <div className="form-group">
                  <label className="form-label">Cover art</label>

                  <CoverUploader
                    formData={this.props.formik.values}
                    color={this.props.formik.values.artwork ? this.props.formik.values.artwork.color : null}
                    icon={this.props.formik.values.artwork ? this.props.formik.values.artwork.icon : null}
                    url={this.props.formik.values.artwork ? this.props.formik.values.artwork.url : null}
                    formHandler={this.props.formik.setFieldValue}
                    user={this.props.currentUser}
                    isRemoveEnabled
                    dropzone={(
                      <div className={"dropzone-artwork-container"}>
                        <AwsS3Dropzone
                          allowMultipleUpload={false}
                          maxSize={process.env.REACT_APP_MAX_IMAGE_SIZE * 1000000}
                          onError={onUploadError}
                          onUploadComplete={uploadedFiles => onArtworkUploaded(uploadedFiles, this.props.formik.setFieldValue)}
                          forceSquareImageFormat={true}
                          fileType={"image/jpg, image/jpeg, image/png"}
                          uploadType="artwork"
                          errorMessage={this.state.uploadError}
                          disabled={!this.props.currentUser.planFeatures.customCoverArt}
                          description="Select square png or jpg file (min. 500 x 500 px)" />
                      </div>
                    )}
                  />

                  {(!this.state.uploadError && this.props.formik.errors.artwork && this.props.formik.errors.artwork.url) && <div className="invalid-feedback-copy">Please upload a JPG or PNG File</div>}
                  {this.state.uploadError && <div className="invalid-feedback-copy">{this.state.uploadError}</div>}
                </div>

                <Form.Group controlId="exampleForm.ControlTextarea1" className="content-input">
                  <label className="form-label">Summary <em>*</em></label>

                  <AIField formik={this.props.formik} name="description" source="product" data={this.props.formik.values} prompt={this.props.formik.values.description
                    ? "Rewrite this description of my podcast:\n\n" + this.props.formik.values.description
                    : "Write a description for this podcast"}>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="description"
                      {...this.props.formik.getFieldProps("description")}
                      className={{ "form-control": true, "is-invalid": this.props.formik.errors.description }}
                    />
                  </AIField>

                  <ErrorMessage name="description">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                </Form.Group>

                <div className="form-group mt-10">
                  <label className="form-label d-block">
                    Theme color
                    <Tooltip title="This will change the appeareance of your Sales Page and in our App." placement="top">
                      <Button><i className="fa fa-info-circle" aria-hidden="true" /></Button>
                    </Tooltip>
                    {!this.props.currentUser.planFeatures.customTheme ? <UpgradeLink user={this.props.currentUser} className="float-right" /> : <></>}
                  </label>
                  <ColorPicker disabled={!this.props.currentUser.planFeatures.customTheme} value={this.props.formik.values.color} onChange={color => this.props.formik.setFieldValue("color", color)} />
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h3 className="card-title m-0">Episode Delivery & Availabilty</h3>
              </div>

              <div className="card-body episode-access">
                <StepAvailability formik={this.props.formik} />
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h3 className="card-title m-0">Ownership</h3>
              </div>

              <div className="card-body ownership">
                <div className="owner-name-and-email row">
                  <div className="col-sm-6">
                    <Form.Group controlId="exampleForm.ControlInput1" className="content-input mb-sm-0">
                      <label className="form-label">Owner <em>*</em></label>

                      <Form.Control
                        type="text"
                        name="fromName"
                        {...this.props.formik.getFieldProps("fromName")}
                        className={{ "form-control": true, "is-invalid": this.props.formik.errors.fromName }}
                      />

                      <ErrorMessage name="fromName">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6">
                    <Form.Group controlId="exampleForm.ControlInput1" className="content-input mb-0">
                      <label className="form-label">
                        Owner’s email <em>*</em>
                        <Tooltip title="It’s best to use your support email address." placement="top">
                          <Button><i className="fa fa-info-circle" aria-hidden="true" /></Button>
                        </Tooltip>
                      </label>

                      <Form.Control
                        type="text"
                        name="replyToEmail"
                        {...this.props.formik.getFieldProps("replyToEmail")}
                        className={{ "form-control": true, "is-invalid": this.props.formik.errors.replyToEmail }}
                      />

                      <ErrorMessage name="replyToEmail">{msg => <div className="invalid-feedback">{msg}</div>}</ErrorMessage>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <StepApp formik={this.props.formik} user={this.props.currentUser} />

            <StepSampleEpisodes formik={this.props.formik} user={this.props.currentUser} />

            <StepPromote formik={this.props.formik} user={this.props.currentUser} />

            <div className="card">
              <div className="card-header">
                <h3 className="card-title m-0">Edit The Listener Access Email</h3>
                <p className="text-muted">Edit the access email sent to you listeners. Or leave it blank and keep the default text.</p>
              </div>

              <div className="card-body listener-access-email">
                <StepEmail
                  formik={this.props.formik}
                  contentDescription="Edit the access email sent to you listeners. Or leave it blank and keep the default text."
                />
              </div>
            </div>

            <StepNotifications formik={this.props.formik} />

            <div className="card border-0">
              <div className="card-body text-right p-0 d-flex justify-content-between">
                <button className="btn btn-secondary" type="button" onClick={cancelOnClick} disabled={!this.props.formik.dirty}>Cancel</button>
                <div>
                  <button className={"btn btn-secondary mr-3 " + (this.props.saving == "draft" ? "loading spinner" : "")} disabled={!(this.props.formik.isValid && this.props.formik.dirty)} type="button" onClick={saveDraftOnClick}>
                    Save as draft
                  </button>
                  <button className={"btn btn-primary " + (this.props.saving == "publish" ? "loading spinner" : "")} disabled={!(this.props.formik.isValid && this.props.formik.dirty)} type="button" onClick={saveOnClick}>
                    Save and publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ConfirmModal
          show={this.state.showConfirmCancel}
          message="Do you want to discard your changes?"
          onConfirm={cancelOnConfirm}
          onCancel={() => this.setState({ ...this.state, showConfirmCancel: false })} />
      </>
    );
  }
}

export default injectIntl(connect(
  store => ({
    currentUser: store.auth.user,
  }),
  actions.product,
)(Steps));

