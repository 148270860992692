import { isMobile, isTablet } from "react-device-detect";
import React from "react";

export default function DesktopOnly() {
    return (
        <>
            {isMobile && !isTablet && (
                <div className="mobile-warning">
                    <div>Hiro is a web app designed for use on a computer, not a phone. Please log in from your computer.</div>
                </div>
            )}
        </>
    );
}