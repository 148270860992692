import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

function EmbedOptions({ onOptionSelect, selectedOption }) {
  const cardHeight = '200px';

  const embedOptions = [
    {
      id: 'player',
      title: 'Show and player',
      icon: '/media/def-image/icons/embed-show-and-player.svg',
    },
    {
      id: 'signup',
      title: 'Sign up form',
      icon: '/media/def-image/icons/embed-signup-form.svg',
    },
    {
      id: 'sales',
      title: 'Sales page',
      icon: '/media/def-image/icons/embed-sales-page.svg',
    },
  ];

  return (
    <>
      <Row className='mb-4'>
        {embedOptions.map((option) => {
          const isSelected = option.id === selectedOption;

          return (
            <Col md={4} key={option.id} className='mb-3'>
              <div
                onClick={() => onOptionSelect(option.id)}
                className={`card-selectable ${isSelected ? 'selected' : ''}`}
                style={{ height: cardHeight }}
              >
                <div className='absolute-fill'>
                  <div
                    className={`svg-background ${isSelected ? 'selected' : ''}`}
                    style={{
                      backgroundImage: `url(${option.icon})`,
                    }}
                  />
                </div>

                {isSelected && (
                  <div className='selection-indicator animate-fadeInScale'>
                    <SVG
                      src='/media/def-image/icons/check-white.svg'
                      width='14'
                      height='14'
                    />
                  </div>
                )}
              </div>
              <h5
                className={`option-title ${
                  isSelected ? 'title-active' : 'title-inactive'
                }`}
              >
                {option.title}
              </h5>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default EmbedOptions;
