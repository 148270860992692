import en from "./en";
import de from "./de";
import dum from "./dum";
import es from "./es";
import fr from "./fr";
import it from "./it";
import pl from "./pl";
import pt from "./pt";
import ru from "./ru";
import sv from "./sv";
import bg from "./bg";

export const resources = {
    en,
    de,
    dum,
    es,
    fr,
    it,
    pl,
    pt,
    ru,
    sv,
    bg
};

export const languages = {
    "en": "English",
    "de": "Deutsch",
    "es": "Español",
    "fr": "Français",
    "it": "Italiano",
    "dum": "Nederlands",
    "pl": "Polski",
    "pt": "Português",
    "sv": "Svenska",
    "ru": "русский",
    "bg": "български"
};

export const languagesEn = {
    "en": "English",
    "dum": "Dutch",
    "fr": "French",
    "de": "German",
    "it": "Italian",
    "pl": "Polish",
    "pt": "Portuguese",
    "ru": "Russian",
    "es": "Spanish",
    "sv": "Swedish",
    "bg": "Bulgarian"
};

export const momentLocales = {
    "en": "en",
    "de": "de",
    "es": "es",
    "fr": "fr",
    "it": "it",
    "dum": "nl",
    "pl": "pl",
    "pt": "pt",
    "sv": "sv",
    "ru": "ru",
    "bg": "bg"
};