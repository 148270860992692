import React from "react";
import ProductItem from "./generic/ProductItem";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import actions from "../../../redux/actions";
import NewProductDropdown from "../../layout/dropdowns/NewProductDropdown";
import SVG from "react-inlinesvg";
import Lottie from "react-lottie";
import animationData from "../../layout/lottie/empty-podcasts.json";

function ProductsList(props) {
  const usedToHaveProducts = !!parseInt(window.localStorage.getItem("productsCount"));

  return (
    <>
      <h1>
        Shows
        <NewProductDropdown className="inline" toggleClassName="btn-plus" />
      </h1>

      {!(props.products || []).length && (
        usedToHaveProducts
          ? (
            <div className="row no-product-item text-center">
              <div className="col-lg-12 col-xxl-12">
                <SVG className="empty-icon my-10" src="/media/def-image/empty-domains.svg" />
                <h3 className="mb-3">The Internet Unplugged Us</h3>
                <p>
                  We’re having trouble loading your shows. Please press the CTRL key and the<br />
                  refresh button at the same time to clear your browser.
                </p>
              </div>
            </div>
          )
          : (
            <div className="row no-product-item text-center">
              <div className="col-lg-12 col-xxl-12">
                <div className="empty-lottie">
                  <Lottie options={{
                    animationData,
                    loop: false,
                    autoplay: true
                  }} height={240} width={300} />
                </div>
                <div className="empty-content">
                  <p className="mb-10">Set up your show, upload your cover art and add your first episodes to<br />create your first show.</p>
                  <div>
                    <NewProductDropdown className="inline" toggleClassName="btn-primary" alignRight>Create Your First Audio Feed</NewProductDropdown>
                  </div>
                </div>
              </div>
            </div>
          )
      )}

      {!!(props.products || []).length && (
        <div className="product-table table-responsive">
          <table className="table table-head-custom table-vertical-center">
            <thead>
              <tr>
                <th colSpan="2">Title</th>
                <th className="text-center">Length</th>
                <th className="text-center">Listeners</th>
                <th className="text-center">Type</th>
                <th className="text-center">Status</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.products.map((product) => (
                <ProductItem
                  key={product._id}
                  item={product}
                  history={props.history}
                />
              ))}
            </tbody>
          </table>
          <Link to="/create-product" className="btn-table-row">
            <span className="plus" /> Create New Show
          </Link>
        </div>
      )}
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      auth: state.auth,
      products: state.product.products,
      currentUser: state.auth.user,
    }),
    actions.product
  )(withRouter(ProductsList))
);
