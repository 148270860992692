import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import schemas from "../../../schemas";
import { Formik, Form as FormFormik, useFormik } from "formik";
import api from "../../../../redux/api";
import { getInputClassName, toastMessage } from "../../../helpers";
import { injectIntl } from "react-intl";
import actions from "../../../../redux/actions";
import { connect } from "react-redux";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  confirm: Yup.string().required("Required field.")
});

function DeleteAccount({ match, history, dispatch, user, setLogin, fulfillUser }) {
  let [loading, setLoading] = useState(false),
    formik = useFormik({
      initialValues: {
        confirm: "",
      },
      validationSchema,
      onSubmit: sendForm
    });

  async function updateUser() {
    dispatch(fulfillUser((await api.auth.getUserByToken(true)).data));
  }

  async function sendForm(values) {
    if(values.confirm.trim().toLocaleLowerCase() != (user.firstName.trim() + " " + user.lastName.trim()).toLocaleLowerCase())
      return toastMessage.error("Please type the creator‘s full name to confirm.");

    setLoading(true);

    let res = await api.user.deleteSubaccount();

    if(!res || !res.success) {
      setLoading(false);
      toastMessage.error((res && res.error) || "Unable to connect to the server.");
      return;
    }

    if(res.token && res.user)
      await dispatch(setLogin(res.token, res.user));

    await updateUser();

    toastMessage.success("Account deleted successfully");

    setLoading(false);

    history.push("/home");
  };

  return (
    <>
      <h1>
        <Link to="/profile-page" className="btn btn-back">
          <SVG src={toAbsoluteUrl("/media/def-image/icons/back.svg")} />
        </Link>
        Delete Account
      </h1>

      <form onSubmit={formik.handleSubmit}>
        <div className="container-inner">
          <div className="card">
            <div className="card-body">
              <h2 className="mb-4">Confirm Deletion</h2>

              <p className="mb-8">
                Are you sure you want to delete the creator‘s account? This is your last chance to change your mind! Remember,
                you can always reach out to our support team to discuss any challenges you’re experiencing and we’ll be happy
                to help out.
              </p>

              <div className="form-group">
                <label className="form-label">Confirm it‘s you - For your safety, type "{user.firstName} {user.lastName}"</label>
                <input type="text" className={getInputClassName(formik, "confirm")} {...formik.getFieldProps("confirm")}  onPaste={ev => ev.preventDefault()} />
                {formik.touched.confirm && formik.errors.confirm && <div className="field-error">{formik.errors.confirm}</div>}
              </div>
            </div>
            <div className="card-footer text-right">
              <a href="#" onClick={history.goBack} className="btn btn-secondary mr-2" disabled={loading}>Cancel</a>
              <button type="submit" className={"btn btn-danger " + (loading ? "loading spinner" : "")} disabled={!formik.isValid || !formik.dirty}>Delete</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default injectIntl(
  connect(
    state => ({
      user: state.auth.user
    }),
    dispatch => ({
      ...actions.auth,
      dispatch,
    })
  )(DeleteAccount)
);