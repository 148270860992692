import React from "react";
import { getEstimatedProcessingTime } from "../../../../helpers/utils";

class PodcastPlayer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        {(() => {
          if(this.props.product.pendingOwnership)
            return "Waiting ownership verification.";

          if(!this.props.audioUrl && !this.props.originalFileUrl)
            return "No audio file.";

          if(!this.props.finishedProcessing) {
            let estimate = getEstimatedProcessingTime(this.props.episode);

            if(this.props.isEditForm) {
              if(this.props.isNewFile)
                return (
                  <div className="mt-3 text-gray small">
                    <strong>File Queued After Save</strong><br />
                    Processing queued once you save the episode. {!!estimate && "Estimated time about: " + estimate}
                  </div>
                );

              return (
                <div className="mt-3 text-gray small">
                  <strong>Processing File...</strong><br />
                  Processing in progress or queued. {!!estimate && "Estimated time about: " + estimate}
                </div>
              );
            }

            return (
              <>
                Processing file...
                {!!estimate && (
                  <>
                    <br />
                    <small className="text-gray">Estimated: {estimate}</small>
                  </>
                )}
              </>
            );
          }

          if(!this.props.failedProcessing)
            return (
              <audio controls preload="none">
                <source src={this.props.audioUrl} type="audio/mpeg" />
                Your browser does not support the HTML5 audio element.
              </audio>
            );

          if(this.props.processingFriendlyError) {
            if(this.props.isEditForm)
              return (
                <div className="text-danger mt-3">{this.props.processingFriendlyError}</div>
              );

            return this.props.processingFriendlyError;
          }

          if(this.props.isEditForm)
            return (
              <div className="text-danger mt-3">Conversion failed, please retry or upload an MP3 instead.</div>
            );

          return "Conversion failed, please retry or upload an MP3 instead.";
        })()}
      </div >
    )
  }
}

export default PodcastPlayer;
