import React from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import actions from "../../../redux/actions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { checkLimits } from "../../plans";

function NewListenerDropdown({ productId, className = "", toggleClassName = "", children, currentUser, drop = "down", alignRight = false, fixed = false }) {
    let history = useHistory();

    async function createListenerOnClick(ev) {
        ev.preventDefault();

        if(await checkLimits.canAddContact(currentUser))
            history.push("/products/" + productId + "/listeners#edit");
    }

    return (
        <Dropdown className={(fixed ? "fixed-dropdown " : "") + className} drop={drop} alignRight={alignRight}>
            <Dropdown.Toggle className={toggleClassName}>
                {children}
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={fixed ? { strategy: "fixed" } : {}} renderOnMount>
                <Link to="#" onClick={createListenerOnClick} className="dropdown-item no-plan-cant-click">
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/user-plus2.svg")} />
                    </div>
                    Single Listener
                </Link>

                <Link to={"/products/" + productId + "/listeners#bulk-upload"} className="dropdown-item no-plan-cant-click">
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/csv.svg")} />
                    </div>
                    Bulk Upload
                </Link>

                {currentUser && currentUser.planFeatures && !currentUser.planFeatures.zapsMonthly
                    ? (
                        <a href="#" className="dropdown-item no-plan-cant-click" onClick={ev => {
                            ev.preventDefault();
                            checkLimits.upgradeOrActivate(currentUser);
                        }}>
                            <div className="icon">
                                <SVG src="/media/def-image/icons/zapier.svg" />
                            </div>
                            Zapier
                        </a>
                    )
                    : (
                        <a className="dropdown-item no-plan-cant-click" href="https://zapier.com/apps/hirofm/integrations" target="_blank">
                            <div className="icon">
                                <SVG src="/media/def-image/icons/zapier.svg" />
                            </div>
                            Zapier
                        </a>
                    )}

                {currentUser && currentUser.planFeatures && !currentUser.planFeatures.zapsMonthly
                    ? (
                        <a href="#" className="dropdown-item no-plan-cant-click" onClick={ev => {
                            ev.preventDefault();
                            checkLimits.upgradeOrActivate(currentUser);
                        }}>
                            <div className="icon">
                                <SVG src="/media/def-image/icons/pabbly.svg" />
                            </div>
                            Pabbly
                        </a>
                    )
                    : (
                        <a className="dropdown-item no-plan-cant-click" href="https://www.pabbly.com/connect/integrations/hiro-fm/" target="_blank">
                            <div className="icon">
                                <SVG src="/media/def-image/icons/pabbly.svg" />
                            </div>
                            Pabbly
                        </a>
                    )}

                {currentUser && currentUser.planFeatures && !currentUser.planFeatures.api
                    ? (
                        <a href="#" className="dropdown-item no-plan-cant-click" onClick={ev => {
                            ev.preventDefault();
                            checkLimits.upgradeOrActivate(currentUser);
                        }}>
                            <div className="icon">
                                <SVG src="/media/def-image/icons/ghl.svg" />
                            </div>
                            HighLevel
                        </a>
                    )
                    : (
                        <a className="dropdown-item no-plan-cant-click" href="https://marketplace.gohighlevel.com/integration/65c3ca4663331e6b1c8f98ae" target="_blank">
                            <div className="icon">
                                <SVG src="/media/def-image/icons/ghl.svg" />
                            </div>
                            HighLevel
                        </a>
                    )}

                {currentUser && currentUser.planFeatures && !currentUser.planFeatures.api
                    ? (
                        <a href="#" className="dropdown-item no-plan-cant-click" onClick={ev => {
                            ev.preventDefault();
                            checkLimits.upgradeOrActivate(currentUser);
                        }}>
                            <div className="icon">
                                <SVG src="/media/def-image/icons/api.svg" />
                            </div>
                            Public API
                        </a>
                    )
                    : (
                        <a className="dropdown-item no-plan-cant-click" href="https://hirofm.docs.apiary.io/#reference/0/contacts-and-listeners" target="_blank">
                            <div className="icon">
                                <SVG src="/media/def-image/icons/api.svg" />
                            </div>
                            Public API
                        </a>
                    )}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default injectIntl(
    connect(
        (state) => ({
            currentUser: state.auth.user,
        }),
        actions.product
    )(withRouter(NewListenerDropdown))
);