import React from 'react';
import { toastMessage } from '../../../helpers';

function EmbedCode({ productId, embedType, productLive, publicUri }) {
  const getEmbedCode = () => {
    let baseUrl = process.env.REACT_APP_LOCAL_URL,
      isDev = process.env.REACT_APP_ENV != "PROD",
      dataTest = isDev
        ? " data-test=\"true\""
        : "";

    switch (embedType) {
      case 'player':
        return `<iframe width="100%" height="600" src="${baseUrl}embed/${productId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"></iframe>`;

      case 'signup':
        return `<div class="hiro-embed" data-signup="${productId}"${dataTest}></div>
<script type="text/javascript" src="${baseUrl}js/embed.min.js" async defer></script>`;

      case 'sales':
        return `<div class="hiro-embed" data-sp="${publicUri}"${dataTest}></div>
<script type="text/javascript" src="${baseUrl}js/embed.min.js" async defer></script>`;

      default:
        return '';
    }
  };

  const copyCode = async () => {
    try {
      if (!productLive) {
        toastMessage.error(
          'This product is not live yet. Please make it live first.'
        );
        return;
      }

      await navigator.clipboard.writeText(getEmbedCode());
      toastMessage.success('Embed code copied to clipboard!');
    } catch (err) {
      toastMessage.error('Failed to copy code. Please try again.');
    }
  };

  return (
    <div className='card-footer d-flex justify-content-end py-6 px-9'>
      <button className='btn btn-primary' onClick={copyCode}>
        Copy embed code
      </button>
    </div>
  );
}

export default EmbedCode;
